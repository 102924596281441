import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { GlobalConfigService } from './global-config.service';
import { AlertService } from './alert.service';
import { LoginModel, TokenData } from '../models/login.model';
import { CompanyNameAndId } from 'api/models';
import { TranslateService } from '@ngx-translate/core';

// tslint:disable:no-bitwise

export enum AccessLevel {
  None = 0,
  User = 1,
  Administrator = 2,
  SysAdministrator = 4
}

@Injectable()
export class AuthService {
  private serviceUrl: string;
  private jwtHelper = new JwtHelperService();
  private localStorageName = 'tokenData';
  roleTranslations = '';
  constructor(
    private http: HttpClient,
    private config: GlobalConfigService,
    protected alertService: AlertService,
    protected location: Location,
    protected router: Router,
    public translateService: TranslateService
  ) {
    this.serviceUrl = this.config.apiBaseUrl;
    this.translateService.get('unauthorized_missing_roles').subscribe(res => {
      this.roleTranslations = res;
    });
  }

  public getToken(): string {
    const tokenData = localStorage.getItem(this.localStorageName);

    if (tokenData) {
      const tokenDataObject = JSON.parse(tokenData);
      return tokenDataObject.token;
    } else {
      return '';
    }
  }

  public getRoleString(): string {
    return AccessLevel[this.getRole()];
  }

  public getRole(): AccessLevel {
    const tokenData = localStorage.getItem(this.localStorageName);

    if (tokenData) {
      const parsedToken = JSON.parse(tokenData);
      // TODO check token expiry date
      const roles = parsedToken[
        'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      ] as string[];
      if (roles.includes('SysAdministrator')) {
        return AccessLevel.SysAdministrator;
      } else if (roles.includes('Administrator')) {
        return AccessLevel.Administrator;
      } else if (roles.includes('User')) {
        return AccessLevel.User;
      }
    }

    return AccessLevel.None;
  }

  public isAdministrative(): boolean {
    return (
      AccessLevel[this.getRole()] === 'SysAdministrator' ||
      AccessLevel[this.getRole()] === 'Administrator'
    );
  }

  private handleError(error: Response | any) {
    let errorMsg = error;
    try {
      const errorResponse = error.json();
      errorMsg = errorResponse.error;
      // json
    } catch (e) {}
    return throwError(errorMsg);
  }

  public updateRoleContext(company: CompanyNameAndId) {
    const tokenData = this.getTokenData();
    tokenData.companyName = company.name;
    tokenData.companyId = company.id;
    localStorage.setItem(this.localStorageName, JSON.stringify(tokenData));
    localStorage.setItem('locationsFilter', '0');
  }

  public login(
    model: LoginModel,
    clientPortal = false
  ): Observable<{ hasAccess: boolean; message: string }> {
    const myHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http
      .post(this.serviceUrl + 'Token', model, { headers: myHeader })
      .pipe(
        map((response: any) => {
          const loginResponse = {
            hasAccess: true,
            message: ''
          };
          const parsedTokenData = this.jwtHelper.decodeToken(response.token);

          if (!clientPortal && (response.role === 'User' || !response.role)) {
            loginResponse.hasAccess = false;
            loginResponse.message = this.roleTranslations;
          } else {
            parsedTokenData.token = response.token;
            parsedTokenData.role = response.role;
            parsedTokenData.companyId = response.company.id;
            parsedTokenData.companyName = response.company.name;
            localStorage.setItem(
              this.localStorageName,
              JSON.stringify(parsedTokenData)
            );
          }

          return loginResponse;
        }),
        catchError(this.handleError)
      );
  }

  public requestOptionsWithGivenToken(token: string): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  public requestOptionsWithToken(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.getToken(),
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  public getTokenData(): TokenData {
    const tokenData = localStorage.getItem(this.localStorageName);

    if (tokenData) {
      return JSON.parse(tokenData) as TokenData;
    } else {
      localStorage.removeItem(this.localStorageName);
      this.router.navigate(['/login']);
      return new TokenData();
    }
  }

  public logout(): boolean {
    // clear token remove user from local storage to log user out
    localStorage.removeItem(this.localStorageName);
    localStorage.setItem('locationsFilter', '0');
    return true;
  }

  public requestOptionsFormDataWithToken(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.getToken()
      })
    };
    return httpOptions;
  }

  getTokenExpirationDate(token: string): Date {
    const decodedToken = this.jwtHelper.decodeToken(token);
    if (!decodedToken) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(decodedToken.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.getToken();
    }
    if (!token) {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    if (!date) {
      return false;
    }
    return date.valueOf() < new Date().valueOf();
  }

  getErrorTranslationObject(errorType) {
    switch (errorType) {
      case 'UserIsNotActivated':
        return {
          value: 'User is not activated yet',
          id: 'user_not_activated'
        };
      default:
        return {
          value: 'Invalid user ID and/or password!',
          id: 'invalid_user_id_password'
        };
    }
  }
}
