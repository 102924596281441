import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ImportUserResultService {
    private subject = new BehaviorSubject([]);
    result = this.subject.asObservable();

    constructor() {
    }

    newResult(data) {
        this.subject.next(data);
    }

    clear() {
        this.subject.next([]);
    }
}
