<mat-card class="my-tickets-mat-card">
  <mat-card-header>
    <mat-card-title>{{'my_bookings' | translate}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ngx-datatable
      class="material fullscreen flex-responsive only-scroll-horz no-box-shadow order-table my-tickets-table"
      [columnMode]="'force'" [headerHeight]="'auto'" [footerHeight]="50" [rowHeight]="'auto'" [rows]="items || []"
      [scrollbarH]="true"
      [messages]="{emptyMessage: 'no_data_to_display' | translate, totalMessage: 'total' | translate}">

      <ngx-datatable-column name="{{'activity' | translate}}" prop="itemName">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ row.itemName }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{'date' | translate}}" prop="from">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ row.from | appDate }} - {{row.to | appDate:'time' }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{'action' | translate}}">

        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <button mat-icon-button (click)="cancelBooking(row)" title="Kanseller booking">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </mat-card-content>
</mat-card>