    <mat-card class="ticket-mat-card">
        <mat-card-header>
            <mat-card-title>{{'tickets' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)='onSelectTabChange($event)' class="mt-3 ticket-group">
                <mat-tab label="{{'single_tickets' | translate}}">

                    <div class="tickets-item mt-4 ml-4">

                        <div class="mb-3 display-flex items-list" *ngFor="let item of items">

                            <span style="width: 50%; align-items: center;    color: #38A8DF;" class="display-flex"> {{ getName(item) }} </span>

                            <span  class="display-flex price-span"> <span [ngClass]="{'discount-strike': checkDiscount(item) }"> kr {{ item.prices[0]? item.prices[0].value : 0 }} </span>
                            <span style="margin-left: 10px;" *ngIf="checkDiscount(item)"> kr {{ getPriceDiscount(item) }}  </span> </span>


                            <div class="wrapper">
                                <button class="plusminus" (click)="removeFromCart(item)">-</button>
                                <span class="num"> {{ getNumberOfItems(item) }} </span>
                                <button class="plusminus" (click)="addToCart(item)">+</button>
                            </div>

                        </div>


                    </div>



                </mat-tab>
                <mat-tab label="{{'monthly_subscription' | translate}}">

                    <div class="tickets-item mt-4 ml-4">

                        <div class="mb-3 display-flex items-list" *ngFor="let item of items">

                            <span style="width: 50%; align-items: center;    color: #38A8DF;" class="display-flex"> {{ getName(item)}} </span>

                            <span  class="display-flex price-span"> kr {{ item.prices[0]? item.prices[0].value : 0 }}  </span>

                            <div class="wrapper">
                                <button class="plusminus" (click)="removeFromCart(item)">-</button>
                                <span class="num"> {{ getNumberOfItems(item) }} </span>
                                <button class="plusminus" (click)="addToCart(item)">+</button>
                            </div>

                        </div>


                    </div>

                </mat-tab>

                <mat-tab label="{{'yearly_subscription' | translate}}">


                    <div class="tickets-item mt-4 ml-4">

                        <div class="mb-3 display-flex items-list" *ngFor="let item of items">

                            <span style="width: 50%; align-items: center;    color: #38A8DF;" class="display-flex"> {{  getName(item)}} </span>

                            <span  class="display-flex price-span"> kr {{ item.prices[0]? item.prices[0].value : 0 }}  </span>

                            <div class="wrapper">
                                <button class="plusminus" (click)="removeFromCart(item)">-</button>
                                <span class="num"> {{ getNumberOfItems(item) }} </span>
                                <button class="plusminus" (click)="addToCart(item)">+</button>
                            </div>

                        </div>


                    </div>

                </mat-tab>

                <mat-tab label="{{'journey_tickets' | translate}}">


                    <div class="tickets-item mt-4 ml-4">

                        <div class="mb-3 display-flex items-list" *ngFor="let item of items">

                            <span style="width: 50%; align-items: center; color: #38A8DF;" class="display-flex"> {{ getName(item)}} </span>

                            <span  class="display-flex price-span"> kr {{ item.prices[0]? item.prices[0].value : 0 }}  </span>

                            <div class="wrapper">
                                <button class="plusminus" (click)="removeFromCart(item)">-</button>
                                <span class="num"> {{ getNumberOfItems(item) }} </span>
                                <button class="plusminus" (click)="addToCart(item)">+</button>
                            </div>

                        </div>


                    </div>

                </mat-tab>

                <mat-tab label="{{'gift_tickets' | translate}}">


                    <div class="tickets-item mt-4 ml-4">

                        <div class="mb-3 display-flex items-list" *ngFor="let item of items">

                            <span style="width: 50%; align-items: center; color: #38A8DF;" class="display-flex"> {{ getName(item)}} </span>

                            <span  class="display-flex price-span"> kr {{ item.prices[0]? item.prices[0].value : 0 }}  </span>



                            <div class="wrapper">
                                <button class="plusminus" (click)="removeFromCart(item)">-</button>
                                <span class="num"> {{ getNumberOfItems(item) }} </span>
                                <button class="plusminus" (click)="addToCart(item)">+</button>
                            </div>

                        </div>


                    </div>

                </mat-tab>

            </mat-tab-group>
        </mat-card-content>
    </mat-card>



    <div class="cart">
        <div class="sum">
            <span> SUM: KR  <span *ngIf="checkSumDiscount()" [ngClass]="{'discount-strike': checkSumDiscount() }"> {{ fullCartSum }} </span> <span style="margin-left: 10px;"> {{ cartSum }} </span>  </span>
        </div>
        <button style="width: 100px; background-color: #7b2cb5; color:white; text-transform: uppercase; margin-right: 20px;" *ngIf="cart && cart.length>0" class="mt-3" (click)="emptyBasket()" type="button" mat-raised-button color="danger" >{{'empty' | translate}}</button>

        <button style="width: 100px; background-color: #c23277; text-transform: uppercase;" [disabled]="!cart || cart.length <=0" class="mt-3" (click)="buy()" type="button" mat-raised-button color="primary">{{'buy' | translate}}</button>
    </div>