/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedResultOfLocation } from '../models/paged-result-of-location';
import { Location } from '../models/location';
import { LocationUser } from '../models/location-user';
@Injectable({
  providedIn: 'root',
})
class LocationService extends __BaseService {
  static readonly LocationGetPath = '/api/Location';
  static readonly LocationCreatePath = '/api/Location';
  static readonly LocationUpdatePath = '/api/Location';
  static readonly LocationGetUserLocationsPath = '/api/Location/UserLocations';
  static readonly LocationGet2Path = '/api/Location/{id}';
  static readonly LocationDeletePath = '/api/Location/{id}';
  static readonly LocationGetUsersPath = '/api/Location/{id}/users';
  static readonly LocationAddUserPath = '/api/Location/{id}/users/{userId}';
  static readonly LocationDeleteUserPath = '/api/Location/{id}/users/{userId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get locations for given companyId
   * @param params The `LocationService.LocationGetParams` containing the following parameters:
   *
   * - `simpleResults`:
   *
   * - `overrideActiveBookings`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  LocationGetResponse(params: LocationService.LocationGetParams): __Observable<__StrictHttpResponse<PagedResultOfLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.simpleResults != null) __params = __params.set('simpleResults', params.simpleResults.toString());
    if (params.overrideActiveBookings != null) __params = __params.set('overrideActiveBookings', params.overrideActiveBookings.toString());
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Location`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfLocation>;
      })
    );
  }
  /**
   * Get locations for given companyId
   * @param params The `LocationService.LocationGetParams` containing the following parameters:
   *
   * - `simpleResults`:
   *
   * - `overrideActiveBookings`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  LocationGet(params: LocationService.LocationGetParams): __Observable<PagedResultOfLocation> {
    return this.LocationGetResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfLocation)
    );
  }

  /**
   * Create location
   * @param location undefined
   */
  LocationCreateResponse(location: Location): __Observable<__StrictHttpResponse<Location>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = location;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Location`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Location>;
      })
    );
  }
  /**
   * Create location
   * @param location undefined
   */
  LocationCreate(location: Location): __Observable<Location> {
    return this.LocationCreateResponse(location).pipe(
      __map(_r => _r.body as Location)
    );
  }

  /**
   * Update location
   * @param location undefined
   */
  LocationUpdateResponse(location: Location): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = location;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Location`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update location
   * @param location undefined
   */
  LocationUpdate(location: Location): __Observable<null> {
    return this.LocationUpdateResponse(location).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `LocationService.LocationGetUserLocationsParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  LocationGetUserLocationsResponse(params: LocationService.LocationGetUserLocationsParams): __Observable<__StrictHttpResponse<PagedResultOfLocation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Location/UserLocations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfLocation>;
      })
    );
  }
  /**
   * @param params The `LocationService.LocationGetUserLocationsParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  LocationGetUserLocations(params: LocationService.LocationGetUserLocationsParams): __Observable<PagedResultOfLocation> {
    return this.LocationGetUserLocationsResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfLocation)
    );
  }

  /**
   * Get location by given locationId
   * @param id undefined
   */
  LocationGet2Response(id: number): __Observable<__StrictHttpResponse<Location>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Location/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Location>;
      })
    );
  }
  /**
   * Get location by given locationId
   * @param id undefined
   */
  LocationGet2(id: number): __Observable<Location> {
    return this.LocationGet2Response(id).pipe(
      __map(_r => _r.body as Location)
    );
  }

  /**
   * Delete location by given locationId
   * @param id undefined
   */
  LocationDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Location/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete location by given locationId
   * @param id undefined
   */
  LocationDelete(id: number): __Observable<null> {
    return this.LocationDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get location users by given locationId
   * @param id undefined
   */
  LocationGetUsersResponse(id: number): __Observable<__StrictHttpResponse<Array<LocationUser>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Location/${encodeURIComponent(String(id))}/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LocationUser>>;
      })
    );
  }
  /**
   * Get location users by given locationId
   * @param id undefined
   */
  LocationGetUsers(id: number): __Observable<Array<LocationUser>> {
    return this.LocationGetUsersResponse(id).pipe(
      __map(_r => _r.body as Array<LocationUser>)
    );
  }

  /**
   * @param params The `LocationService.LocationAddUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `id`:
   */
  LocationAddUserResponse(params: LocationService.LocationAddUserParams): __Observable<__StrictHttpResponse<LocationUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Location/${encodeURIComponent(String(params.id))}/users/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LocationUser>;
      })
    );
  }
  /**
   * @param params The `LocationService.LocationAddUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `id`:
   */
  LocationAddUser(params: LocationService.LocationAddUserParams): __Observable<LocationUser> {
    return this.LocationAddUserResponse(params).pipe(
      __map(_r => _r.body as LocationUser)
    );
  }

  /**
   * @param params The `LocationService.LocationDeleteUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `id`:
   */
  LocationDeleteUserResponse(params: LocationService.LocationDeleteUserParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Location/${encodeURIComponent(String(params.id))}/users/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `LocationService.LocationDeleteUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `id`:
   */
  LocationDeleteUser(params: LocationService.LocationDeleteUserParams): __Observable<null> {
    return this.LocationDeleteUserResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module LocationService {

  /**
   * Parameters for LocationGet
   */
  export interface LocationGetParams {
    simpleResults?: boolean;
    overrideActiveBookings?: boolean;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for LocationGetUserLocations
   */
  export interface LocationGetUserLocationsParams {
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for LocationAddUser
   */
  export interface LocationAddUserParams {
    userId: number;
    id: number;
  }

  /**
   * Parameters for LocationDeleteUser
   */
  export interface LocationDeleteUserParams {
    userId: number;
    id: number;
  }
}

export { LocationService }
