<mat-card class="register-card">
    <mat-card-header>
        <mat-card-title >{{'register' | translate}}</mat-card-title>
        <mat-card-subtitle >{{'enter_details' | translate}}</mat-card-subtitle>
    </mat-card-header>

        <mat-card-content>
            <form [formGroup]="registerForm" (ngSubmit)="submit()">
                <mat-form-field appearance="outline">
                    <mat-label>{{'first_name' | translate}}</mat-label>
                    <input matInput formControlName="firstName">

                    <mat-error *ngIf="registerForm.controls.firstName.hasError('required') || registerForm.controls.firstName.hasError('minlength')" >{{'firstName_required' | translate}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label >{{'last_name' | translate}}</mat-label>
                    <input matInput formControlName="lastName">

                    <mat-error *ngIf="registerForm.controls.lastName.hasError('required') || registerForm.controls.lastName.hasError('minlength')" >{{'lastName_required' | translate}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label >{{'email_c' | translate}}</mat-label>
                    <input matInput formControlName="email">
                    <mat-error *ngIf="registerForm.controls.email.hasError('required') || registerForm.controls.email.hasError('minlength')" >{{'email_required' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label >{{'password' | translate}}</mat-label>
                    <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="newPassword">
                    <mat-icon matSuffix (click)="hidePassword = !hidePassword" class="toggle-password-visibility">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>

                    <mat-error *ngIf="registerForm.controls.newPassword.hasError('required') || registerForm.controls.newPassword.hasError('minlength')" >{{'password_required' | translate}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label >{{'cellphone' | translate}}</mat-label>
                    <input matInput formControlName="cellPhone">

                    <mat-error *ngIf="registerForm.controls.cellPhone.hasError('required') || registerForm.controls.cellPhone.hasError('minlength')" >{{'cellphone_required' | translate}}</mat-error>
                </mat-form-field>
                <div class="activation-choice">
                    <mat-label> {{'send_activation_code_to' | translate}} </mat-label>
                    <mat-radio-group [(ngModel)]="activationChoice" [ngModelOptions]="{standalone: true}">
                        <mat-radio-button value="Email" >{{'email_c' | translate}}</mat-radio-button>
                        <mat-radio-button value="SMS" >{{'sms' | translate}}</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div layout="row" layout-align="start center">
                    <div class="action-buttons" flex>
                        <button mat-raised-button color="primary" >{{'register' | translate}}</button>
                        <button mat-raised-button color="secondary"  (click)="login()">{{'sign_in' | translate}}</button>
                    </div>
                    <a routerLink="/recoverpassword" class="forgot-password"><small >{{'lost_your_password' | translate}}</small></a>
                </div>
            </form>
        </mat-card-content>
</mat-card>
