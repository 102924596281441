/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RentOrderDto } from '../models/rent-order-dto';
@Injectable({
  providedIn: 'root',
})
class RentOrderService extends __BaseService {
  static readonly RentOrderCreatePath = '/api/v2/RentOrder';
  static readonly RentOrderGetOrdersPath = '/api/v2/RentOrder';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RentOrderService.RentOrderCreateParams` containing the following parameters:
   *
   * - `rentableItemGroupId`:
   *
   * - `priceType`:
   *
   * - `paymentOptionId`:
   */
  RentOrderCreateResponse(params: RentOrderService.RentOrderCreateParams): __Observable<__StrictHttpResponse<RentOrderDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.rentableItemGroupId != null) __params = __params.set('rentableItemGroupId', params.rentableItemGroupId.toString());
    if (params.priceType != null) __params = __params.set('priceType', params.priceType.toString());
    if (params.paymentOptionId != null) __params = __params.set('paymentOptionId', params.paymentOptionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/RentOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RentOrderDto>;
      })
    );
  }
  /**
   * @param params The `RentOrderService.RentOrderCreateParams` containing the following parameters:
   *
   * - `rentableItemGroupId`:
   *
   * - `priceType`:
   *
   * - `paymentOptionId`:
   */
  RentOrderCreate(params: RentOrderService.RentOrderCreateParams): __Observable<RentOrderDto> {
    return this.RentOrderCreateResponse(params).pipe(
      __map(_r => _r.body as RentOrderDto)
    );
  }
  RentOrderGetOrdersResponse(): __Observable<__StrictHttpResponse<Array<RentOrderDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/RentOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RentOrderDto>>;
      })
    );
  }  RentOrderGetOrders(): __Observable<Array<RentOrderDto>> {
    return this.RentOrderGetOrdersResponse().pipe(
      __map(_r => _r.body as Array<RentOrderDto>)
    );
  }
}

module RentOrderService {

  /**
   * Parameters for RentOrderCreate
   */
  export interface RentOrderCreateParams {
    rentableItemGroupId?: number;
    priceType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
    paymentOptionId?: number;
  }
}

export { RentOrderService }
