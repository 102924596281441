import { Component, OnInit } from '@angular/core';
import { StripeScriptTag } from 'stripe-angular';
import { GlobalConfigService } from 'services/global-config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'MiniStorageWeb';

  constructor(
    private stripeScriptTag: StripeScriptTag,
    private config: GlobalConfigService,
    private translateService: TranslateService
  ) {
    const language = localStorage.getItem('language');
    if (!language) {
      localStorage.setItem('language', 'nb-NO');
      this.translateService.use('nb-NO');
    } else {
      this.translateService.use(language);
    }

  }

  ngOnInit() {
    this.stripeScriptTag.setPublishableKey(this.config.stripePublicKey);
  }
}
