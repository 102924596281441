import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, delay } from 'rxjs/operators';
import { LoaderService } from '../shared/loading-indicator/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  count = 0;
  constructor(public loaderService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('api') && !this.loaderService.forceHide) {
      this.count++;
      this.loaderService.show();
      return next.handle(req).pipe(finalize(() => {
        this.count--;
        if (this.count === 0) {
           this.loaderService.hide();
        }
      }));
    } else {
      return next.handle(req).pipe(finalize(() => {
        this.loaderService.setToDefault();
      }));
    }
  }
}
