import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderInterceptor } from 'services/loader.interceptor';
import { LoaderComponent } from './loading-indicator/loader.component';
import { LoaderService } from './loading-indicator/loader.service';



@NgModule({
  imports: [MatProgressBarModule,
    MatProgressSpinnerModule, CommonModule],
  providers: [LoaderService, {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true,
    deps: [LoaderService],
  }],
  declarations: [LoaderComponent],
  exports: [LoaderComponent]

})
export class LoaderModule { }



