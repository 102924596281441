/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedResultOfItem } from '../models/paged-result-of-item';
import { Item } from '../models/item';
import { ApplyBookablePeriods } from '../models/apply-bookable-periods';
import { RecurringOrder } from '../models/recurring-order';
import { RecurringDate } from '../models/recurring-date';
import { Period } from '../models/period';
@Injectable({
  providedIn: 'root',
})
class ItemService extends __BaseService {
  static readonly ItemGetPath = '/api/Item';
  static readonly ItemCreatePath = '/api/Item';
  static readonly ItemUpdatePath = '/api/Item';
  static readonly ItemGetStorageItemsPath = '/api/Item/StorageItems';
  static readonly ItemGetBookableItemsForLocationsPath = '/api/Item/BookableItemsForLocations';
  static readonly ItemGet2Path = '/api/Item/{id}';
  static readonly ItemDeletePath = '/api/Item/{id}';
  static readonly ItemApplyBookablePeriodsToAllPath = '/api/Item/ApplyBookablePeriods';
  static readonly ItemApplyBookablePeriodsConflictsPath = '/api/Item/ApplyBookablePeriodsConflicts/{locationId}/{itemId}';
  static readonly ItemEnablePath = '/api/Item/Enable/{id}';
  static readonly ItemDisablePath = '/api/Item/Disable/{id}';
  static readonly ItemCheckAvailabilityPath = '/api/Item/checkavailability/{orderLineId}/{id}/{startDate}/{endDate}';
  static readonly ItemCheckRecurranceAvailabilityPath = '/api/Item/{id}/checkRecurranceAvailability';
  static readonly ItemAvailableDatesPath = '/api/Item/{id}/AvailableDates';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all items
   * @param params The `ItemService.ItemGetParams` containing the following parameters:
   *
   * - `locationId`:
   *
   * - `itemType`:
   *
   * - `itemCategoryId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ItemGetResponse(params: ItemService.ItemGetParams): __Observable<__StrictHttpResponse<PagedResultOfItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
    if (params.itemType != null) __params = __params.set('itemType', params.itemType.toString());
    if (params.itemCategoryId != null) __params = __params.set('itemCategoryId', params.itemCategoryId.toString());
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfItem>;
      })
    );
  }
  /**
   * Get all items
   * @param params The `ItemService.ItemGetParams` containing the following parameters:
   *
   * - `locationId`:
   *
   * - `itemType`:
   *
   * - `itemCategoryId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ItemGet(params: ItemService.ItemGetParams): __Observable<PagedResultOfItem> {
    return this.ItemGetResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfItem)
    );
  }

  /**
   * Create item
   * @param patch undefined
   */
  ItemCreateResponse(patch: Item): __Observable<__StrictHttpResponse<Item>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patch;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Item>;
      })
    );
  }
  /**
   * Create item
   * @param patch undefined
   */
  ItemCreate(patch: Item): __Observable<Item> {
    return this.ItemCreateResponse(patch).pipe(
      __map(_r => _r.body as Item)
    );
  }

  /**
   * Update item
   * @param patch undefined
   */
  ItemUpdateResponse(patch: Item): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patch;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update item
   * @param patch undefined
   */
  ItemUpdate(patch: Item): __Observable<null> {
    return this.ItemUpdateResponse(patch).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get all items for the storage view
   * @param params The `ItemService.ItemGetStorageItemsParams` containing the following parameters:
   *
   * - `locationId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ItemGetStorageItemsResponse(params: ItemService.ItemGetStorageItemsParams): __Observable<__StrictHttpResponse<PagedResultOfItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Item/StorageItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfItem>;
      })
    );
  }
  /**
   * Get all items for the storage view
   * @param params The `ItemService.ItemGetStorageItemsParams` containing the following parameters:
   *
   * - `locationId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ItemGetStorageItems(params: ItemService.ItemGetStorageItemsParams): __Observable<PagedResultOfItem> {
    return this.ItemGetStorageItemsResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfItem)
    );
  }

  /**
   * Get bookable (available) items for given locationId
   * @param params The `ItemService.ItemGetBookableItemsForLocationsParams` containing the following parameters:
   *
   * - `timeTo`:
   *
   * - `timeFrom`:
   *
   * - `overrideFilteredItems`: True = Changes how activebookings property works, by setting 1 booking available if there is atleast 30 minutes or more available today
   *
   * - `locationId`:
   *
   * - `itemType`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ItemGetBookableItemsForLocationsResponse(params: ItemService.ItemGetBookableItemsForLocationsParams): __Observable<__StrictHttpResponse<PagedResultOfItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.timeTo != null) __params = __params.set('timeTo', params.timeTo.toString());
    if (params.timeFrom != null) __params = __params.set('timeFrom', params.timeFrom.toString());
    if (params.overrideFilteredItems != null) __params = __params.set('overrideFilteredItems', params.overrideFilteredItems.toString());
    if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
    if (params.itemType != null) __params = __params.set('itemType', params.itemType.toString());
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Item/BookableItemsForLocations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfItem>;
      })
    );
  }
  /**
   * Get bookable (available) items for given locationId
   * @param params The `ItemService.ItemGetBookableItemsForLocationsParams` containing the following parameters:
   *
   * - `timeTo`:
   *
   * - `timeFrom`:
   *
   * - `overrideFilteredItems`: True = Changes how activebookings property works, by setting 1 booking available if there is atleast 30 minutes or more available today
   *
   * - `locationId`:
   *
   * - `itemType`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ItemGetBookableItemsForLocations(params: ItemService.ItemGetBookableItemsForLocationsParams): __Observable<PagedResultOfItem> {
    return this.ItemGetBookableItemsForLocationsResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfItem)
    );
  }

  /**
   * Get item by id
   * @param id undefined
   */
  ItemGet2Response(id: number): __Observable<__StrictHttpResponse<Item>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Item/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Item>;
      })
    );
  }
  /**
   * Get item by id
   * @param id undefined
   */
  ItemGet2(id: number): __Observable<Item> {
    return this.ItemGet2Response(id).pipe(
      __map(_r => _r.body as Item)
    );
  }

  /**
   * Delete item
   * @param id undefined
   */
  ItemDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Item/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete item
   * @param id undefined
   */
  ItemDelete(id: number): __Observable<null> {
    return this.ItemDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create item
   * @param patch undefined
   */
  ItemApplyBookablePeriodsToAllResponse(patch: ApplyBookablePeriods): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patch;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Item/ApplyBookablePeriods`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create item
   * @param patch undefined
   */
  ItemApplyBookablePeriodsToAll(patch: ApplyBookablePeriods): __Observable<null> {
    return this.ItemApplyBookablePeriodsToAllResponse(patch).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get items whose bookable periods will be overwritten in case of applying bookable period of item
   * @param params The `ItemService.ItemApplyBookablePeriodsConflictsParams` containing the following parameters:
   *
   * - `locationId`:
   *
   * - `itemId`:
   */
  ItemApplyBookablePeriodsConflictsResponse(params: ItemService.ItemApplyBookablePeriodsConflictsParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Item/ApplyBookablePeriodsConflicts/${encodeURIComponent(String(params.locationId))}/${encodeURIComponent(String(params.itemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Get items whose bookable periods will be overwritten in case of applying bookable period of item
   * @param params The `ItemService.ItemApplyBookablePeriodsConflictsParams` containing the following parameters:
   *
   * - `locationId`:
   *
   * - `itemId`:
   */
  ItemApplyBookablePeriodsConflicts(params: ItemService.ItemApplyBookablePeriodsConflictsParams): __Observable<Array<string>> {
    return this.ItemApplyBookablePeriodsConflictsResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Enable item
   * @param id undefined
   */
  ItemEnableResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Item/Enable/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Enable item
   * @param id undefined
   */
  ItemEnable(id: number): __Observable<null> {
    return this.ItemEnableResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Disable item
   * @param id undefined
   */
  ItemDisableResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Item/Disable/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Disable item
   * @param id undefined
   */
  ItemDisable(id: number): __Observable<null> {
    return this.ItemDisableResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get item availability for provided dates
   * @param params The `ItemService.ItemCheckAvailabilityParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `orderLineId`:
   *
   * - `id`:
   *
   * - `endDate`:
   */
  ItemCheckAvailabilityResponse(params: ItemService.ItemCheckAvailabilityParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Item/checkavailability/${encodeURIComponent(String(params.orderLineId))}/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Get item availability for provided dates
   * @param params The `ItemService.ItemCheckAvailabilityParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `orderLineId`:
   *
   * - `id`:
   *
   * - `endDate`:
   */
  ItemCheckAvailability(params: ItemService.ItemCheckAvailabilityParams): __Observable<boolean> {
    return this.ItemCheckAvailabilityResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Check item availability for list of dates
   * @param params The `ItemService.ItemCheckRecurranceAvailabilityParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dateList`:
   */
  ItemCheckRecurranceAvailabilityResponse(params: ItemService.ItemCheckRecurranceAvailabilityParams): __Observable<__StrictHttpResponse<Array<RecurringOrder>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.dateList;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Item/${encodeURIComponent(String(params.id))}/checkRecurranceAvailability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RecurringOrder>>;
      })
    );
  }
  /**
   * Check item availability for list of dates
   * @param params The `ItemService.ItemCheckRecurranceAvailabilityParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dateList`:
   */
  ItemCheckRecurranceAvailability(params: ItemService.ItemCheckRecurranceAvailabilityParams): __Observable<Array<RecurringOrder>> {
    return this.ItemCheckRecurranceAvailabilityResponse(params).pipe(
      __map(_r => _r.body as Array<RecurringOrder>)
    );
  }

  /**
   * Get booked dates for item
   * @param params The `ItemService.ItemAvailableDatesParams` containing the following parameters:
   *
   * - `id`: Item id
   *
   * - `to`:
   *
   * - `locationId`:
   *
   * - `from`:
   */
  ItemAvailableDatesResponse(params: ItemService.ItemAvailableDatesParams): __Observable<__StrictHttpResponse<Array<Period>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Item/${encodeURIComponent(String(params.id))}/AvailableDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Period>>;
      })
    );
  }
  /**
   * Get booked dates for item
   * @param params The `ItemService.ItemAvailableDatesParams` containing the following parameters:
   *
   * - `id`: Item id
   *
   * - `to`:
   *
   * - `locationId`:
   *
   * - `from`:
   */
  ItemAvailableDates(params: ItemService.ItemAvailableDatesParams): __Observable<Array<Period>> {
    return this.ItemAvailableDatesResponse(params).pipe(
      __map(_r => _r.body as Array<Period>)
    );
  }
}

module ItemService {

  /**
   * Parameters for ItemGet
   */
  export interface ItemGetParams {
    locationId?: null | number;
    itemType?: null | number;
    itemCategoryId?: null | number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for ItemGetStorageItems
   */
  export interface ItemGetStorageItemsParams {
    locationId?: number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for ItemGetBookableItemsForLocations
   */
  export interface ItemGetBookableItemsForLocationsParams {
    timeTo?: null | string;
    timeFrom?: null | string;

    /**
     * True = Changes how activebookings property works, by setting 1 booking available if there is atleast 30 minutes or more available today
     */
    overrideFilteredItems?: boolean;
    locationId?: null | number;
    itemType?: null | number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for ItemApplyBookablePeriodsConflicts
   */
  export interface ItemApplyBookablePeriodsConflictsParams {
    locationId: number;
    itemId: number;
  }

  /**
   * Parameters for ItemCheckAvailability
   */
  export interface ItemCheckAvailabilityParams {
    startDate: string;
    orderLineId: number;
    id: number;
    endDate: string;
  }

  /**
   * Parameters for ItemCheckRecurranceAvailability
   */
  export interface ItemCheckRecurranceAvailabilityParams {
    id: number;
    dateList: {[key: string]: RecurringDate};
  }

  /**
   * Parameters for ItemAvailableDates
   */
  export interface ItemAvailableDatesParams {

    /**
     * Item id
     */
    id: number;
    to?: string;
    locationId?: number;
    from?: string;
  }
}

export { ItemService }
