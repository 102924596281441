/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedResultOfCompany } from '../models/paged-result-of-company';
import { Company } from '../models/company';
@Injectable({
  providedIn: 'root',
})
class CompanyService extends __BaseService {
  static readonly CompanyGetPath = '/api/Company';
  static readonly CompanyCreatePath = '/api/Company';
  static readonly CompanyUpdatePath = '/api/Company';
  static readonly CompanyGet2Path = '/api/Company/{id}';
  static readonly CompanyDeletePath = '/api/Company/{id}';
  static readonly CompanyGetParentPath = '/api/Company/{id}/parent';
  static readonly CompanyGetChildrenPath = '/api/Company/children';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get companies
   * @param params The `CompanyService.CompanyGetParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  CompanyGetResponse(params: CompanyService.CompanyGetParams): __Observable<__StrictHttpResponse<PagedResultOfCompany>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfCompany>;
      })
    );
  }
  /**
   * Get companies
   * @param params The `CompanyService.CompanyGetParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  CompanyGet(params: CompanyService.CompanyGetParams): __Observable<PagedResultOfCompany> {
    return this.CompanyGetResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfCompany)
    );
  }

  /**
   * Create company
   * @param company undefined
   */
  CompanyCreateResponse(company: Company): __Observable<__StrictHttpResponse<Company>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = company;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Company>;
      })
    );
  }
  /**
   * Create company
   * @param company undefined
   */
  CompanyCreate(company: Company): __Observable<Company> {
    return this.CompanyCreateResponse(company).pipe(
      __map(_r => _r.body as Company)
    );
  }

  /**
   * Update company
   * @param company undefined
   */
  CompanyUpdateResponse(company: Company): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = company;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update company
   * @param company undefined
   */
  CompanyUpdate(company: Company): __Observable<null> {
    return this.CompanyUpdateResponse(company).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get company by id
   * @param id undefined
   */
  CompanyGet2Response(id: number): __Observable<__StrictHttpResponse<Company>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Company>;
      })
    );
  }
  /**
   * Get company by id
   * @param id undefined
   */
  CompanyGet2(id: number): __Observable<Company> {
    return this.CompanyGet2Response(id).pipe(
      __map(_r => _r.body as Company)
    );
  }

  /**
   * Delete company
   * @param id undefined
   */
  CompanyDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete company
   * @param id undefined
   */
  CompanyDelete(id: number): __Observable<null> {
    return this.CompanyDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  CompanyGetParentResponse(id: number): __Observable<__StrictHttpResponse<Company>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(id))}/parent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Company>;
      })
    );
  }
  /**
   * @param id undefined
   */
  CompanyGetParent(id: number): __Observable<Company> {
    return this.CompanyGetParentResponse(id).pipe(
      __map(_r => _r.body as Company)
    );
  }

  /**
   * @param params The `CompanyService.CompanyGetChildrenParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  CompanyGetChildrenResponse(params: CompanyService.CompanyGetChildrenParams): __Observable<__StrictHttpResponse<PagedResultOfCompany>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/children`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfCompany>;
      })
    );
  }
  /**
   * @param params The `CompanyService.CompanyGetChildrenParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  CompanyGetChildren(params: CompanyService.CompanyGetChildrenParams): __Observable<PagedResultOfCompany> {
    return this.CompanyGetChildrenResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfCompany)
    );
  }
}

module CompanyService {

  /**
   * Parameters for CompanyGet
   */
  export interface CompanyGetParams {
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for CompanyGetChildren
   */
  export interface CompanyGetChildrenParams {
    UpdatedAfter?: null | string;
    Query?: number;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }
}

export { CompanyService }
