<ng-template ngx-datatable-footer-template>
    <div style="width: 200px; margin-left: 20px;" *ngIf="page">
        <ng-container >{{'current_page' | translate}} </ng-container>
        <span>{{page}} / {{totalPages}}</span>
    </div>
    <div class="pageSizeSelect">
        <ng-container >{{'rows_per_page' | translate}}</ng-container>
        <mat-select name="pageSizeSelect" [value]="pageSize">
            <mat-option *ngFor="let singleSize of pageSizes" [value]="singleSize" (click)="onPageSizeChange(singleSize)">
                {{ singleSize }}
            </mat-option>
        </mat-select>
    </div>
    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="page" [size]="pageSize" [count]="rowCount" [hidden]="!showPager"
        (change)="onPageChange($event)">
    </datatable-pager>
</ng-template>