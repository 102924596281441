/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedResultOfPayment } from '../models/paged-result-of-payment';
import { Payment } from '../models/payment';
import { PaymentRefund } from '../models/payment-refund';
import { PaymentCharge } from '../models/payment-charge';
import { PagedResultOfPaymentOption } from '../models/paged-result-of-payment-option';
import { PaymentOption } from '../models/payment-option';
@Injectable({
  providedIn: 'root',
})
class PaymentService extends __BaseService {
  static readonly PaymentGetPath = '/api/Payment';
  static readonly PaymentGet2Path = '/api/Payment/{id}';
  static readonly PaymentGetItemPaymentsPath = '/api/Payment/ItemPayments/{id}';
  static readonly PaymentRefundPath = '/api/Payment/refund';
  static readonly PaymentOrderLineRefundPath = '/api/Payment/{orderLine}';
  static readonly PaymentChargePath = '/api/Payment/charge';
  static readonly PaymentGetPaymentOptionsPath = '/api/Payment/options/{userId}';
  static readonly PaymentDeletePath = '/api/Payment/options/{userId}/{id}';
  static readonly PaymentCreatePath = '/api/Payment/options';
  static readonly PaymentUpdatePath = '/api/Payment/options';
  static readonly PaymentMarkAsDefaultPath = '/api/Payment/options/{userId}/{id}/default';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get payments for given orderId or for current user if orderId is empty
   * @param params The `PaymentService.PaymentGetParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  PaymentGetResponse(params: PaymentService.PaymentGetParams): __Observable<__StrictHttpResponse<PagedResultOfPayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orderId != null) __params = __params.set('orderId', params.orderId.toString());
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfPayment>;
      })
    );
  }
  /**
   * Get payments for given orderId or for current user if orderId is empty
   * @param params The `PaymentService.PaymentGetParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  PaymentGet(params: PaymentService.PaymentGetParams): __Observable<PagedResultOfPayment> {
    return this.PaymentGetResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfPayment)
    );
  }

  /**
   * Get payment for given paymentId
   * @param id undefined
   */
  PaymentGet2Response(id: number): __Observable<__StrictHttpResponse<Payment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Payment/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Payment>;
      })
    );
  }
  /**
   * Get payment for given paymentId
   * @param id undefined
   */
  PaymentGet2(id: number): __Observable<Payment> {
    return this.PaymentGet2Response(id).pipe(
      __map(_r => _r.body as Payment)
    );
  }

  /**
   * Get payments by order
   * @param id undefined
   */
  PaymentGetItemPaymentsResponse(id: number): __Observable<__StrictHttpResponse<Array<Payment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Payment/ItemPayments/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Payment>>;
      })
    );
  }
  /**
   * Get payments by order
   * @param id undefined
   */
  PaymentGetItemPayments(id: number): __Observable<Array<Payment>> {
    return this.PaymentGetItemPaymentsResponse(id).pipe(
      __map(_r => _r.body as Array<Payment>)
    );
  }

  /**
   * Refund order with given orderId
   * @param params The `PaymentService.PaymentRefundParams` containing the following parameters:
   *
   * - `paymentRefund`:
   *
   * - `refundAll`: On delete order decide wether to refund all payments or last one only
   */
  PaymentRefundResponse(params: PaymentService.PaymentRefundParams): __Observable<__StrictHttpResponse<Payment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.paymentRefund;
    if (params.refundAll != null) __params = __params.set('refundAll', params.refundAll.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Payment/refund`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Payment>;
      })
    );
  }
  /**
   * Refund order with given orderId
   * @param params The `PaymentService.PaymentRefundParams` containing the following parameters:
   *
   * - `paymentRefund`:
   *
   * - `refundAll`: On delete order decide wether to refund all payments or last one only
   */
  PaymentRefund(params: PaymentService.PaymentRefundParams): __Observable<Payment> {
    return this.PaymentRefundResponse(params).pipe(
      __map(_r => _r.body as Payment)
    );
  }

  /**
   * @param params The `PaymentService.PaymentOrderLineRefundParams` containing the following parameters:
   *
   * - `paymentRefund`:
   *
   * - `orderLine`:
   */
  PaymentOrderLineRefundResponse(params: PaymentService.PaymentOrderLineRefundParams): __Observable<__StrictHttpResponse<Payment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.paymentRefund;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/Payment/${encodeURIComponent(String(params.orderLine))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Payment>;
      })
    );
  }
  /**
   * @param params The `PaymentService.PaymentOrderLineRefundParams` containing the following parameters:
   *
   * - `paymentRefund`:
   *
   * - `orderLine`:
   */
  PaymentOrderLineRefund(params: PaymentService.PaymentOrderLineRefundParams): __Observable<Payment> {
    return this.PaymentOrderLineRefundResponse(params).pipe(
      __map(_r => _r.body as Payment)
    );
  }

  /**
   * Charge order with given paymentOptionId
   * @param paymentCharge undefined
   */
  PaymentChargeResponse(paymentCharge: PaymentCharge): __Observable<__StrictHttpResponse<Payment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = paymentCharge;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Payment/charge`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Payment>;
      })
    );
  }
  /**
   * Charge order with given paymentOptionId
   * @param paymentCharge undefined
   */
  PaymentCharge(paymentCharge: PaymentCharge): __Observable<Payment> {
    return this.PaymentChargeResponse(paymentCharge).pipe(
      __map(_r => _r.body as Payment)
    );
  }

  /**
   * Get payment options for given userId
   * @param params The `PaymentService.PaymentGetPaymentOptionsParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  PaymentGetPaymentOptionsResponse(params: PaymentService.PaymentGetPaymentOptionsParams): __Observable<__StrictHttpResponse<PagedResultOfPaymentOption>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Payment/options/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfPaymentOption>;
      })
    );
  }
  /**
   * Get payment options for given userId
   * @param params The `PaymentService.PaymentGetPaymentOptionsParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  PaymentGetPaymentOptions(params: PaymentService.PaymentGetPaymentOptionsParams): __Observable<PagedResultOfPaymentOption> {
    return this.PaymentGetPaymentOptionsResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfPaymentOption)
    );
  }

  /**
   * Delete given payment option
   * @param params The `PaymentService.PaymentDeleteParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `id`:
   */
  PaymentDeleteResponse(params: PaymentService.PaymentDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Payment/options/${encodeURIComponent(String(params.userId))}/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete given payment option
   * @param params The `PaymentService.PaymentDeleteParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `id`:
   */
  PaymentDelete(params: PaymentService.PaymentDeleteParams): __Observable<null> {
    return this.PaymentDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Add payment option for user
   * @param patch undefined
   */
  PaymentCreateResponse(patch: PaymentOption): __Observable<__StrictHttpResponse<PaymentOption>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patch;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Payment/options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentOption>;
      })
    );
  }
  /**
   * Add payment option for user
   * @param patch undefined
   */
  PaymentCreate(patch: PaymentOption): __Observable<PaymentOption> {
    return this.PaymentCreateResponse(patch).pipe(
      __map(_r => _r.body as PaymentOption)
    );
  }

  /**
   * Edit payment option for user
   * @param patch undefined
   */
  PaymentUpdateResponse(patch: PaymentOption): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patch;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Payment/options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Edit payment option for user
   * @param patch undefined
   */
  PaymentUpdate(patch: PaymentOption): __Observable<null> {
    return this.PaymentUpdateResponse(patch).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Set given payment option as default
   * @param params The `PaymentService.PaymentMarkAsDefaultParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `id`:
   */
  PaymentMarkAsDefaultResponse(params: PaymentService.PaymentMarkAsDefaultParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Payment/options/${encodeURIComponent(String(params.userId))}/${encodeURIComponent(String(params.id))}/default`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Set given payment option as default
   * @param params The `PaymentService.PaymentMarkAsDefaultParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `id`:
   */
  PaymentMarkAsDefault(params: PaymentService.PaymentMarkAsDefaultParams): __Observable<null> {
    return this.PaymentMarkAsDefaultResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PaymentService {

  /**
   * Parameters for PaymentGet
   */
  export interface PaymentGetParams {
    orderId?: null | number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for PaymentRefund
   */
  export interface PaymentRefundParams {
    paymentRefund: PaymentRefund;

    /**
     * On delete order decide wether to refund all payments or last one only
     */
    refundAll?: boolean;
  }

  /**
   * Parameters for PaymentOrderLineRefund
   */
  export interface PaymentOrderLineRefundParams {
    paymentRefund: PaymentRefund;
    orderLine: number;
  }

  /**
   * Parameters for PaymentGetPaymentOptions
   */
  export interface PaymentGetPaymentOptionsParams {
    userId: number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for PaymentDelete
   */
  export interface PaymentDeleteParams {
    userId: number;
    id: number;
  }

  /**
   * Parameters for PaymentMarkAsDefault
   */
  export interface PaymentMarkAsDefaultParams {
    userId: number;
    id: number;
  }
}

export { PaymentService }
