import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseComponent } from 'components/shared/base.component';
import { AlertService } from 'services/alert.service';
import { TokenData } from 'models/login.model';
import { AuthService } from 'services/auth.service';
import * as moment from 'moment';
import { OrderService, UserService, ItemService } from 'api/services';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalConfigService } from 'services/global-config.service';
import { HttpClient } from '@angular/common/http';
import * as ICal from 'ical.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-book-children-birthday',
  templateUrl: './book-children-birthday.component.html',
  styleUrls: ['./book-children-birthday.component.css']
})
export class BookChildrenBirthdayComponent extends BaseComponent implements OnInit {
  userTokenData: TokenData;
  data: any;
  user: any;
  showLoadingIcon = false;
  saveForm: FormGroup;
  price = 0;
  itemPrice = 0;
  prices = [
    {
      name: 'Oppgradering fra 70g - 100g kr 30',
      price: 30
    },
    {
      name: 'Oppgradering fra 70g - 160g kr 50',
      price: 50
    },
    {
      name: 'Ekstra pølse kr 22',
      price: 22
    },
    {
      name: '0,4l brus/slush kr 5',
      price: 5
    },
    {
      name: '0,5l brus eller stor juice/litago kr 10',
      price: 10
    },
    {
      name: 'Bursdagssoftis kr 40',
      price: 40
    },
    {
      name: 'Oppgradering: fra 2 til 4 pizzabiter pr person kr 30.',
      price: 30
    },
    {
      name: 'Ost kr 10.',
      price: 10
    }
  ];
  constructor(
    alertService: AlertService,
    router: Router,
    public translateService: TranslateService,
    private dialogRef: MatDialogRef<BookChildrenBirthdayComponent>,
    private authService: AuthService,
    private orderService: OrderService,
    private userService: UserService,
    private fb: FormBuilder,
    private itemService: ItemService,
    private httpClient: HttpClient,


  ) {
    super(alertService, router, translateService);
    this.userTokenData = this.authService.getTokenData();
    this.userService.UserGet(Number(this.userTokenData.sub)).subscribe(res => {

      this.user = res;

    }, err => {
      this.defaultErrorHandler(err);
    });
    this.createForm();



    this.saveForm.get('typeOfFood').valueChanges.subscribe(res => {
      this.saveForm.patchValue({
        additionalFoodInfo: null,
        dressing: null,
        foodInfo: null
      });
    });
    this.saveForm.get('additionalFoodInfo').valueChanges.subscribe(res => {
      this.saveForm.patchValue({
        dressing: null
      });
    });
  }

  ngOnInit() {
      this.itemService.ItemGet2(this.data.id).subscribe(res => {
         this.itemPrice = res.prices && res.prices.length > 0 ? res.prices[0].value : 0;
      });

  }

  createForm() {
    this.saveForm = this.fb.group({
      numberOfChildren: new FormControl(8, [Validators.required, Validators.min(8)]),
      allergies: new FormControl(''),
      typeOfFood: new FormControl(null, Validators.required),
      foodInfo: new FormControl(null),
      additionalFoodInfo: new FormControl(null),
      dressing: new FormControl(null),
      drink: new FormControl(null),
      dessert: new FormControl(null),
      totalPrice: new FormControl(0)
    });
  }
  buy() {
    if (this.saveForm.value.typeOfFood === 'Pizza' && !this.saveForm.value.additionalFoodInfo) {
      this.translateService.get('type_of_pizza').subscribe(message => {
        this.alertService.error(message, 2000);
      });
      return;
    }
    if (!this.saveForm.value.typeOfFood) {
      this.translateService.get('select_type_of_food').subscribe(message => {
            this.alertService.error(message, 2000);
      });

      return;
    }
    if (this.saveForm.value.typeOfFood === 'Hamburger'
     && this.saveForm.value.additionalFoodInfo
     && !this.saveForm.value.dressing) {
       this.translateService.get('type_of_hamburger').subscribe(message => {
          this.alertService.error(message, 2000);
       });

      return;
    }
    if (!this.saveForm.valid) {
      return;
    }

    const calendar = new ICal.Component(['vcalendar', [], []]);
    calendar.updatePropertyWithValue('userId', Number(this.userTokenData.sub));
    calendar.updatePropertyWithValue('itemId', this.data.id);

      const vevent = new ICal.Component('vevent');
      const _event = new ICal.Event(vevent);

      _event.userId = Number(this.userTokenData.sub);

      _event.startDate = new ICal.Time.fromDateTimeString(
        this.convertDate(this.data.start)
      );
      _event.endDate = new ICal.Time.fromDateTimeString(
        this.convertDate(this.data.end)
      );

      calendar.addSubcomponent(vevent);
      const bookingLines = [];
      bookingLines.push({
      itemId: this.data.id,
      item: { id: this.data.id},
      quantity: 1
      });
      const data = {
        UserId: Number(this.userTokenData.sub),
        User: {
          Id: Number(this.userTokenData.sub)
        },
        BookingCalendar: calendar.toString(),
        BookingLines: bookingLines,
        AdditionalData: JSON.stringify(this.saveForm.value),
      };
      this.showLoadingIcon = true;
      this.httpClient.post('/api/Booking', data).subscribe(res => {
      this.showLoadingIcon = false;
      this.dialogRef.close();


  this.orderService.OrderGet2(res['orderId']).subscribe(result => {

  this.httpClient.post('/api/Order/birthdayCustomer', result).subscribe(res => {

    });
    this.httpClient.post('/api/Order/birthdayPirbadet', result.orderLines[0]).subscribe(res => {

    });
    this.translateService.get('success_booked').subscribe(message => {
      this.alertService.success(message, 3000);
    });

    }, err => {
      this.defaultErrorHandler(err);
    });
  });





  }
  close() {
    this.dialogRef.close();
  }

  convertDate(date, zone?) {
    if (zone === 'local') {
      date = moment(date).utc(true).format('YYYY-MM-DDTHH:mm:ss[Z]');
      date = moment(date).local();
      return new Date(date);
    } else {
      date = moment(date).local(false).format();

      return moment(date).add(-2, 'hours').format('YYYY-MM-DDTHH:mm:ss') + 'Z';

    }
  }
  calculatePrice() {

    const additionalFoodInfo = this.saveForm.value.additionalFoodInfo;
    const dressing = this.saveForm.value.dressing;
    const drink = this.saveForm.value.drink;
    const dessert = this.saveForm.value.dessert;
    const foodInfo = this.saveForm.value.foodInfo;
    this.price = this.itemPrice;
    if (foodInfo) {
      const priceValue = this.prices.find(x => x.name === foodInfo);
      if (priceValue) {
        this.price = this.price + priceValue.price;
      }
    }
    if (additionalFoodInfo) {
      const priceValue = this.prices.find(x => x.name === additionalFoodInfo);
      if (priceValue) {
        this.price = this.price + priceValue.price;
      }
    }
    if (dressing) {
      const priceValue = this.prices.find(x => x.name === dressing);
      if (priceValue) {
        this.price = this.price + priceValue.price;
      }
    }
    if (drink) {
      const priceValue = this.prices.find(x => x.name === drink);
      if (priceValue) {
        this.price = this.price + priceValue.price;
      }
    }
    if (dessert) {
      const priceValue = this.prices.find(x => x.name === dessert);
      if (priceValue) {
        this.price = this.price + priceValue.price;
      }
    }


    if (this.saveForm.value.numberOfChildren) {
      const totalPrice = this.price * this.saveForm.value.numberOfChildren;
      this.saveForm.patchValue({
        totalPrice: totalPrice
      });
      return totalPrice;
    } else {
      return this.price;
    }

  }
}
