import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
// tslint:disable:max-line-length
import { ConfirmDialogComponent } from '../components/secure/dialogs/confirm-dialog/confirm-dialog.component';
import { BuyTicketsDialogComponent } from 'components/secure/dialogs/buy-tickets-dialog/buy-tickets-dialog.component';
import { Item } from 'api/models';
import { BookItemComponent } from 'components/secure/dialogs/book-item/book-item.component';
import { QrCodeDialogComponent } from '../components/secure/dialogs/qr-code/qr-code-dialog.component';
import { ConfirmMonthlyDialogComponent } from 'components/secure/dialogs/confirm-monhtly/confirm-monthly-dialog.component';
import { BookChildrenBirthdayComponent } from '../components/secure/dialogs/book-children-birthday/book-children-birthday.component';

interface DialogSize {
  width: string;
  maxWidth: string;
  height: string;
  maxHeight: string;
}

const defaultDialogSize: DialogSize = {
  width: '650px',
  maxWidth: 'auto',
  height: 'auto',
  maxHeight: 'auto'
};

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {}

  public confirm(
    title: string,
    message: string,
    htmlMessage = '',
    size = { width: '500px', height: '220px', maxHeight: 'auto' }
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent>;

    dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: size.width,
      height: size.height,
      maxHeight: size.maxHeight
    });

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.htmlMessage = htmlMessage;

    return dialogRef.afterClosed();
  }


  public buy(
    items: Item[],
    cartSum: number,
    odrderLines: any,
    booking,
    giftTicket,
    monthly
  ): Observable<any> {
    let dialogRef: MatDialogRef<BuyTicketsDialogComponent>;

    dialogRef = this.dialog.open(BuyTicketsDialogComponent, {
      width: '820px',
      height: '600px',
      panelClass: 'buy-dialog'
    });

    dialogRef.componentInstance.items = items;
    dialogRef.componentInstance.cartSum = cartSum;
    dialogRef.componentInstance.orderLines = odrderLines;
    dialogRef.componentInstance.booking = booking;
    dialogRef.componentInstance.giftTicket = giftTicket;
    dialogRef.componentInstance.monthly = monthly;

    return dialogRef.afterClosed();
  }

  public bookItem(data): Observable<any> {
    let dialogRef: MatDialogRef<BookItemComponent>;

    dialogRef = this.dialog.open(
      BookItemComponent,
      {
        width: '800px',
        height: '600px',
        panelClass: 'book-item-dialog'
      }
    );

    dialogRef.componentInstance.data = data;
    return dialogRef.afterClosed();
  }

  public bookChildrenBirthday(data): Observable<any> {
    let dialogRef: MatDialogRef<BookChildrenBirthdayComponent>;

    dialogRef = this.dialog.open(
      BookChildrenBirthdayComponent,
      {
        width: '800px',
        height: '600px',
        panelClass: 'book-children-birthday-dialog'
      }
    );

    dialogRef.componentInstance.data = data;
    return dialogRef.afterClosed();
  }
  public showQrCode(
    data: any
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<QrCodeDialogComponent>;

    dialogRef = this.dialog.open(QrCodeDialogComponent, {
      data: data
    });

    dialogRef.componentInstance.data = data;

    return dialogRef.afterClosed();
  }
  public confirmMonthlyBooking(
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmMonthlyDialogComponent>;

    dialogRef = this.dialog.open(ConfirmMonthlyDialogComponent, {
      maxWidth: '500px',
      panelClass: 'monthly-confirm'
    });


    return dialogRef.afterClosed();
  }

}
