
  <mat-card class="booking-mat-card">
    <div class="mt-2" style="display: flex; height: 100%; flex-direction: column;">

        <!-- <mat-form-field appearance="outline">
            <mat-label i18n="@@Items">Items</mat-label>
            <mat-select (selectionChange)="onItemChange($event)">
                <mat-option *ngFor="let item of items" [value]="item">{{ getName(item)}}</mat-option>

            </mat-select>


        </mat-form-field> -->
<mat-card *ngIf="itemsList.length !== 0" class="item-select">

  <button (click)="itemSelectedChange(item)" [ngClass]="{'disabled-item': !item.selected}" *ngFor="let item of itemsList" [ngStyle]="item.item.color ? {'background-color': item.item.color} : {'background-color': '#7b2cb5'}"
   class="ml-3" mat-raised-button color="primary" >{{ getName(item.item) }}</button>

  <!-- <mat-button-toggle-group  multiple>
    <mat-button-toggle (change)="itemSelectedChange(item)" [checked]="item.selected" *ngFor="let item of itemsList" [value]="item.item.id">{{ getName(item.item) }}</mat-button-toggle>
  </mat-button-toggle-group> -->
</mat-card>

        <div style="display: flex;
        width: 100%;
        flex-direction: row;">
        <div class="navigate">
          <mat-icon (click)="navigateBack()" >navigate_before</mat-icon>
        </div>
          <div class="day-row" *ngFor="let day of dates">

            <div class="day-name">
            <span> {{ getDayName(day)}}</span>
             <span>{{ getDate(day)}}</span>
            </div>



           </div>
           <div class="navigate">
            <mat-icon (click)="navigateNext()" >navigate_next</mat-icon>
           </div>
        </div>

        <div style="display: flex;
        overflow-x: hidden; width: 100%; height: 100%;" class="mt-3">
          <div class="day-row" *ngFor="let day of dates">
            <div class="event-wrapper">
          <div [ngStyle]="{'background-color': item.color ? item.color : '#7b2cb5'}" class="events" *ngFor="let item of getDayEvents(day)">
            <span>{{ getEventTime(item)}}</span>
            <span class="mt-3">{{ item.title}}</span>
            <span>{{item.available}}/{{item.total}} ledige</span>

            <div class="book-button" style="visibility: hidden;" >
              <button style="background-color: #c23277;"  (click)="eventClick(item)" type="button" [ngClass]="{'disabled-item': item.available <= 0}"  mat-raised-button color="primary">{{'book' | translate}}</button>

            </div>

         </div>
            </div>
        </div>
        </div>

    </div>

  </mat-card>
