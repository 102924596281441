import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormGroup, FormArray } from '@angular/forms';
import { AlertService } from 'services/alert.service';
import { TranslateService } from '@ngx-translate/core';

export class BaseComponent {
  constructor(
    public alertService: AlertService,
    public router: Router,
    public translateService: TranslateService
  ) {}

  protected defaultErrorHandler(error: HttpErrorResponse) {
    const autoClear = 5000;
    const body = error.error;
    const fault = body && body.error ? body.error : null;
    switch (error.status) {
      case 400:
        if (!body.error) {
          this.translateService.get('bad_request').subscribe(message => {
            this.alertService.error(message, autoClear);
          });

        } else {
          this.alertService.error(body.error);
        }
        break;
      case 401:
        localStorage.removeItem('tokenData');
        this.translateService.get('no_authorized').subscribe(message => {
           const trans = message + ' ' + fault;
           this.alertService.error(trans, autoClear);
        });
        this.router.navigate(['/login']);
        break;
      case 403:
        this.translateService.get('forbidden_action').subscribe(message => {
        const trans = message + ' ' + fault;
        this.alertService.error(trans, autoClear);
        });

        break;
      case 404:
        this.router.navigateByUrl('/not-found');
        break;
      case 409:
        this.alertService.error(body.error);
        break;
      case 500:
      default:
        if (fault) {
          this.alertService.error(fault, autoClear);
        } else {
          this.translateService.get('unknown_error').subscribe(message => {
             this.alertService.error(message, autoClear);
          });

        }
        break;
    }
  }

  protected getAllErrors(
    form: FormGroup | FormArray
  ): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce(
      (acc, key) => {
        const control = form.get(key);
        const errors =
          control instanceof FormGroup || control instanceof FormArray
            ? this.getAllErrors(control)
            : control.errors;
        if (errors) {
          acc[key] = errors;
          hasError = true;
        }
        return acc;
      },
      {} as { [key: string]: any }
    );
    return hasError ? result : null;
  }

  protected showErrorMessages(form: FormGroup | FormArray) {
    const errors = this.getAllErrors(form);
    let message = '';
    message += this.iterateErrors(errors, message);

    if (message) {
      this.alertService.error(message);
    }
  }

  protected iterateErrors(errors, message) {
    for (const key in errors) {
      if (typeof errors === 'object' && errors.hasOwnProperty(key)) {

      }
    }

    return message;
  }

  canDeactivate() {
    return true;
  }
}
