import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.css']
})
export class QrCodeDialogComponent implements OnInit {
  public data: any;

  constructor(private dialogRef: MatDialogRef<QrCodeDialogComponent>) {

  }
  ngOnInit(): void {
  }

  onCloseDialog(confirm: boolean) {
    this.dialogRef.close(confirm);
  }
}
