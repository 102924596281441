import { Component, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { Router } from '@angular/router';
import { BasePagedComponent } from '../../shared/base-paged.component';
import { AlertService } from 'services/alert.service';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { OrderService } from 'api/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css']
})
export class SubscriptionsComponent extends BasePagedComponent {
  showLoadingIcon = false;
  tableData: any = {};
  items: any[] = [];
  recurringCancelled = {};
  orderStatus = [
    'Pending',
    'Ordered',
    'Completed',
    'Cancelled'
  ];
  paymentStatus = ['NotPaid', 'Paid', 'Refunded'];
  itemTypes = [
    'Rent',
    'Single',
    'Year',
    'HalfYear',
    'Month',
    'Journey',
    'Purchase'
  ];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  expanded: any;

  constructor(
    alertService: AlertService,
    router: Router,
    public translateService: TranslateService,
    private orderService: OrderService,
    private cdRef: ChangeDetectorRef
  ) {
    super(alertService, router, translateService);
  }
  ngAfterViewChecked() {
    if (this.table && this.table.rowDetail) {
      this.table.rowDetail.expandAllRows();
      this.cdRef.detectChanges();
    }
  }

  loadData() {
    this.showLoadingIcon = true;
    this.orderService.OrderGetSubscriptionsForCurrentUser().subscribe(
      data => {
        this.items = data;
        this.showLoadingIcon = false;
      },
      err => {
        this.showLoadingIcon = false;
        this.defaultErrorHandler(err);
      }
    );
  }

  toggleExpandRow(row) {
    if (row.type !== 'click') {
      return;
    }
    this.table.rowDetail.toggleExpandRow(row.row);
  }

  toggleExpandAllRows() {
    if (!this.expanded) {
      this.table.rowDetail.expandAllRows();
    } else {
      this.table.rowDetail.collapseAllRows();
    }
    this.expanded = !this.expanded;
  }

  cancelRecurring(row) {
    row.cancelRecurring = true;
    // TODO Should cancellation of recurring orderline call order update endpoint, or new one should be made for cancellation?
  }
}
