import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../shared/base.component';
import { AuthService } from 'services/auth.service';
import { AlertService } from 'services/alert.service';
import { UserService } from 'api/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {
  hidePassword = true;
  showLoadingIcon = false;
  changeForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    router: Router,
    private authService: AuthService,
    alertService: AlertService,
    public translateService: TranslateService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    super(alertService, router, translateService);
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.changeForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(4)]],
      newPasswordRepeat: ['', [Validators.required, Validators.minLength(4)]],
      resetKey: ['',Validators.required]
    });
  }

  submit() {
    if (!this.changeForm.valid) {
      return;
    }
    this.showLoadingIcon = true;
    this.alertService.clear();

    this.userService
      .UserResetPasswordByCode(this.changeForm.value )
      .subscribe(
        result => {
          this.translateService.get('successfully_changed_password_now_you_can_login_with_your_new password').subscribe(message => {
            this.alertService.success(message);
          });

          this.router.navigate(['/login']);
          this.showLoadingIcon = false;
        },
        err => {
          this.defaultErrorHandler(err);
          this.showLoadingIcon = false;
        }
      );
  }
}
