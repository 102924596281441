import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base.component';
import { AuthService } from 'services/auth.service';
import { AlertService } from 'services/alert.service';
import { UserService } from 'api/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './recoverpassword.component.html',
  styleUrls: ['./recoverpassword.component.css']
})
export class RecoverPasswordComponent extends BaseComponent implements OnInit {
  hidePassword = true;
  showLoadingIcon = false;
  recoverForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    router: Router,
    private authService: AuthService,
    alertService: AlertService,
    public translateService: TranslateService,
    private userService: UserService
  ) {
    super(alertService, router, translateService);
    this.createForm();
  }

  ngOnInit() {
    const userToken = this.authService.getToken();

    if (userToken) {
      this.router.navigate(['/']);
    }
  }

  createForm() {
    this.recoverForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(4)]]
    });
  }

  submit() {
    if (!this.recoverForm.valid) {
      return;
    }

    this.showLoadingIcon = true;
    this.alertService.clear();

    const userName=this.recoverForm.get('username').value.toString();

    this.userService
      .UserRecoverPasswordByEmailOrMobile(userName)
      .subscribe(
        result => {
          this.translateService.get('check_email_instructions').subscribe(message=>{
            this.alertService.success(message);
          });


          this.router.navigate(['/changepassword']);
          this.showLoadingIcon = false;
        },
        err => {
          this.defaultErrorHandler(err);
          this.showLoadingIcon = false;
        }
      );
  }
}
