import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from './loader.service';
import { Subscription, BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  sub: Subscription;
  constructor(public loaderService: LoaderService) {

  }
}
