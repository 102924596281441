/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ItemAccessSimple } from '../models/item-access-simple';
import { PagedResultOfItemAccessResponse } from '../models/paged-result-of-item-access-response';
@Injectable({
  providedIn: 'root',
})
class ItemAccessService extends __BaseService {
  static readonly ItemAccessSavePath = '/api/ItemAccess/save';
  static readonly ItemAccessSave2Path = '/api/ItemAccess';
  static readonly ItemAccessPagedQueryPath = '/api/ItemAccess';
  static readonly ItemAccessPagedQueryByUserIdPath = '/api/ItemAccess/user/{userId}';
  static readonly ItemAccessPagedQueryByItemIdPath = '/api/ItemAccess/{id}';
  static readonly ItemAccessMyVisitsPath = '/api/ItemAccess/myvisits';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Save the given list of itemAccess to database
   * @param itemAccessList undefined
   */
  ItemAccessSaveResponse(itemAccessList: Array<ItemAccessSimple>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = itemAccessList;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ItemAccess/save`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Save the given list of itemAccess to database
   * @param itemAccessList undefined
   */
  ItemAccessSave(itemAccessList: Array<ItemAccessSimple>): __Observable<null> {
    return this.ItemAccessSaveResponse(itemAccessList).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Save the given list of itemAccess to database
   * @param itemAccessList undefined
   */
  ItemAccessSave2Response(itemAccessList: Array<ItemAccessSimple>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = itemAccessList;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ItemAccess`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Save the given list of itemAccess to database
   * @param itemAccessList undefined
   */
  ItemAccessSave2(itemAccessList: Array<ItemAccessSimple>): __Observable<null> {
    return this.ItemAccessSave2Response(itemAccessList).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get item access records
   * @param params The `ItemAccessService.ItemAccessPagedQueryParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ItemAccessPagedQueryResponse(params: ItemAccessService.ItemAccessPagedQueryParams): __Observable<__StrictHttpResponse<PagedResultOfItemAccessResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ItemAccess`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfItemAccessResponse>;
      })
    );
  }
  /**
   * Get item access records
   * @param params The `ItemAccessService.ItemAccessPagedQueryParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ItemAccessPagedQuery(params: ItemAccessService.ItemAccessPagedQueryParams): __Observable<PagedResultOfItemAccessResponse> {
    return this.ItemAccessPagedQueryResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfItemAccessResponse)
    );
  }

  /**
   * Get the user itemaccess records.
   * @param params The `ItemAccessService.ItemAccessPagedQueryByUserIdParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ItemAccessPagedQueryByUserIdResponse(params: ItemAccessService.ItemAccessPagedQueryByUserIdParams): __Observable<__StrictHttpResponse<PagedResultOfItemAccessResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ItemAccess/user/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfItemAccessResponse>;
      })
    );
  }
  /**
   * Get the user itemaccess records.
   * @param params The `ItemAccessService.ItemAccessPagedQueryByUserIdParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ItemAccessPagedQueryByUserId(params: ItemAccessService.ItemAccessPagedQueryByUserIdParams): __Observable<PagedResultOfItemAccessResponse> {
    return this.ItemAccessPagedQueryByUserIdResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfItemAccessResponse)
    );
  }

  /**
   * Get item access records
   * @param params The `ItemAccessService.ItemAccessPagedQueryByItemIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `itemId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ItemAccessPagedQueryByItemIdResponse(params: ItemAccessService.ItemAccessPagedQueryByItemIdParams): __Observable<__StrictHttpResponse<PagedResultOfItemAccessResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.itemId != null) __params = __params.set('itemId', params.itemId.toString());
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ItemAccess/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfItemAccessResponse>;
      })
    );
  }
  /**
   * Get item access records
   * @param params The `ItemAccessService.ItemAccessPagedQueryByItemIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `itemId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ItemAccessPagedQueryByItemId(params: ItemAccessService.ItemAccessPagedQueryByItemIdParams): __Observable<PagedResultOfItemAccessResponse> {
    return this.ItemAccessPagedQueryByItemIdResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfItemAccessResponse)
    );
  }

  /**
   * Used currently only for pirbadet app.
   * @param direction undefined
   */
  ItemAccessMyVisitsResponse(direction?: 0 | 1): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (direction != null) __params = __params.set('direction', direction.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ItemAccess/myvisits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * Used currently only for pirbadet app.
   * @param direction undefined
   */
  ItemAccessMyVisits(direction?: 0 | 1): __Observable<number> {
    return this.ItemAccessMyVisitsResponse(direction).pipe(
      __map(_r => _r.body as number)
    );
  }
}

module ItemAccessService {

  /**
   * Parameters for ItemAccessPagedQuery
   */
  export interface ItemAccessPagedQueryParams {
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for ItemAccessPagedQueryByUserId
   */
  export interface ItemAccessPagedQueryByUserIdParams {
    userId: null | number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for ItemAccessPagedQueryByItemId
   */
  export interface ItemAccessPagedQueryByItemIdParams {
    id: string;
    itemId?: number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }
}

export { ItemAccessService }
