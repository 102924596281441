import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  public title: string;
  public message: string;
  public htmlMessage: string;

  constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>) {}

  onCloseDialog(confirm: boolean) {
    this.dialogRef.close(confirm);
  }
}
