/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RentableItemGroupDto } from '../models/rentable-item-group-dto';
@Injectable({
  providedIn: 'root',
})
class RentableItemService extends __BaseService {
  static readonly RentableItemGetRentableItemGroupsPath = '/api/v2/RentableItem/location/{locationId}/{priceType}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RentableItemService.RentableItemGetRentableItemGroupsParams` containing the following parameters:
   *
   * - `priceType`:
   *
   * - `locationId`:
   */
  RentableItemGetRentableItemGroupsResponse(params: RentableItemService.RentableItemGetRentableItemGroupsParams): __Observable<__StrictHttpResponse<Array<RentableItemGroupDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/RentableItem/location/${encodeURIComponent(String(params.locationId))}/${encodeURIComponent(String(params.priceType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RentableItemGroupDto>>;
      })
    );
  }
  /**
   * @param params The `RentableItemService.RentableItemGetRentableItemGroupsParams` containing the following parameters:
   *
   * - `priceType`:
   *
   * - `locationId`:
   */
  RentableItemGetRentableItemGroups(params: RentableItemService.RentableItemGetRentableItemGroupsParams): __Observable<Array<RentableItemGroupDto>> {
    return this.RentableItemGetRentableItemGroupsResponse(params).pipe(
      __map(_r => _r.body as Array<RentableItemGroupDto>)
    );
  }
}

module RentableItemService {

  /**
   * Parameters for RentableItemGetRentableItemGroups
   */
  export interface RentableItemGetRentableItemGroupsParams {
    priceType: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
    locationId: number;
  }
}

export { RentableItemService }
