import { Component, ViewChild } from '@angular/core';
import { AlertService } from 'services/alert.service';
import { Router } from '@angular/router';
import { BasePagedComponent } from '../../shared/base-paged.component';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BookingService } from 'api/services';
import { DialogService } from 'shared/dialog.service';
import * as moment from 'moment';
import { TokenData } from 'models/login.model';
import { AuthService } from 'services/auth.service';
import { GlobalConfigService } from 'services/global-config.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';


@Component({
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.css']
})
export class MybookingsComponent extends BasePagedComponent {
  showLoadingIcon = false;
  tableData: any = {};
  items: any[] = [];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  expanded = false;
  userTokenData: TokenData;
  childrenBirthday = null;
  cancelOrder: string;
  youWillCancelOrder: string;

  constructor(
    alertService: AlertService,
    router: Router,
    public translateService: TranslateService,
    private bookingService: BookingService,
    private authService: AuthService,
    private dialogService: DialogService,
    private globalConfig: GlobalConfigService,
    private httpClient: HttpClient
  ) {
    super(alertService, router, translateService);
    this.userTokenData = this.authService.getTokenData();
    this.translateService.get('cancel_order').subscribe(x =>
      this.cancelOrder = x);
    this.translateService.get('you_will_cancel_order').subscribe(x =>
      this.youWillCancelOrder = x);
  }

  loadData() {
    this.childrenBirthday = this.globalConfig.childrenBirtday ? Number(this.globalConfig.childrenBirtday) : null;
    this.showLoadingIcon = true;
    this.bookingService.BookingGetMyActivceBookings(Number(this.userTokenData.sub)).subscribe(
      data => {
        this.tableData['currentPage'] = 1;
        this.tableData['totalItems'] = data.length;
        this.tableData['totalPages'] = 1;
        const orderlines = [];
        if (data) {
          data.forEach(ol => {
            const startDate = moment(ol.valid.from).add(2, 'hours').toLocaleString();
            const endDate = moment(ol.valid.to).add(2, 'hours').toLocaleString();
            orderlines.push({
              id: ol.id,
              orderId: ol.orderId,
              itemName: ol.itemName,
              itemId: ol.itemId,
              canceledAt: ol.canceledAt,
              from: new Date(startDate),
              to: new Date(endDate)
            });
          });
        }
        this.items = orderlines.sort((a, b) => (a.from > b.from) ? 1 : -1);
        this.showLoadingIcon = false;
      },
      err => {
        this.showLoadingIcon = false;
        this.defaultErrorHandler(err);
      }
    );
  }

  cancelBooking(item) {

    this.dialogService.confirm(this.cancelOrder, null, this.youWillCancelOrder).subscribe(result => {
      if (result) {
        this.bookingService.BookingCancelMyBooking(item.id).subscribe(result => {
          this.alertService.success(
            'Bookingen er kansellert',
            3000
          );
          if (item && item.itemId === this.childrenBirthday) {
            this.httpClient.post('/api/Order/birthdayPirbadet', item).subscribe(res => {
            });
          }
          this.loadData();
        }, err => {
          this.defaultErrorHandler(err);
        });
      }
    });
  }

  convertDate(date, zone?) {
    if (zone === 'local') {
      return new Date(moment(date).utc(false).format('YYYY-MM-DDTHH:mm:ss.SSS'));
    } else {
      date = moment(date).local(false).format();
      return moment(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }
  }

}
