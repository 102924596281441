import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class CustomPaymentStripeService {
  constructor(private http: HttpClient) {}

  byUserIdSourcesPost(
    params: any
  ): Observable<any> {
    const { userId, ...bodyParams } = params;

    return this.http.post<any>(
      `/api/PaymentStripe/${userId}/sources`,
      '"' + bodyParams.sourceOrtoken + '"',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  }
}
