/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LoginResult } from '../models/login-result';
import { LoginModel } from '../models/login-model';
@Injectable({
  providedIn: 'root',
})
class TokenService extends __BaseService {
  static readonly TokenCreateTokenPath = '/api/Token';
  static readonly TokenRefreshPath = '/api/Token/refreshToken';
  static readonly TokenVerifyTokenPath = '/api/Token/verify';
  static readonly TokenLoginWithMobilePath = '/api/Token/loginWithMobile';
  static readonly TokenLoginWithMobileAndCodePath = '/api/Token/verifyWithMobileAndCode';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Authorize user and create JWT
   * @param login undefined
   */
  TokenCreateTokenResponse(login: LoginModel): __Observable<__StrictHttpResponse<LoginResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = login;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoginResult>;
      })
    );
  }
  /**
   * Authorize user and create JWT
   * @param login undefined
   */
  TokenCreateToken(login: LoginModel): __Observable<LoginResult> {
    return this.TokenCreateTokenResponse(login).pipe(
      __map(_r => _r.body as LoginResult)
    );
  }

  /**
   * Creates a new token for user when the current one expires - refresh token
   * @param refreshToken undefined
   */
  TokenRefreshResponse(refreshToken?: null | string): __Observable<__StrictHttpResponse<LoginResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (refreshToken != null) __params = __params.set('refreshToken', refreshToken.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Token/refreshToken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoginResult>;
      })
    );
  }
  /**
   * Creates a new token for user when the current one expires - refresh token
   * @param refreshToken undefined
   */
  TokenRefresh(refreshToken?: null | string): __Observable<LoginResult> {
    return this.TokenRefreshResponse(refreshToken).pipe(
      __map(_r => _r.body as LoginResult)
    );
  }

  /**
   * Verify JWT
   */
  TokenVerifyTokenResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Token/verify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Verify JWT
   */
  TokenVerifyToken(): __Observable<boolean> {
    return this.TokenVerifyTokenResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Should be a 6 digit code.
   * @param phoneNumber undefined
   */
  TokenLoginWithMobileResponse(phoneNumber?: null | string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (phoneNumber != null) __params = __params.set('phoneNumber', phoneNumber.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Token/loginWithMobile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Should be a 6 digit code.
   * @param phoneNumber undefined
   */
  TokenLoginWithMobile(phoneNumber?: null | string): __Observable<null> {
    return this.TokenLoginWithMobileResponse(phoneNumber).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TokenService.TokenLoginWithMobileAndCodeParams` containing the following parameters:
   *
   * - `smsCode`:
   *
   * - `phoneNumber`:
   */
  TokenLoginWithMobileAndCodeResponse(params: TokenService.TokenLoginWithMobileAndCodeParams): __Observable<__StrictHttpResponse<LoginResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.smsCode != null) __params = __params.set('smsCode', params.smsCode.toString());
    if (params.phoneNumber != null) __params = __params.set('phoneNumber', params.phoneNumber.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Token/verifyWithMobileAndCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoginResult>;
      })
    );
  }
  /**
   * @param params The `TokenService.TokenLoginWithMobileAndCodeParams` containing the following parameters:
   *
   * - `smsCode`:
   *
   * - `phoneNumber`:
   */
  TokenLoginWithMobileAndCode(params: TokenService.TokenLoginWithMobileAndCodeParams): __Observable<LoginResult> {
    return this.TokenLoginWithMobileAndCodeResponse(params).pipe(
      __map(_r => _r.body as LoginResult)
    );
  }
}

module TokenService {

  /**
   * Parameters for TokenLoginWithMobileAndCode
   */
  export interface TokenLoginWithMobileAndCodeParams {
    smsCode?: null | string;
    phoneNumber?: null | string;
  }
}

export { TokenService }
