<mat-dialog-content>
        <div style="height: 100%;">


            <div class="buy-grid">
                <form style="width: 50%;" [formGroup]="paymentForm">
                    <label >{{'payment_option' | translate}}</label>

                    <mat-radio-group class="radio-group" formControlName="paymentOption" *ngIf="paymentOptions.length">
                        <mat-radio-button class="radio-group-button" *ngFor="let paymentOption of paymentOptions" [value]="paymentOption.id">
                            {{ paymentOption.name }}
                            <span class="payment-option-date" *ngIf="paymentOption.createdOn"><ng-container >{{'added_on' | translate}}</ng-container> {{ paymentOption.createdOn | appDate: 'monthFirst' }}</span>
                        </mat-radio-button>
                    </mat-radio-group>

                    <div *ngIf="!paymentOptions.length">
                        <p class="no-payments-message" >{{'no_payment_options'| translate}}</p>
                        <a style="background-color: #38A8DF;" mat-raised-button color="accent" [routerLink]="['/profile']" [queryParams]="{'tab': 'payment-options'}" (click)="closeDialog()">
                            <mat-icon class="push-right-sm">add_circle_outline</mat-icon>
                            <ng-container  >{{'add_payment' | translate}}</ng-container>
                        </a>
                    </div>
                </form>

                <div class="buy-items">

                    <div class="mb-2" style="display: flex;" *ngFor="let item of items">
                        <div style="display: flex;width: 50%;"> {{ getName(item.item)}} </div>
                        <div style="display: flex;
                        width: 50%;
                        place-content: end;"> <span [ngClass]="{'discount-strike': checkDiscount(item.item) }"> kr {{ item.item.prices[0]? item.item.prices[0].value : 0 }} </span>
                            <span style="margin-left: 10px;" *ngIf="checkDiscount(item.item)"> kr {{ getPriceDiscount(item.item) }}  </span> &nbsp; x {{ item.number }}</div>
                    </div>

                </div>
            </div>



            <div style="place-content: end; place-items: end;" class="step-actions mt-3" layout="column">
                <div class="sum">
                    <span> SUM: KR {{ cartSum }} </span>
                </div>
                <div class="mt-3">
                    <!-- <b class="push-right">{{ (item.prices[0] ? item.prices[0].value : 0) | appCurrency }}</b> -->

                    <button style="background-color: #7b2cb5;" mat-raised-button color="primary" (click)="closeDialog()"  >{{'cancel' | translate}}</button>

                    <button class="ml-4" style="background-color: #c23277;" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!paymentForm.value.paymentOption" >{{'pay' | translate}}</button>


                </div>
            </div>
        </div>
</mat-dialog-content>
