

    <mat-card [ngClass]="{'profile-mat-card-height': selectedTab===2}" class="profile-mat-card">
        <mat-card-header>
            <mat-card-title >{{'edit_profile' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="content-wrapper">
        <mat-tab-group (selectedTabChange)='onSelectTabChange($event)' class="profile-mat-group" [selectedIndex]="selectedTab">
            <mat-tab  label="{{'details' | translate}}" style="overflow: hidden;">
                <mat-card-content>
                    <form [formGroup]="saveForm">
                        <mat-card *ngIf="showLoadingIcon" class="progressSpinnerWrapper">
                            <mat-progress-spinner class="progressSpinner" [color]="'primary'" [mode]="'indeterminate'">
                            </mat-progress-spinner>
                        </mat-card>
                        <div class="row" layout-gt-xs="row" style="margin-top:15px;">
                            <mat-form-field flex appearance="outline">
                                <mat-label >{{'first_name' | translate}}</mat-label>
                                <input matInput formControlName="firstName">
                                <span class="required-input" matSuffix>*</span>
                                <mat-error *ngIf="saveForm.controls.firstName.hasError('required')" >{{'firstName_required' | translate}}</mat-error>
                            </mat-form-field>

                            <mat-form-field flex appearance="outline">
                                <mat-label >{{'middle_name' | translate}}</mat-label>
                                <input matInput formControlName="middleName">
                            </mat-form-field>

                            <mat-form-field flex appearance="outline">
                                <mat-label >{{'last_name' | translate}}</mat-label>
                                <input matInput formControlName="lastName">
                                <span class="required-input" matSuffix>*</span>
                                <mat-error *ngIf="saveForm.controls.lastName.hasError('required')" >{{'lastName_required' | translate}}</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="row" layout-gt-xs="row">
                            <mat-form-field flex appearance="outline">
                                <mat-label >{{'username' | translate}}</mat-label>
                                <input matInput formControlName="username">
                                <span class="required-input" matSuffix>*</span>
                                <mat-error *ngIf="saveForm.controls.username.hasError('required')" >{{'username_required' | translate}}</mat-error>
                            </mat-form-field>

                            <mat-form-field flex appearance="outline">
                                <mat-label >{{'email' | translate}}</mat-label>
                                <input matInput formControlName="email">
                                <span class="required-input" matSuffix>*</span>
                                <mat-error *ngIf="saveForm.controls.email.hasError('required')">{{'email_required' | translate}}</mat-error>
                                <mat-error *ngIf="saveForm.controls.email.hasError('pattern')" >{{'email_pattern' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row" layout-gt-xs="row">
                            <mat-form-field flex appearance="outline">
                                <mat-label >{{'cellphone' | translate}}</mat-label>
                                <input matInput formControlName="cellPhone">
                            </mat-form-field>
                            <mat-form-field flex appearance="outline">
                                <mat-label >{{'landphone'| translate}}</mat-label>
                                <input matInput formControlName="landPhone">
                            </mat-form-field>
                        </div>
                        <div class="row" layout-gt-xs="row">
                            <mat-form-field flex appearance="outline">
                                <mat-label >{{'gender' | translate}}</mat-label>
                                <mat-select placeholder="{{'gender' | translate}}"  formControlName="gender">
                                    <mat-option *ngFor="let gender of userGenders" [value]="gender">{{gender}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field flex appearance="outline">
                                <mat-label >{{'date_birth' | translate}}</mat-label>
                                <input matInput [matDatepicker]="picker" placeholder="{{'date_birth' | translate}}" formControlName="dateOfBirth" [max]="maxDateOfBirth">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="row" layout-gt-xs="row">
                            <mat-form-field flex appearance="outline">
                                <mat-label >{{'language' | translate}}</mat-label>

                                <mat-select placeholder="{{'language' | translate}}"  formControlName="languageId">
                                    <mat-option *ngFor="let lang of languages" [value]="lang.id">{{lang.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <h3 class="push-none push-bottom" >{{'change_password' | translate }}</h3>

                        <div class="row" layout-gt-xs="row">
                            <mat-form-field flex appearance="outline">
                                <mat-label >{{'new_password' | translate}}</mat-label>
                                <input matInput formControlName="newPassword" type="password">
                            </mat-form-field>
                            <mat-form-field flex appearance="outline">
                                <mat-label >{{'repeat_password' | translate}}</mat-label>
                                <input matInput formControlName="repeatedNewPassword" type="password">
                            </mat-form-field>
                        </div>

                        <button style="background-color:#c23277;" mat-raised-button color="primary" (click)="onSubmit()" >{{'save' | translate}}</button>
                    </form>
                </mat-card-content>
            </mat-tab>

            <mat-tab  label="{{'payment_options' | translate}}">
                <mat-card-content>
                    <mat-list>
                        <h3 mat-subheader >{{'added_cards' | translate }}</h3>
                        <mat-list-item *ngFor="let option of paymentOptions">
                            <div class="payment-option-wrapper">
                                <mat-icon>credit_card</mat-icon>

                                <div class="payment-option-label">
                                    <span>{{ option.name }}</span>
                                    <span class="small"><ng-container >{{'added_on' | translate}}</ng-container> {{ option.createdOn | appDate: 'monthFirst' }}</span>
                                </div>
                                
                                <button mat-icon-button [ngClass]="{'option-default': option.isDefault}" (click)="setDefaultPaymentOption(option)"><mat-icon>{{ option.isDefault ? 'star' : 'star_border' }}</mat-icon></button>
                                <button mat-icon-button (click)="deletePaymentOption(option)"><mat-icon>delete</mat-icon></button>
                            </div>
                        </mat-list-item>

                        <mat-divider></mat-divider>

                        <mat-list-item *ngIf="!paymentOptions.length">
                            <h4 mat-line >{{'no_payment_options' | translate}}</h4>
                        </mat-list-item>

                        <mat-divider></mat-divider>

                        <h3 mat-subheader >{{'add_new_payment_option' | translate}}</h3>
                        <mat-list-item>
                            <div class="add-card-grid" flex layout="column" layout-gt-xs="row">
                                <div class="stripe-card-wrapper">

                                    <stripe-card #stripeCard (catch)="onStripeError($event)" (tokenChange)="setStripeToken($event)" (sourceChange)="setStripeSource($event)"></stripe-card>

                                </div>

                                <button style="background-color:#c23277;" mat-raised-button color="primary" (click)="addCard()" >{{'add_card' | translate}}</button>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-tab>

            <mat-tab  label="{{'my_receipts' | translate}}">
                <mat-card class="receipts-mat-card">
                    <ngx-datatable class="material fullscreen flex-responsive only-scroll-horz no-box-shadow receipts-table" [columnMode]="'force'" [headerHeight]="'auto'" [footerHeight]="50" [rowHeight]="'auto'" [count]="tableData.totalItems" [rows]="items || []" [offset]="tableData.currentPage"
                        [limit]="currentPageSize" [externalPaging]="true" [externalSorting]="true" (sort)="onSort($event)" [scrollbarH]="true" (activate)="toggleExpandRow($event)" [messages]="{emptyMessage: 'empty_order_results_message' | translate, totalMessage: 'total' | translate}">
                        <!-- Row Detail Template -->
                        <ngx-datatable-row-detail [rowHeight]="'auto'" #detailRow>
                            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                                <ngx-datatable [columnMode]="'force'" class="material fullscreen no-box-shadow flex-responsive only-scroll-horz" [headerHeight]="50" [footerHeight]="'auto'" [rowHeight]="'auto'" [rows]="row.orderLines || []" [scrollbarH]="true" [messages]="{emptyMessage: 'no_data_to_display' | translate, totalMessage: 'total' | translate}">
                                    <ngx-datatable-column name="{{'name' | translate}}"  prop="itemName">
                                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                            <a class="edit-item-link">{{ getName(row)}}</a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="{{'category' | translate}}" >
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row.itemCategory !== null ? row.itemCategory : 'None'}}
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="{{'used_from_to' | translate }}"  prop="used" [width]="200">
                                        <ng-template let-value="value" ngx-datatable-cell-template>
                                            <span> {{ ((value.from) ? (value.from | appDate) : '--')  + ' - ' + ((value.to) ? (value.to | appDate) : '--')  }} </span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="{{'price_c' | translate}}" prop="price.value">
                                        <ng-template let-price="value" let-row="row" ngx-datatable-cell-template>
                                            <span>{{ price | appCurrency }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="{{'quantity' | translate}}"  prop="itemCount">
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="{{'total' | translate}}"  prop="price.value">
                                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                            <span>{{ (value * row.itemCount) | appCurrency }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>

                                </ngx-datatable>
                            </ng-template>
                        </ngx-datatable-row-detail>

                        <!-- Column Templates arrow for expanding and header template for arrow collapse/expand all-->
                        <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
                            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                                <a href="javascript:void(0)" class="expand-arrow" [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="{{'expand_collapse' | translate}}" ></a>
                            </ng-template>
                            <ng-template ngx-datatable-header-template>
                                <a href="javascript:void(0)" class="expand-arrow" [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="{{'expand_collapse' | translate}}"  (click)="toggleExpandAllRows()"></a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="ID" prop="id" minWidth="100" [canAutoResize]="false"></ngx-datatable-column>
                        <ngx-datatable-column name="{{'customer' | translate}}"  prop="deliveryDetails">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                <a style="place-content: center;
                                display: flex;
                                align-items: center;" class="edit-item-link">
                                    <mat-icon>account_circle</mat-icon>
                                    {{ value.name }}
                                </a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{'order_date' | translate}}" prop="placedAt">
                            <ng-template let-value="value" ngx-datatable-cell-template><span>{{ value | appDate }}</span></ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{'status' | translate}}"  prop="status">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                {{ getOrderStatus(value) | translate }}
                                <div *ngIf="recurringCancelled[row.id]">
                                    <mat-icon color="warn" title="Number of cancelled recurring">cancel</mat-icon> {{ recurringCancelled[row.id] + ' cancelled' }}
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{'payment_status' | translate}}"  prop="paymentStatus">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                {{ getPaymentStatus(value) |  translate}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="" cellClass="action-column" headerClass="action-column-header" [width]="60">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <button *ngIf="row.paymentStatus === 1" mat-icon-button color="accent" (click)="downloadReceipt(row)" title="{{'download_receipt' | translate}}" >
                                      <mat-icon>save_alt</mat-icon>
                                    </button>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-footer [template]="commonfooter.tmplDir.template"></ngx-datatable-footer>
                        <datatable-commonfooter #commonfooter [page]="currentPage" [pageSize]="currentPageSize" [totalPages]="tableData.totalPages" [rowCount]="tableData.totalItems" (change)="pagingChanged($event)"></datatable-commonfooter>
                    </ngx-datatable>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>