/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User } from '../models/user';
import { PagedResultOfUser } from '../models/paged-result-of-user';
import { ResetPasswordRequestOfGuid } from '../models/reset-password-request-of-guid';
import { ResetPasswordRequestOfString } from '../models/reset-password-request-of-string';
import { PagedResultOfCompanyNameAndId } from '../models/paged-result-of-company-name-and-id';
import { UserRole } from '../models/user-role';
import { Role } from '../models/role';
import { StringWrapper } from '../models/string-wrapper';
import { IssuedToken } from '../models/issued-token';
import { MessageBody } from '../models/message-body';
import { PagedResultOfItemAccessResponse } from '../models/paged-result-of-item-access-response';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly UserCreatePath = '/api/User';
  static readonly UserUpdatePath = '/api/User';
  static readonly UserGetPath = '/api/User/byEmail/{email}';
  static readonly UserGet2Path = '/api/User/{id}';
  static readonly UserDeletePath = '/api/User/{id}';
  static readonly UserGet3Path = '/api/User/Get';
  static readonly UserRecoverPasswordPath = '/api/User/recoverpassword/{username}';
  static readonly UserResetPasswordPath = '/api/User/resetpassword';
  static readonly UserConfirmPasswordPath = '/api/User/confirmpassword/{password}';
  static readonly UserRecoverPasswordByEmailOrMobilePath = '/api/User/recoverpasswordbyemailormobile/{emailOrMobile}';
  static readonly UserSetPasswordPath = '/api/User/setpassword';
  static readonly UserResetPasswordByCodePath = '/api/User/resetpasswordbycode';
  static readonly UserGetAdministrativeUsersPath = '/api/User/administrative/users';
  static readonly UserSetNfcUidPath = '/api/User/setnfcuid/{userId}';
  static readonly UserActivateAccountPath = '/api/User/activateaccount';
  static readonly UserResendActivationCodePath = '/api/User/resendactivationcode';
  static readonly UserGetCompaniesUserCanActOnBehalfOfPath = '/api/User/mycompanies';
  static readonly UserAssignRoleToUserPath = '/api/User/roles';
  static readonly UserGetAllRolesPath = '/api/User/roles';
  static readonly UserBlacklistTokenPath = '/api/User/{userId}/tokens/blacklist';
  static readonly UserTokensPath = '/api/User/{userId}/tokens';
  static readonly UserSendFeedbackPath = '/api/User/feedback';
  static readonly UserImportUsersPath = '/api/User/importusers';
  static readonly UserSendMessagePath = '/api/User/sendmessage';
  static readonly UserValidateAndGetNumberOfRecipientsPath = '/api/User/validaterecipients/{type}';
  static readonly UserItemAccessPath = '/api/User/{id}/access';
  static readonly UserDeleteMyDataPath = '/api/User/deletemydata';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create user
   * @param params The `UserService.UserCreateParams` containing the following parameters:
   *
   * - `user`:
   *
   * - `activationType`:
   */
  UserCreateResponse(params: UserService.UserCreateParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.user;
    if (params.activationType != null) __params = __params.set('activationType', params.activationType.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Create user
   * @param params The `UserService.UserCreateParams` containing the following parameters:
   *
   * - `user`:
   *
   * - `activationType`:
   */
  UserCreate(params: UserService.UserCreateParams): __Observable<User> {
    return this.UserCreateResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * Update user
   * @param user undefined
   */
  UserUpdateResponse(user: User): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Update user
   * @param user undefined
   */
  UserUpdate(user: User): __Observable<User> {
    return this.UserUpdateResponse(user).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param email undefined
   */
  UserGetResponse(email: null | string): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/byEmail/${encodeURIComponent(String(email))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param email undefined
   */
  UserGet(email: null | string): __Observable<User> {
    return this.UserGetResponse(email).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * Get user data
   * @param id undefined
   */
  UserGet2Response(id: number): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Get user data
   * @param id undefined
   */
  UserGet2(id: number): __Observable<User> {
    return this.UserGet2Response(id).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * Delete user by given userId
   * @param id undefined
   */
  UserDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/User/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete user by given userId
   * @param id undefined
   */
  UserDelete(id: number): __Observable<null> {
    return this.UserDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get users
   * @param params The `UserService.UserGet3Params` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  UserGet3Response(params: UserService.UserGet3Params): __Observable<__StrictHttpResponse<PagedResultOfUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/Get`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfUser>;
      })
    );
  }
  /**
   * Get users
   * @param params The `UserService.UserGet3Params` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  UserGet3(params: UserService.UserGet3Params): __Observable<PagedResultOfUser> {
    return this.UserGet3Response(params).pipe(
      __map(_r => _r.body as PagedResultOfUser)
    );
  }

  /**
   * Recover password on given username
   * @param username undefined
   */
  UserRecoverPasswordResponse(username: null | string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/recoverpassword/${encodeURIComponent(String(username))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Recover password on given username
   * @param username undefined
   */
  UserRecoverPassword(username: null | string): __Observable<null> {
    return this.UserRecoverPasswordResponse(username).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Reset password for the given user
   * @param resetPasswordRequest undefined
   */
  UserResetPasswordResponse(resetPasswordRequest: ResetPasswordRequestOfGuid): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = resetPasswordRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/resetpassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Reset password for the given user
   * @param resetPasswordRequest undefined
   */
  UserResetPassword(resetPasswordRequest: ResetPasswordRequestOfGuid): __Observable<null> {
    return this.UserResetPasswordResponse(resetPasswordRequest).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Confirm password for user
   * @param password undefined
   */
  UserConfirmPasswordResponse(password: null | string): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/confirmpassword/${encodeURIComponent(String(password))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Confirm password for user
   * @param password undefined
   */
  UserConfirmPassword(password: null | string): __Observable<User> {
    return this.UserConfirmPasswordResponse(password).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * Recover password by email or mobile for the given user
   * @param emailOrMobile undefined
   */
  UserRecoverPasswordByEmailOrMobileResponse(emailOrMobile: null | string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/recoverpasswordbyemailormobile/${encodeURIComponent(String(emailOrMobile))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Recover password by email or mobile for the given user
   * @param emailOrMobile undefined
   */
  UserRecoverPasswordByEmailOrMobile(emailOrMobile: null | string): __Observable<null> {
    return this.UserRecoverPasswordByEmailOrMobileResponse(emailOrMobile).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Changes password
   * @param newPassword undefined
   */
  UserSetPasswordResponse(newPassword?: null | string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (newPassword != null) __params = __params.set('newPassword', newPassword.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/setpassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Changes password
   * @param newPassword undefined
   */
  UserSetPassword(newPassword?: null | string): __Observable<null> {
    return this.UserSetPasswordResponse(newPassword).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Reset password by email or mobile for the given user
   * @param resetPasswordRequest undefined
   */
  UserResetPasswordByCodeResponse(resetPasswordRequest: ResetPasswordRequestOfString): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = resetPasswordRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/resetpasswordbycode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Reset password by email or mobile for the given user
   * @param resetPasswordRequest undefined
   */
  UserResetPasswordByCode(resetPasswordRequest: ResetPasswordRequestOfString): __Observable<null> {
    return this.UserResetPasswordByCodeResponse(resetPasswordRequest).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get administrative users for dropdowns or autocompletes
   */
  UserGetAdministrativeUsersResponse(): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/administrative/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * Get administrative users for dropdowns or autocompletes
   */
  UserGetAdministrativeUsers(): __Observable<Array<User>> {
    return this.UserGetAdministrativeUsersResponse().pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param params The `UserService.UserSetNfcUidParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `nfcuid`:
   */
  UserSetNfcUidResponse(params: UserService.UserSetNfcUidParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.nfcuid;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/setnfcuid/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.UserSetNfcUidParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `nfcuid`:
   */
  UserSetNfcUid(params: UserService.UserSetNfcUidParams): __Observable<null> {
    return this.UserSetNfcUidResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Reset password for the given user
   * @param params The `UserService.UserActivateAccountParams` containing the following parameters:
   *
   * - `emailOrMobile`:
   *
   * - `activationCode`:
   */
  UserActivateAccountResponse(params: UserService.UserActivateAccountParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.emailOrMobile != null) __params = __params.set('emailOrMobile', params.emailOrMobile.toString());
    if (params.activationCode != null) __params = __params.set('activationCode', params.activationCode.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/activateaccount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Reset password for the given user
   * @param params The `UserService.UserActivateAccountParams` containing the following parameters:
   *
   * - `emailOrMobile`:
   *
   * - `activationCode`:
   */
  UserActivateAccount(params: UserService.UserActivateAccountParams): __Observable<null> {
    return this.UserActivateAccountResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Reset password for the given user
   * @param emailOrMobile undefined
   */
  UserResendActivationCodeResponse(emailOrMobile?: null | string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (emailOrMobile != null) __params = __params.set('emailOrMobile', emailOrMobile.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/resendactivationcode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Reset password for the given user
   * @param emailOrMobile undefined
   */
  UserResendActivationCode(emailOrMobile?: null | string): __Observable<null> {
    return this.UserResendActivationCodeResponse(emailOrMobile).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get companies for given user
   * @param params The `UserService.UserGetCompaniesUserCanActOnBehalfOfParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  UserGetCompaniesUserCanActOnBehalfOfResponse(params: UserService.UserGetCompaniesUserCanActOnBehalfOfParams): __Observable<__StrictHttpResponse<PagedResultOfCompanyNameAndId>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/mycompanies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfCompanyNameAndId>;
      })
    );
  }
  /**
   * Get companies for given user
   * @param params The `UserService.UserGetCompaniesUserCanActOnBehalfOfParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  UserGetCompaniesUserCanActOnBehalfOf(params: UserService.UserGetCompaniesUserCanActOnBehalfOfParams): __Observable<PagedResultOfCompanyNameAndId> {
    return this.UserGetCompaniesUserCanActOnBehalfOfResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfCompanyNameAndId)
    );
  }

  /**
   * Assign role to user
   * @param requested undefined
   */
  UserAssignRoleToUserResponse(requested: UserRole): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = requested;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Assign role to user
   * @param requested undefined
   */
  UserAssignRoleToUser(requested: UserRole): __Observable<null> {
    return this.UserAssignRoleToUserResponse(requested).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get all roles for given user
   */
  UserGetAllRolesResponse(): __Observable<__StrictHttpResponse<Array<Role>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Role>>;
      })
    );
  }
  /**
   * Get all roles for given user
   */
  UserGetAllRoles(): __Observable<Array<Role>> {
    return this.UserGetAllRolesResponse().pipe(
      __map(_r => _r.body as Array<Role>)
    );
  }

  /**
   * Add token to blacklist
   * @param params The `UserService.UserBlacklistTokenParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tokenId`:
   */
  UserBlacklistTokenResponse(params: UserService.UserBlacklistTokenParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.tokenId;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.userId))}/tokens/blacklist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Add token to blacklist
   * @param params The `UserService.UserBlacklistTokenParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tokenId`:
   */
  UserBlacklistToken(params: UserService.UserBlacklistTokenParams): __Observable<null> {
    return this.UserBlacklistTokenResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get tokens for given userId
   * @param userId undefined
   */
  UserTokensResponse(userId: number): __Observable<__StrictHttpResponse<Array<IssuedToken>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/${encodeURIComponent(String(userId))}/tokens`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuedToken>>;
      })
    );
  }
  /**
   * Get tokens for given userId
   * @param userId undefined
   */
  UserTokens(userId: number): __Observable<Array<IssuedToken>> {
    return this.UserTokensResponse(userId).pipe(
      __map(_r => _r.body as Array<IssuedToken>)
    );
  }

  /**
   * Send feedback
   * @param message undefined
   */
  UserSendFeedbackResponse(message: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = message;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/feedback`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Send feedback
   * @param message undefined
   */
  UserSendFeedback(message: string): __Observable<null> {
    return this.UserSendFeedbackResponse(message).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Import users from CSV file and optionally assign them user group
   * @param params The `UserService.UserImportUsersParams` containing the following parameters:
   *
   * - `UserGroupId`:
   *
   * - `Save`:
   *
   * - `Name`:
   *
   * - `Length`:
   *
   * - `Headers`:
   *
   * - `FileName`:
   *
   * - `ContentType`:
   *
   * - `ContentDisposition`:
   */
  UserImportUsersResponse(params: UserService.UserImportUsersParams): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.UserGroupId != null) { __formData.append('UserGroupId', JSON.stringify(params.UserGroupId));}
    if (params.Save != null) { __formData.append('Save', JSON.stringify(params.Save));}
    if (params.Name != null) { __formData.append('Name', params.Name as string | Blob);}
    if (params.Length != null) { __formData.append('Length', JSON.stringify(params.Length));}
    if (params.Headers != null) { __formData.append('Headers', JSON.stringify(params.Headers));}
    if (params.FileName != null) { __formData.append('FileName', params.FileName as string | Blob);}
    if (params.ContentType != null) { __formData.append('ContentType', params.ContentType as string | Blob);}
    if (params.ContentDisposition != null) { __formData.append('ContentDisposition', params.ContentDisposition as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/importusers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * Import users from CSV file and optionally assign them user group
   * @param params The `UserService.UserImportUsersParams` containing the following parameters:
   *
   * - `UserGroupId`:
   *
   * - `Save`:
   *
   * - `Name`:
   *
   * - `Length`:
   *
   * - `Headers`:
   *
   * - `FileName`:
   *
   * - `ContentType`:
   *
   * - `ContentDisposition`:
   */
  UserImportUsers(params: UserService.UserImportUsersParams): __Observable<Array<User>> {
    return this.UserImportUsersResponse(params).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * Send an email or SMS to all or filtered users
   * @param message undefined
   */
  UserSendMessageResponse(message: MessageBody): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = message;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/sendmessage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Send an email or SMS to all or filtered users
   * @param message undefined
   */
  UserSendMessage(message: MessageBody): __Observable<null> {
    return this.UserSendMessageResponse(message).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get number of valid recipients
   * @param params The `UserService.UserValidateAndGetNumberOfRecipientsParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `userIds`:
   *
   * - `filter`:
   */
  UserValidateAndGetNumberOfRecipientsResponse(params: UserService.UserValidateAndGetNumberOfRecipientsParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.userIds != null) __params = __params.set('userIds', params.userIds.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/validaterecipients/${encodeURIComponent(String(params.type))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * Get number of valid recipients
   * @param params The `UserService.UserValidateAndGetNumberOfRecipientsParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `userIds`:
   *
   * - `filter`:
   */
  UserValidateAndGetNumberOfRecipients(params: UserService.UserValidateAndGetNumberOfRecipientsParams): __Observable<number> {
    return this.UserValidateAndGetNumberOfRecipientsResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * Get item access records
   * @param params The `UserService.UserItemAccessParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  UserItemAccessResponse(params: UserService.UserItemAccessParams): __Observable<__StrictHttpResponse<PagedResultOfItemAccessResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.id))}/access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfItemAccessResponse>;
      })
    );
  }
  /**
   * Get item access records
   * @param params The `UserService.UserItemAccessParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  UserItemAccess(params: UserService.UserItemAccessParams): __Observable<PagedResultOfItemAccessResponse> {
    return this.UserItemAccessResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfItemAccessResponse)
    );
  }
  UserDeleteMyDataResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/deletemydata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  UserDeleteMyData(): __Observable<null> {
    return this.UserDeleteMyDataResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserService {

  /**
   * Parameters for UserCreate
   */
  export interface UserCreateParams {
    user: User;
    activationType?: null | 0 | 1 | 2;
  }

  /**
   * Parameters for UserGet3
   */
  export interface UserGet3Params {
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for UserSetNfcUid
   */
  export interface UserSetNfcUidParams {
    userId: number;
    nfcuid: string;
  }

  /**
   * Parameters for UserActivateAccount
   */
  export interface UserActivateAccountParams {
    emailOrMobile?: null | string;
    activationCode?: null | string;
  }

  /**
   * Parameters for UserGetCompaniesUserCanActOnBehalfOf
   */
  export interface UserGetCompaniesUserCanActOnBehalfOfParams {
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for UserBlacklistToken
   */
  export interface UserBlacklistTokenParams {
    userId: number;
    tokenId: StringWrapper;
  }

  /**
   * Parameters for UserImportUsers
   */
  export interface UserImportUsersParams {
    UserGroupId?: number;
    Save?: boolean;
    Name?: null | string;
    Length?: number;
    Headers?: null | {};
    FileName?: null | string;
    ContentType?: null | string;
    ContentDisposition?: null | string;
  }

  /**
   * Parameters for UserValidateAndGetNumberOfRecipients
   */
  export interface UserValidateAndGetNumberOfRecipientsParams {
    type: null | string;
    userIds?: null | string;
    filter?: null | string;
  }

  /**
   * Parameters for UserItemAccess
   */
  export interface UserItemAccessParams {
    id: number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }
}

export { UserService }
