/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedResultOfThirdPartyProvider } from '../models/paged-result-of-third-party-provider';
import { ThirdPartyProvider } from '../models/third-party-provider';
@Injectable({
  providedIn: 'root',
})
class ThirdPartyProviderService extends __BaseService {
  static readonly ThirdPartyProviderGetPath = '/api/ThirdPartyProvider';
  static readonly ThirdPartyProviderCreatePath = '/api/ThirdPartyProvider';
  static readonly ThirdPartyProviderUpdatePath = '/api/ThirdPartyProvider';
  static readonly ThirdPartyProviderGet2Path = '/api/ThirdPartyProvider/{id}';
  static readonly ThirdPartyProviderDeletePath = '/api/ThirdPartyProvider/{id}';
  static readonly ThirdPartyProviderGetPaymentOptionProvidersPath = '/api/ThirdPartyProvider/payment';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get ThirdPartyProviders for given companyId
   * @param params The `ThirdPartyProviderService.ThirdPartyProviderGetParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ThirdPartyProviderGetResponse(params: ThirdPartyProviderService.ThirdPartyProviderGetParams): __Observable<__StrictHttpResponse<PagedResultOfThirdPartyProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ThirdPartyProvider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfThirdPartyProvider>;
      })
    );
  }
  /**
   * Get ThirdPartyProviders for given companyId
   * @param params The `ThirdPartyProviderService.ThirdPartyProviderGetParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ThirdPartyProviderGet(params: ThirdPartyProviderService.ThirdPartyProviderGetParams): __Observable<PagedResultOfThirdPartyProvider> {
    return this.ThirdPartyProviderGetResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfThirdPartyProvider)
    );
  }

  /**
   * Create ThirdPartyProvider for given companyId
   * @param thirdPartyProvider undefined
   */
  ThirdPartyProviderCreateResponse(thirdPartyProvider: ThirdPartyProvider): __Observable<__StrictHttpResponse<ThirdPartyProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = thirdPartyProvider;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ThirdPartyProvider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ThirdPartyProvider>;
      })
    );
  }
  /**
   * Create ThirdPartyProvider for given companyId
   * @param thirdPartyProvider undefined
   */
  ThirdPartyProviderCreate(thirdPartyProvider: ThirdPartyProvider): __Observable<ThirdPartyProvider> {
    return this.ThirdPartyProviderCreateResponse(thirdPartyProvider).pipe(
      __map(_r => _r.body as ThirdPartyProvider)
    );
  }

  /**
   * Update ThirdPartyProvider
   * @param location undefined
   */
  ThirdPartyProviderUpdateResponse(location: ThirdPartyProvider): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = location;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ThirdPartyProvider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update ThirdPartyProvider
   * @param location undefined
   */
  ThirdPartyProviderUpdate(location: ThirdPartyProvider): __Observable<null> {
    return this.ThirdPartyProviderUpdateResponse(location).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get ThirdPartyProvider for given id
   * @param id undefined
   */
  ThirdPartyProviderGet2Response(id: number): __Observable<__StrictHttpResponse<ThirdPartyProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ThirdPartyProvider/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ThirdPartyProvider>;
      })
    );
  }
  /**
   * Get ThirdPartyProvider for given id
   * @param id undefined
   */
  ThirdPartyProviderGet2(id: number): __Observable<ThirdPartyProvider> {
    return this.ThirdPartyProviderGet2Response(id).pipe(
      __map(_r => _r.body as ThirdPartyProvider)
    );
  }

  /**
   * Delete given ThirdPartyProvider
   * @param id undefined
   */
  ThirdPartyProviderDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ThirdPartyProvider/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete given ThirdPartyProvider
   * @param id undefined
   */
  ThirdPartyProviderDelete(id: number): __Observable<null> {
    return this.ThirdPartyProviderDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get payment ThirdPartyProviders for given companyId
   * @param params The `ThirdPartyProviderService.ThirdPartyProviderGetPaymentOptionProvidersParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ThirdPartyProviderGetPaymentOptionProvidersResponse(params: ThirdPartyProviderService.ThirdPartyProviderGetPaymentOptionProvidersParams): __Observable<__StrictHttpResponse<PagedResultOfThirdPartyProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ThirdPartyProvider/payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfThirdPartyProvider>;
      })
    );
  }
  /**
   * Get payment ThirdPartyProviders for given companyId
   * @param params The `ThirdPartyProviderService.ThirdPartyProviderGetPaymentOptionProvidersParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ThirdPartyProviderGetPaymentOptionProviders(params: ThirdPartyProviderService.ThirdPartyProviderGetPaymentOptionProvidersParams): __Observable<PagedResultOfThirdPartyProvider> {
    return this.ThirdPartyProviderGetPaymentOptionProvidersResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfThirdPartyProvider)
    );
  }
}

module ThirdPartyProviderService {

  /**
   * Parameters for ThirdPartyProviderGet
   */
  export interface ThirdPartyProviderGetParams {
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for ThirdPartyProviderGetPaymentOptionProviders
   */
  export interface ThirdPartyProviderGetPaymentOptionProvidersParams {
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }
}

export { ThirdPartyProviderService }
