/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PaymentStatusResponse } from '../models/payment-status-response';
import { PaymentResponse } from '../models/payment-response';
import { OnetimeCheckout } from '../models/onetime-checkout';
@Injectable({
  providedIn: 'root',
})
class PaymentEasyService extends __BaseService {
  static readonly PaymentEasyGetAndCompletePaymentPath = '/api/PaymentEasy/GetAndCompletePayment';
  static readonly PaymentEasyCreatePaymentRequestPath = '/api/PaymentEasy/createPaymentRequest';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get and complete easy payment
   * @param paymentId payment reference
   * @return status and order
   */
  PaymentEasyGetAndCompletePaymentResponse(paymentId?: null | string): __Observable<__StrictHttpResponse<PaymentStatusResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (paymentId != null) __params = __params.set('paymentId', paymentId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PaymentEasy/GetAndCompletePayment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentStatusResponse>;
      })
    );
  }
  /**
   * Get and complete easy payment
   * @param paymentId payment reference
   * @return status and order
   */
  PaymentEasyGetAndCompletePayment(paymentId?: null | string): __Observable<PaymentStatusResponse> {
    return this.PaymentEasyGetAndCompletePaymentResponse(paymentId).pipe(
      __map(_r => _r.body as PaymentStatusResponse)
    );
  }

  /**
   * Create payment request for onetime payments
   * @param onetimeCheckout Items and quantity to pay for
   * @return Stripe payment request
   */
  PaymentEasyCreatePaymentRequestResponse(onetimeCheckout: OnetimeCheckout): __Observable<__StrictHttpResponse<PaymentResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = onetimeCheckout;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PaymentEasy/createPaymentRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentResponse>;
      })
    );
  }
  /**
   * Create payment request for onetime payments
   * @param onetimeCheckout Items and quantity to pay for
   * @return Stripe payment request
   */
  PaymentEasyCreatePaymentRequest(onetimeCheckout: OnetimeCheckout): __Observable<PaymentResponse> {
    return this.PaymentEasyCreatePaymentRequestResponse(onetimeCheckout).pipe(
      __map(_r => _r.body as PaymentResponse)
    );
  }
}

module PaymentEasyService {
}

export { PaymentEasyService }
