import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseComponent } from 'components/shared/base.component';
import { AlertService } from 'services/alert.service';
import { Router } from '@angular/router';
import { AuthService } from 'services/auth.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';
import { TokenData } from 'models/login.model';
import { AppCurrencyPipe } from 'pipes/app-currency.pipe';
import { Item, Order, PaymentOption } from 'api/models';
import { OrderService, PaymentService, VippsService } from 'api/services';
import { GlobalConfigService } from 'services/global-config.service';
import { TranslateService } from '@ngx-translate/core';
import { Price } from '../../../../models/price.model';

@Component({
  selector: 'app-buy-tickets-dialog',
  templateUrl: './buy-tickets-dialog.component.html',
  styleUrls: ['./buy-tickets-dialog.component.css']
})
export class BuyTicketsDialogComponent extends BaseComponent implements OnInit {
  public items: Item[];
  cartSum = 0;

  paymentForm: FormGroup;
  userTokenData: TokenData;
  orderLines = [];
  booking = false;
  giftTicket = null;
  monthly = false;


  paymentOptions: PaymentOption[] = [];
  showLoadingIcon = false;
  confirmPaymentTranslate = '';
  newOneTranslate = '';

  constructor(
    alertService: AlertService,
    router: Router,
    public translateService: TranslateService,
    private config: GlobalConfigService,
    private dialogRef: MatDialogRef<BuyTicketsDialogComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private orderService: OrderService,
    private vippsService: VippsService,
    private dialog: MatDialog,
    private paymentService: PaymentService,
    private appCurency: AppCurrencyPipe
  ) {
    super(alertService, router, translateService);
    this.translateService.get('confirm_payment').subscribe(result => {
      this.confirmPaymentTranslate = result;
    });
    this.translateService.get('you_are_going_to_pay_new').subscribe(result => {
       this.newOneTranslate = result;
    });
  }

  ngOnInit() {
    this.showLoadingIcon = true;
    this.userTokenData = this.authService.getTokenData();

    this.paymentService.PaymentGetPaymentOptions({
        userId: Number(this.userTokenData.sub),
        PageSize: 100
      })
      .subscribe(response => {
        this.paymentOptions = response.results;


        if (!this.monthly) { // only Vipps payment for one-time tickets
          this.paymentOptions.push({
            createdOn: '',
            id: -500,
            updatedOn: '',
            expiredOn: null,
            isDefault: this.paymentOptions.length == 0 ? true : false,
            isNotified: false,
            name: 'Vipps',
            paymentProvider: null,
            paymentProviderId: 0,
            tokenOrReference: null,
            user: null,
            userId: 0,
            deletedOn: null,
            forceHardDelete: false
          });
        }


        if (this.paymentOptions.length) {
          this.paymentForm.patchValue({
            paymentOption: this.paymentOptions.find(x => x.isDefault)
              ? this.paymentOptions.find(x => x.isDefault).id
              : null
          });
        }

        this.showLoadingIcon = false;
      });




    this.paymentForm = this.formBuilder.group({
      paymentOption: Validators.required
    });
  }

  onCloseDialog(confirm: boolean) {
    this.dialogRef.close(confirm);
  }

  confirmPayment(): Observable<boolean> {

    let dialogRef: MatDialogRef<ConfirmDialogComponent>;

    const formattedPrice = this.appCurency.transform(
      this.cartSum
    );

    dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.title = this.confirmPaymentTranslate;
    dialogRef.componentInstance.htmlMessage = this.newOneTranslate + '<b> ' +  formattedPrice +'</b>.';


    return dialogRef.afterClosed();
  }

  onSubmit() {
    this.confirmPayment().subscribe(confirmed => {
      if (confirmed) {
        this.showLoadingIcon = true;

        this.buyItems();


      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
  buyItems() {

    const buyOrderLines = this.orderLines.filter(x => x.itemId != this.config.christmasGiftCardId);

    const wetPackage = this.orderLines.find(x => x.itemId == this.config.christmasGiftCardId);
    if (wetPackage) {

      for (let i = 0; i < wetPackage.itemCount; i++) {

        for (let step = 0; step < 5; step++) {
          buyOrderLines.push({
            itemId: wetPackage.itemId,
            itemCount: 1
          });
        }

      }
    }

    let orderParam: Order = {
      userId: Number(this.userTokenData.sub),
      orderLines: buyOrderLines,
      isRecurring: false,
      orderType: 0,
      paymentStatus: 0,
      paymentType: 0, // 0 = Card | 1 = Vipps
      placedAt: '',
      recurringOrder: false,
      status: 0,
      totalPrice: 0,
      totalPriceExVat: 0,
      totalVat: 0,
      createdOn: '',
      updatedOn: '',
      id: 0,
      visitorsNumber: 0,
      additionalData: null,
      deletedOn: null,
      forceHardDelete: false,
      bookingCalendar: null,
      comment: null,
      deletedReason: null,
      deliveryDetails: null,
      externalReference: null,
      invoiceDetails: null,
      leadTimeIn: null,
      leadTimeOut: null,
      localId: null,
      location: null,
      locationId: null,
      payments: null,
      recurring: null,
      recurringPeriods: null,
      user: null,
    };

    let params2 = {
      order: orderParam,
      completeAndCharge: true
   };


   const selectedOptionVaulue = this.paymentForm.get('paymentOption')?.value;

   if (selectedOptionVaulue == '-500') {

    // VIPPS LOGIC
    var itemIds = [];
    var itemPriceType = this.monthly ? 4 : 0;
    
    buyOrderLines.forEach(orderLine => {
      for (let i = 0; i < orderLine.itemCount; i++) {
        itemIds.push(orderLine.itemId);
      }
    });

    var fullUrl = window.location.origin + '/vipps';

    this.vippsService.VippsCreatePayment({
      description: 'Pirbadet billetter web',
      interactionType: 2,
      itemIds: itemIds, 
      priceType: itemPriceType as any,
      redirectUrl: fullUrl
    }).subscribe(
      response => {
        this.dialogRef.close();
        this.showLoadingIcon = false;

        localStorage.setItem('vipps-order-id', response.orderId.toString());

        // redirect to vipps
        var redirectUrl = response.redirectUrl;
        window.location.href = redirectUrl;
      },
      error => {
        this.defaultErrorHandler(error);
        this.showLoadingIcon = false;
      }
    );

    return;
   }

    this.orderService
      .OrderCreate(
        params2
      )
      .subscribe(
        () => {
          this.dialogRef.close();
          this.translateService.get('success_ordered').subscribe(message => {
           this.alertService.success(message, 3000);
          });

          this.showLoadingIcon = false;
          this.router.navigate(['/mytickets']);
        },
        error => {
          this.defaultErrorHandler(error);
          this.showLoadingIcon = false;
        }
      );
  }
  getName(item) {
    const currentLanguge = localStorage.getItem('language');
    if (currentLanguge) {
      switch (currentLanguge) {
        case 'en-GB': return item.name;
        case 'nb-NO': return (item.properties && item.properties['nb-NO_Name'] ) ? item.properties['nb-NO_Name'] : item.name ;
        default : return item.name;
      }

    } else {
      return item.properties['nb-NO_Name'] ? item.properties['nb-NO_Name'] : item.name;
    }

  }

  checkDiscount(item) {
    if (this.orderLines && this.orderLines.length > 0) {
      const itemCart = this.orderLines.find(x => x.itemId === item.id);
      if (itemCart && itemCart.discount ) {
        return true;
      }
    }

    return false;
 }
 getPriceDiscount(item) {
  return item.prices[0] ? item.prices[0].value - 15 : 0;

}
}
