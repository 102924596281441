



import { RouterModule } from '@angular/router';
import { SecureComponent, DeactivateGuard } from './app.secure';
import { SecureLayoutComponent } from './components/secure/layout/layout.component';
import { ProfileComponent } from './components/secure/profile/profile.component';
import { PaymentsComponent } from './components/secure/payments/payments.component';
import { SubscriptionsComponent } from './components/secure/subscriptions/subscriptions.component';
import { NotFoundComponent } from './components/secure/not-found/not-found.component';
import { PublicLayoutComponent } from './components/public/layout/layout.component';
import { LoginComponent } from './components/public/login/login.component';
import { RegisterComponent } from './components/public/register/register.component';
import { ActivateAccountComponent } from './components/public/activateaccount/activateaccount.component';
import { RecoverPasswordComponent } from './components/public/recoverpassword/recoverpassword.component';
import { ChangePasswordComponent } from './components/public/changepassword/changepassword.component';
import { GlobalConfigService } from './services/global-config.service';
import {
  registerLocaleData,
  CommonModule,
  DatePipe,
  CurrencyPipe
} from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule, MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConfirmDialogComponent } from './components/secure/dialogs/confirm-dialog/confirm-dialog.component';
import { AuthService } from './services/auth.service';
import { MenuService } from './services/menu.service';
import { DialogService } from './shared/dialog.service';
import { AlertService } from './services/alert.service';
import { LoaderInterceptor } from '../app/services/loader.interceptor';
import { LoaderModule } from '../app/shared/loader.module';
import { LoaderService } from './shared/loading-indicator/loader.service';


import { AppDatePipe } from './pipes/app-date.pipe';



import { ImportUserResultService } from './services/import-users-result.service';

import { ColorService } from './services/color.service';
import { TokenInterceptor } from './shared/token.interceptor';
import { ApiUrlInterceptor } from './shared/api-url.interceptor';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MomentUtcDateAdapter } from './components/shared/momentUtcDateAdapter.component';

import { KeysPipe } from './pipes/keys.pipe';

import { AppCurrencyPipe } from './pipes/app-currency.pipe';
import { DynamicSortPipe } from './pipes/dynamic-sort.pipe';
import { PlainTextPipe } from './pipes/plain-text.pipe';
import { RouteInterceptorService } from './services/route-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertComponent } from './components/public/alert/alert.component';
import { AlertMessageComponent } from './components/public/alert/alert-message.component';
import { DatatableCommonFooterComponent } from './components/shared/datatable-commonfooter.component';
import { AppComponent } from './components/app/app.component';
import { StripeModule } from 'stripe-angular';
import { CustomPaymentStripeService } from 'services/payment-stripe.service';
import { TicketsComponent } from 'components/secure/tickets/tickets.component';
import { BuyTicketsDialogComponent } from 'components/secure/dialogs/buy-tickets-dialog/buy-tickets-dialog.component';
import { MyticketsComponent } from 'components/secure/my-tickets/my-tickets.component';
import { MybookingsComponent } from 'components/secure/my-bookings/my-bookings.component';
import { ApplicationInsightsService, FeedbackService,
   HubService, ItemAccessService,
   ItemCategoryService, ItemService,
    MessageService, MessageTemplateService,
    OrderService, PageHelpService, PaymentService, ReportService, ThirdPartyProviderService, UserService } from 'api/services';
import { ApiModule } from 'api/api.module';
import { BookingComponent } from 'components/secure/booking/booking.component';
import { BookItemComponent } from 'components/secure/dialogs/book-item/book-item.component';
import { QrCodeDialogComponent } from 'components/secure/dialogs/qr-code/qr-code-dialog.component';
import { ConfirmMonthlyDialogComponent } from './components/secure/dialogs/confirm-monhtly/confirm-monthly-dialog.component';
import { ChildrenBirthdayComponent } from './components/secure/children-birthday/children-birthday.component';
import { BookChildrenBirthdayComponent } from './components/secure/dialogs/book-children-birthday/book-children-birthday.component';
import { BrowserModule } from '@angular/platform-browser';
import 'hammerjs';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import localeNo from '@angular/common/locales/no';
registerLocaleData(localeNo);





// we need this function since inlining it with lambda makes angular aot cry as of now
export function configFactory(config: GlobalConfigService) {
  return { apiKey: config.googleApiKey };
}

export const DATE_FORMATS = {
  parse: {
    dateInput: 'L'
  },
  display: {
    dateInput: 'L'
  }
};
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
// We should not need export here. but angular has bug. https://github.com/angular/angular/issues/21273
// Remove when we update angular


@NgModule({
    declarations: [
        AppComponent,
        SecureLayoutComponent,
        PublicLayoutComponent,
        PublicLayoutComponent,
        SecureLayoutComponent,
        LoginComponent,
        ProfileComponent,
        BookingComponent,
        ChildrenBirthdayComponent,
        RecoverPasswordComponent,
        ChangePasswordComponent,
        PaymentsComponent,
        NotFoundComponent,
        MyticketsComponent,
        MybookingsComponent,
        SubscriptionsComponent,
        TicketsComponent,
        RegisterComponent,
        ActivateAccountComponent,
        ConfirmDialogComponent,
        ConfirmMonthlyDialogComponent,
        QrCodeDialogComponent,
        AlertComponent,
        AlertMessageComponent,
        DatatableCommonFooterComponent,
        AppDatePipe,
        AppCurrencyPipe,
        KeysPipe,
        DynamicSortPipe,
        PlainTextPipe,
        BuyTicketsDialogComponent,
        BookItemComponent,
        BookChildrenBirthdayComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        CommonModule,
        HttpClientModule,
        FormsModule,
        NgxDatatableModule,
        BrowserModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDatepickerModule,
        MatButtonToggleModule,
        MatCardModule,
        MatChipsModule,
        MatCheckboxModule,
        MatDialogModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatSliderModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatToolbarModule,
        MatRippleModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatTabsModule,
        MatExpansionModule,
        MatTableModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        LoaderModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatStepperModule,
        StripeModule.forRoot(''),
        TranslateModule.forRoot({
          defaultLanguage: 'nb-NO',
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
          },
        }),
        ApiModule.forRoot({ rootUrl: '' }),
        RouterModule.forRoot([
            {
                path: '',
                component: SecureLayoutComponent,
                children: [
                    {
                        path: '',
                        component: TicketsComponent,
                        canActivate: [SecureComponent]
                    },
                    {
                      path: 'gavekort',
                      component: TicketsComponent,
                      canActivate: [SecureComponent]
                  },
                    {
                        path: 'payments',
                        component: PaymentsComponent,
                        canActivate: [SecureComponent]
                    },
                    {
                        path: 'mytickets',
                        component: MyticketsComponent,
                        canActivate: [SecureComponent]
                    },
                    {
                        path: 'mybookings',
                        component: MybookingsComponent,
                        canActivate: [SecureComponent]
                    },
                    {
                        path: 'subscriptions',
                        component: SubscriptionsComponent,
                        canActivate: [SecureComponent]
                    },
                    {
                        path: 'profile',
                        component: ProfileComponent,
                        canActivate: [SecureComponent]
                    },
                    {
                        path: 'not-found',
                        component: NotFoundComponent,
                        canActivate: [SecureComponent]
                    },
                    {
                        path: 'booking',
                        component: BookingComponent,
                        canActivate: [SecureComponent]
                    },
                    {
                        path: 'children-birthday',
                        component: ChildrenBirthdayComponent,
                        canActivate: [SecureComponent]
                    }
                ]
            },
            {
                path: '',
                component: PublicLayoutComponent,
                children: [
                    {
                        path: 'login',
                        component: LoginComponent
                    },
                    {
                        path: 'register',
                        component: RegisterComponent
                    },
                    {
                        path: 'activateaccount',
                        component: ActivateAccountComponent
                    },
                    {
                        path: 'recoverpassword',
                        component: RecoverPasswordComponent
                    },
                    {
                        path: 'changepassword',
                        component: ChangePasswordComponent
                    },
                    {
                        path: 'not-found',
                        component: NotFoundComponent,
                        canActivate: [SecureComponent]
                    },
                    { path: '**', redirectTo: '/not-found' }
                ]
            },
            { path: '**', redirectTo: '/not-found' }
        ], {}),
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatChipsModule
    ],
    providers: [
        AuthService,
        SecureComponent,
        DatePipe,
        CurrencyPipe,
        MenuService,
        DialogService,
        AlertService,
        GlobalConfigService,
        UserService,
        OrderService,
        AppDatePipe,
        ItemService,
        ItemAccessService,
        ThirdPartyProviderService,
        ImportUserResultService,
        ReportService,
        FeedbackService,
        PageHelpService,
        ApplicationInsightsService,
        ColorService,
        CustomPaymentStripeService,
        { provide: LOCALE_ID, useValue: 'nb-NO'},
        {
            provide: APP_INITIALIZER,
            useFactory: (configSvc: GlobalConfigService) => () => configSvc.load(),
            deps: [GlobalConfigService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiUrlInterceptor,
            multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true,
          deps: [LoaderService],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
        MessageTemplateService,
        KeysPipe,
        MessageService,
        HubService,
        AppCurrencyPipe,
        PaymentService, 
        ItemCategoryService,
        DynamicSortPipe,
        DeactivateGuard,
        PlainTextPipe,
        RouteInterceptorService
    ]
})
export class AppModule {}
