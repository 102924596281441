import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { CountryService } from 'api/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class PublicLayoutComponent implements OnInit {
  public languages: any[] = [];
  public selectedLanguage: any;
  constructor(private countryService: CountryService, private translateService: TranslateService) {}
  ngOnInit(): void {
    this.getLanguages();
  }

  getLanguages() {
    this.countryService.CountryGetLanguages({}).subscribe(response => {
      this.languages = response.results;

      const defaultLanguage = this.languages.length
        ? this.languages[1].id
        : 'nb-NO';
      this.selectedLanguage = localStorage.getItem('language')
        ? localStorage.getItem('language')
        : defaultLanguage;
        this.translateService.use(this.selectedLanguage);
    });
  }
  changeLanguage(lang: MatSelectChange) {
    localStorage.setItem('language', lang.value);
    this.translateService.use(lang.value);
  }
}
