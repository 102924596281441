import { PipeTransform, Pipe } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'appCurrency' })
export class AppCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) { }

  transform(value: any, currency: string = 'NOK'): string | null {
    return this.currencyPipe.transform(value, currency, 'symbol-narrow', null, 'no');
  }
}
