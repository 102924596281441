import { Component, ViewChild } from '@angular/core';
import { AlertService } from 'services/alert.service';
import { Router } from '@angular/router';
import { BasePagedComponent } from '../../shared/base-paged.component';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BookingService, VippsService } from 'api/services';
import { DialogService } from 'shared/dialog.service';
import * as moment from 'moment';
import { TokenData } from 'models/login.model';
import { AuthService } from 'services/auth.service';
import { GlobalConfigService } from 'services/global-config.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ReadVarExpr } from '@angular/compiler';


@Component({
  templateUrl: './vipps.component.html',
  styleUrls: ['./vipps.component.css']
})
export class VippsComponent extends BasePagedComponent {
  showLoadingIcon = false;
  showError = false;
  tableData: any = {};
  items: any[] = [];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  expanded = false;
  userTokenData: TokenData;
  cancelOrder: string;
  youWillCancelOrder: string;

  constructor(
    alertService: AlertService,
    router: Router,
    public translateService: TranslateService,
    private bookingService: BookingService,
    private authService: AuthService,
    private dialogService: DialogService,
    private vippsService: VippsService,
    private globalConfig: GlobalConfigService,
    private httpClient: HttpClient
  ) {
    super(alertService, router, translateService);
    this.userTokenData = this.authService.getTokenData();
    this.translateService.get('cancel_order').subscribe(x =>
      this.cancelOrder = x);
    this.translateService.get('you_will_cancel_order').subscribe(x =>
      this.youWillCancelOrder = x);
  }

  loadData() {

    this.showLoadingIcon  = true;

    var orderId = localStorage.getItem('vipps-order-id') || '';
    if (orderId) {

      this.vippsService.VippsCompletePayment(Number(orderId)).subscribe(
        response => {
          this.showError = !response;
          this.showLoadingIcon = false;
          console.log("all ok!");
        },
        error => {
          this.defaultErrorHandler(error);
          this.showError = true;
          this.showLoadingIcon = false;
        }
      );

      this.showLoadingIcon = false;
    } else {

      this.showError = true;
      this.showLoadingIcon = false;
    }
 

  }



}
