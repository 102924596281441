/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { ApplicationInsightsService } from './services/application-insights.service';
import { BookingService } from './services/booking.service';
import { CompanyService } from './services/company.service';
import { CountryService } from './services/country.service';
import { DeviceService } from './services/device.service';
import { FeedbackService } from './services/feedback.service';
import { HubService } from './services/hub.service';
import { ItemAccessService } from './services/item-access.service';
import { ItemCategoryService } from './services/item-category.service';
import { ItemService } from './services/item.service';
import { LocationService } from './services/location.service';
import { LogReaderService } from './services/log-reader.service';
import { MessageService } from './services/message.service';
import { MessageTemplateService } from './services/message-template.service';
import { MobileDeviceService } from './services/mobile-device.service';
import { OrderService } from './services/order.service';
import { PageHelpService } from './services/page-help.service';
import { PaymentService } from './services/payment.service';
import { PaymentStripeService } from './services/payment-stripe.service';
import { ReportService } from './services/report.service';
import { ThirdPartyProviderService } from './services/third-party-provider.service';
import { TokenService } from './services/token.service';
import { UserService } from './services/user.service';
import { UserGroupService } from './services/user-group.service';
import { UtilService } from './services/util.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    ApplicationInsightsService,
    BookingService,
    CompanyService,
    CountryService,
    DeviceService,
    FeedbackService,
    HubService,
    ItemAccessService,
    ItemCategoryService,
    ItemService,
    LocationService,
    LogReaderService,
    MessageService,
    MessageTemplateService,
    MobileDeviceService,
    OrderService,
    PageHelpService,
    PaymentService,
    PaymentStripeService,
    ReportService,
    ThirdPartyProviderService,
    TokenService,
    UserService,
    UserGroupService,
    UtilService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
