/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedResultOfPageHelp } from '../models/paged-result-of-page-help';
import { PageHelp } from '../models/page-help';
import { PageHelpRequest } from '../models/page-help-request';
@Injectable({
  providedIn: 'root',
})
class PageHelpService extends __BaseService {
  static readonly PageHelpGetPath = '/api/PageHelp';
  static readonly PageHelpCreatePath = '/api/PageHelp';
  static readonly PageHelpUpdatePath = '/api/PageHelp';
  static readonly PageHelpGetAllPath = '/api/PageHelp/all';
  static readonly PageHelpGet2Path = '/api/PageHelp/{id}';
  static readonly PageHelpDeletePath = '/api/PageHelp/{id}';
  static readonly PageHelpGetBySlugPath = '/api/PageHelp/bySlug';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all page help items
   * @param params The `PageHelpService.PageHelpGetParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  PageHelpGetResponse(params: PageHelpService.PageHelpGetParams): __Observable<__StrictHttpResponse<PagedResultOfPageHelp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PageHelp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfPageHelp>;
      })
    );
  }
  /**
   * Get all page help items
   * @param params The `PageHelpService.PageHelpGetParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  PageHelpGet(params: PageHelpService.PageHelpGetParams): __Observable<PagedResultOfPageHelp> {
    return this.PageHelpGetResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfPageHelp)
    );
  }

  /**
   * Create Page help
   * @param patch undefined
   */
  PageHelpCreateResponse(patch: PageHelp): __Observable<__StrictHttpResponse<PageHelp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patch;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PageHelp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageHelp>;
      })
    );
  }
  /**
   * Create Page help
   * @param patch undefined
   */
  PageHelpCreate(patch: PageHelp): __Observable<PageHelp> {
    return this.PageHelpCreateResponse(patch).pipe(
      __map(_r => _r.body as PageHelp)
    );
  }

  /**
   * Update Page Help
   * @param patch undefined
   */
  PageHelpUpdateResponse(patch: PageHelp): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patch;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/PageHelp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update Page Help
   * @param patch undefined
   */
  PageHelpUpdate(patch: PageHelp): __Observable<null> {
    return this.PageHelpUpdateResponse(patch).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get all help pages
   */
  PageHelpGetAllResponse(): __Observable<__StrictHttpResponse<Array<PageHelp>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PageHelp/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PageHelp>>;
      })
    );
  }
  /**
   * Get all help pages
   */
  PageHelpGetAll(): __Observable<Array<PageHelp>> {
    return this.PageHelpGetAllResponse().pipe(
      __map(_r => _r.body as Array<PageHelp>)
    );
  }

  /**
   * Get page help by id
   * @param id undefined
   */
  PageHelpGet2Response(id: number): __Observable<__StrictHttpResponse<PageHelp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PageHelp/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageHelp>;
      })
    );
  }
  /**
   * Get page help by id
   * @param id undefined
   */
  PageHelpGet2(id: number): __Observable<PageHelp> {
    return this.PageHelpGet2Response(id).pipe(
      __map(_r => _r.body as PageHelp)
    );
  }

  /**
   * Delete Page Help
   * @param id undefined
   */
  PageHelpDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/PageHelp/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Page Help
   * @param id undefined
   */
  PageHelpDelete(id: number): __Observable<null> {
    return this.PageHelpDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get page help by slug and language
   * @param request undefined
   */
  PageHelpGetBySlugResponse(request: PageHelpRequest): __Observable<__StrictHttpResponse<PageHelp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PageHelp/bySlug`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageHelp>;
      })
    );
  }
  /**
   * Get page help by slug and language
   * @param request undefined
   */
  PageHelpGetBySlug(request: PageHelpRequest): __Observable<PageHelp> {
    return this.PageHelpGetBySlugResponse(request).pipe(
      __map(_r => _r.body as PageHelp)
    );
  }
}

module PageHelpService {

  /**
   * Parameters for PageHelpGet
   */
  export interface PageHelpGetParams {
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }
}

export { PageHelpService }
