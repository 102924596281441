/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class DoorService extends __BaseService {
  static readonly DoorOpenDoorPath = '/api/v2/Door/openDoor';
  static readonly DoorHeartBeatPath = '/api/v2/Door/heartBeat';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DoorService.DoorOpenDoorParams` containing the following parameters:
   *
   * - `UserName`:
   *
   * - `Type`:
   *
   * - `SN`:
   *
   * - `SCode`:
   *
   * - `ReaderNo`:
   *
   * - `PassWord`:
   *
   * - `OpenEvent`:
   *
   * - `DeviceID`:
   *
   * - `ActIndex`:
   */
  DoorOpenDoorResponse(params: DoorService.DoorOpenDoorParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.UserName != null) { __formData.append('UserName', params.UserName as string | Blob);}
    if (params.Type != null) { __formData.append('Type', JSON.stringify(params.Type));}
    if (params.SN != null) { __formData.append('SN', params.SN as string | Blob);}
    if (params.SCode != null) { __formData.append('SCode', params.SCode as string | Blob);}
    if (params.ReaderNo != null) { __formData.append('ReaderNo', params.ReaderNo as string | Blob);}
    if (params.PassWord != null) { __formData.append('PassWord', params.PassWord as string | Blob);}
    if (params.OpenEvent != null) { __formData.append('OpenEvent', JSON.stringify(params.OpenEvent));}
    if (params.DeviceID != null) { __formData.append('DeviceID', params.DeviceID as string | Blob);}
    if (params.ActIndex != null) { __formData.append('ActIndex', JSON.stringify(params.ActIndex));}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/Door/openDoor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `DoorService.DoorOpenDoorParams` containing the following parameters:
   *
   * - `UserName`:
   *
   * - `Type`:
   *
   * - `SN`:
   *
   * - `SCode`:
   *
   * - `ReaderNo`:
   *
   * - `PassWord`:
   *
   * - `OpenEvent`:
   *
   * - `DeviceID`:
   *
   * - `ActIndex`:
   */
  DoorOpenDoor(params: DoorService.DoorOpenDoorParams): __Observable<string> {
    return this.DoorOpenDoorResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `DoorService.DoorHeartBeatParams` containing the following parameters:
   *
   * - `Version`:
   *
   * - `UserName`:
   *
   * - `SN`:
   *
   * - `PassWord`:
   *
   * - `DoorStatus`:
   *
   * - `DeviceTime`:
   *
   * - `DeviceID`:
   */
  DoorHeartBeatResponse(params: DoorService.DoorHeartBeatParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.Version != null) { __formData.append('Version', params.Version as string | Blob);}
    if (params.UserName != null) { __formData.append('UserName', params.UserName as string | Blob);}
    if (params.SN != null) { __formData.append('SN', params.SN as string | Blob);}
    if (params.PassWord != null) { __formData.append('PassWord', params.PassWord as string | Blob);}
    if (params.DoorStatus != null) { __formData.append('DoorStatus', JSON.stringify(params.DoorStatus));}
    if (params.DeviceTime != null) { __formData.append('DeviceTime', JSON.stringify(params.DeviceTime));}
    if (params.DeviceID != null) { __formData.append('DeviceID', params.DeviceID as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/Door/heartBeat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `DoorService.DoorHeartBeatParams` containing the following parameters:
   *
   * - `Version`:
   *
   * - `UserName`:
   *
   * - `SN`:
   *
   * - `PassWord`:
   *
   * - `DoorStatus`:
   *
   * - `DeviceTime`:
   *
   * - `DeviceID`:
   */
  DoorHeartBeat(params: DoorService.DoorHeartBeatParams): __Observable<string> {
    return this.DoorHeartBeatResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module DoorService {

  /**
   * Parameters for DoorOpenDoor
   */
  export interface DoorOpenDoorParams {
    UserName?: null | string;
    Type?: number;
    SN?: null | string;
    SCode?: null | string;
    ReaderNo?: null | string;
    PassWord?: null | string;
    OpenEvent?: number;
    DeviceID?: null | string;
    ActIndex?: number;
  }

  /**
   * Parameters for DoorHeartBeat
   */
  export interface DoorHeartBeatParams {
    Version?: null | string;
    UserName?: null | string;
    SN?: null | string;
    PassWord?: null | string;
    DoorStatus?: number;
    DeviceTime?: number;
    DeviceID?: null | string;
  }
}

export { DoorService }
