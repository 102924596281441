/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreatePaymentResponse } from '../models/create-payment-response';
import { CreatePaymentRequest } from '../models/create-payment-request';
import { VippsUserInfo } from '../models/vipps-user-info';
@Injectable({
  providedIn: 'root',
})
class VippsService extends __BaseService {
  static readonly VippsCreatePaymentPath = '/api/v2/Vipps/createPayment';
  static readonly VippsCompletePaymentPath = '/api/v2/Vipps/completePayment';
  static readonly VippsGetLoginUrlPath = '/api/v2/Vipps/loginurl';
  static readonly VippsGetUserInfoPath = '/api/v2/Vipps/getUserInfo';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param createPaymentRequest undefined
   */
  VippsCreatePaymentResponse(createPaymentRequest: CreatePaymentRequest): __Observable<__StrictHttpResponse<CreatePaymentResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = createPaymentRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/Vipps/createPayment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreatePaymentResponse>;
      })
    );
  }
  /**
   * @param createPaymentRequest undefined
   */
  VippsCreatePayment(createPaymentRequest: CreatePaymentRequest): __Observable<CreatePaymentResponse> {
    return this.VippsCreatePaymentResponse(createPaymentRequest).pipe(
      __map(_r => _r.body as CreatePaymentResponse)
    );
  }

  /**
   * @param orderId undefined
   */
  VippsCompletePaymentResponse(orderId?: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orderId != null) __params = __params.set('orderId', orderId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/Vipps/completePayment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param orderId undefined
   */
  VippsCompletePayment(orderId?: number): __Observable<boolean> {
    return this.VippsCompletePaymentResponse(orderId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param callbackUrl undefined
   */
  VippsGetLoginUrlResponse(callbackUrl?: null | string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (callbackUrl != null) __params = __params.set('callbackUrl', callbackUrl.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/Vipps/loginurl`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param callbackUrl undefined
   */
  VippsGetLoginUrl(callbackUrl?: null | string): __Observable<string> {
    return this.VippsGetLoginUrlResponse(callbackUrl).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `VippsService.VippsGetUserInfoParams` containing the following parameters:
   *
   * - `code`:
   *
   * - `callbackUrl`:
   */
  VippsGetUserInfoResponse(params: VippsService.VippsGetUserInfoParams): __Observable<__StrictHttpResponse<VippsUserInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.code != null) __params = __params.set('code', params.code.toString());
    if (params.callbackUrl != null) __params = __params.set('callbackUrl', params.callbackUrl.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/Vipps/getUserInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VippsUserInfo>;
      })
    );
  }
  /**
   * @param params The `VippsService.VippsGetUserInfoParams` containing the following parameters:
   *
   * - `code`:
   *
   * - `callbackUrl`:
   */
  VippsGetUserInfo(params: VippsService.VippsGetUserInfoParams): __Observable<VippsUserInfo> {
    return this.VippsGetUserInfoResponse(params).pipe(
      __map(_r => _r.body as VippsUserInfo)
    );
  }
}

module VippsService {

  /**
   * Parameters for VippsGetUserInfo
   */
  export interface VippsGetUserInfoParams {
    code?: null | string;
    callbackUrl?: null | string;
  }
}

export { VippsService }
