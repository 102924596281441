import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { GlobalConfigService } from '../services/global-config.service';

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
  constructor(private config: GlobalConfigService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.config.loadedOk) {
      if (req.url.startsWith('/api')) {
        req = req.clone({
          url: req.url.replace('/api/', this.config.apiBaseUrl)
        });
      }

      // Service still sends responseType as json, so manual override is needed for endpoints returning data different from json
      if (req.url.endsWith('/generatepdf')) {
        req = req.clone({ responseType: 'blob' });
      }
    }

    return next.handle(req);
  }
}
