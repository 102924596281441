import { Component, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { AlertService } from 'services/alert.service';
import { Router } from '@angular/router';
import { BasePagedComponent } from '../../shared/base-paged.component';

import { DatatableComponent } from '@swimlane/ngx-datatable';

import { DeviceService, ItemService, OrderService, PaymentStripeService } from 'api/services';
import { DialogService } from 'shared/dialog.service';

import { AuthService } from '../../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './my-tickets.component.html',
  styleUrls: ['./my-tickets.component.css']
})
export class MyticketsComponent extends BasePagedComponent {
  showLoadingIcon = false;
  tableData: any = {};
  items: any[] = [];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  expanded = false;
  cancelOrder: string;
  youWillCancelOrder: string;
  pending: string;
  ordered: string;
  completed: string;
  canceled: string;
  not_paid: string;
  paid: string;
  refunded: string;
  orders = [];
  userId = null;
  cancelSubscriptionTitle: any;
  cancelSubscriptionMessage: any;

  constructor(
    alertService: AlertService,
    router: Router,
    public translateService: TranslateService,
    private orderService: OrderService,
    private deviceService: DeviceService,
    private itemService: ItemService,
    private dialogService: DialogService,
    private authService: AuthService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private paymentStripeService: PaymentStripeService
  ) {
    super(alertService, router, translateService);
    this.translateService.get('cancel_order').subscribe(x =>
      this.cancelOrder = x);
    this.translateService.get('pending').subscribe(x =>
      this.pending = x);
    this.translateService.get('ordered').subscribe(x =>
      this.ordered = x);
    this.translateService.get('completed').subscribe(x =>
      this.completed = x);
    this.translateService.get('canceled').subscribe(x =>
      this.canceled = x);
    this.translateService.get('not_paid').subscribe(x =>
      this.not_paid = x);
    this.translateService.get('paid').subscribe(x =>
      this.paid = x);
    this.translateService.get('refunded').subscribe(x =>
      this.refunded = x);
      this.translateService.get('cancel_subscription').subscribe(x => {
         this.cancelSubscriptionTitle = x;
      });

      this.translateService.get('confirm_cancel_subscription').subscribe(x => {
        this.cancelSubscriptionMessage = x;
     });

    this.userId = Number(this.authService.getTokenData()?.sub);
  }


  loadData() {
    const queryParams = {
      PageSize: 10000,
      Page: this.currentPage,
      IsDescending: this.orderBy.order === 'desc',
      OrderByKey: 'ID',
      Query: this.searchTerm,
      simpleResults: true,
      excludeUsed: true,
      Includes: 'OrderLines;Location.PhysicalAddress.Country'
    };
    this.showLoadingIcon = true;
    this.orderService.OrderGet(queryParams).subscribe(
      data => {
        // this.items = data.results;
        data.results = data.results.filter(x => x.paymentStatus !== 0);


        this.tableData['currentPage'] = data.page - 1;
        this.tableData['totalItems'] = data.totalResults;
        this.tableData['totalPages'] = data.totalPages;
        const orderlines = [];
        if (data && data.results) {
          data.results.forEach(item => {
            if (item.orderLines) {
              item.orderLines.forEach(orderline => {
                orderlines.push(orderline);
              });
            }

          });
        }
        // const validFrom = moment();
        // const validTo = moment(validFrom).add(1, 'day');
        // orderlines = orderlines.filter(o => moment(o.valid.from) <= validFrom && (o.valid.to == null || moment(o.valid.to) >= validTo));
        this.items = orderlines;


        this.showLoadingIcon = false;
      },
      err => {
        this.showLoadingIcon = false;
        this.defaultErrorHandler(err);
      }
    );
  }
  stopRecurring(item) {

   this.translateService.get('cancel_subscription').subscribe(x => {
    this.cancelSubscriptionTitle = x;
 });

 this.translateService.get('confirm_cancel_subscription').subscribe(x => {
   this.cancelSubscriptionMessage = x;
});

   if (item) {
    this.dialogService.confirm(this.cancelSubscriptionTitle, this.cancelSubscriptionMessage).subscribe(res => {
      if (res) {
        this.paymentStripeService.PaymentStripeCancelSubscription({userId: this.userId, orderLineId: item.id}).subscribe(res => {
          this.translateService.get('canceled').subscribe(msg => {
           this.alertService.success(msg);
           this.loadData();
          });
        }, err => {
          this.defaultErrorHandler(err);
        });
      }
   });
  }





  }





  getStatus(status) {
    switch (status) {
      case 0:
        return this.pending;
      case 1:
        return this.ordered;
      case 2:
        return this.completed;
      case 3:
        return this.canceled;
      default:
        return '';
    }

  }
  getPaymentStatus(status) {
    switch (status) {
      case 0:
        return this.not_paid;
      case 1:
         return this.paid;
      case 2:
        return this.refunded;
    }

  }
  checkIsStartedBooking(item) {
    const orderData = this.items.find(x => x.id === item.orderId);
    if (item && orderData && orderData.orderType === 1 && new Date(item.valid.from) > new Date()) {
      return true;
    } else {
      return false;
    }

  }
  cancelBooking(item) {

    this.dialogService.confirm(this.cancelOrder, null, this.youWillCancelOrder).subscribe(result => {

      if (result) {
        const orderData = this.items.find(x => x.id === item.orderId);
        if (orderData) {
          orderData.status = 3;
          this.orderService.OrderUpdate(orderData).subscribe(result => {
            this.loadData();
          }, err => {
            this.defaultErrorHandler(err);
          });
        }
      }

    });




  }
  checkOrderType(item) {
    if (item && item.orderType === 1) {
      return true;
    } else {
      return false;
    }

  }
  getName(item) {

    const currentLanguge = localStorage.getItem('language');
    if (currentLanguge) {
      switch (currentLanguge) {
        case 'en-GB': return item.itemName;
        case 'nb-NO': return item.properties['nb-NO_Name'] ? item.properties['nb-NO_Name'] : item.itemName;
        default: return item.name;
      }

    } else {
      return item.properties['nb-NO_Name'] ? item.properties['nb-NO_Name'] : item.itemName;
    }

  }
  generateQrCode(orderline) {
    const userId = Number(this.authService.getTokenData().sub);
    this.http.get(`/api/Order/qr/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(orderline.id))}`, {
      responseType: 'blob'
    }).subscribe(res => {
      if (res) {
        const unsafeImageUrl = URL.createObjectURL(res);
        const imageUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
        this.dialogService.showQrCode(imageUrl);
      }

    }, err => {
      this.defaultErrorHandler(err);
    });


  }

  downloadGiftCard(orderline) {
    const userId = Number(this.authService.getTokenData().sub);
    this.http.get(`/api/Order/qr/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(orderline.id))}`, {
      responseType: 'blob'
    }).subscribe(res => {
      if (res) {
        const unsafeImageUrl = URL.createObjectURL(res);
        const imageUrl = unsafeImageUrl;
        const image = '/assets/giftcard.png';

        this.mergeImages(imageUrl, image, orderline);
      }
    }, err => {
      this.defaultErrorHandler(err);
    });
  }

  mergeImages(qrCode, image, orderline) {
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    let cw;
    let ch;
    const img = new Image();

    img.onload = function () {
      const qrImage = new Image();

      cw = canvas.width = img.width;
      ch = canvas.height = img.height;

      ctx.drawImage(img, 0, 0);


      const text = orderline.id;

      ctx.font = '12px verdana';
      ctx.fillStyle = 'black';

      ctx.fillText(text, cw - 100 - 10, ch - 190);

      qrImage.onload = function () {
        const resultCanvasCtx = canvas.getContext('2d');
        resultCanvasCtx.drawImage(qrImage, cw - 170, ch - 350, 150, 150);


        const blobFile = canvas.toBlob((blob: any) => {
          const fileName = text + '.jpg';

          const file = new File([blob], fileName, { type: 'image/jpeg' });

          FileSaver.saveAs(blob, fileName);

        }, 'image/jpeg');

      };

      qrImage.src = qrCode;
    };

    img.src = image;
  }
}
