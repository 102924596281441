import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { Menu, MenuService } from 'services/menu.service';
import { TokenData } from 'models/login.model';
import { AuthService } from 'services/auth.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { CountryService, UserService } from 'api/services';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../../shared/dialog.service';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class SecureLayoutComponent implements OnInit {
  public mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  menuItems: Menu[] = [];
  user: TokenData;

  public languages: any[] = [];
  public selectedLanguage: any;
  deleteYouData = '';
  confirmDeleteData = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private menuService: MenuService,
    private countryService: CountryService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private userService: UserService
  ) {
    this.user = this.authService.getTokenData();
    this.translateService.get('delete_your_data').subscribe(res => {
        this.deleteYouData = res;
    });

    this.translateService.get('confirm_delete_data').subscribe(res => {
      this.confirmDeleteData = res;
  });
  }

  ngOnInit() {
    this.mobileQuery = this.media.matchMedia('(max-width: 992px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.menuItems = this.menuService.getAvailableClientMenus();

    this.getLanguages();
  }

  getLanguages() {
    this.countryService.CountryGetLanguages({}).subscribe(response => {
      this.languages = response.results;


      const defaultLanguage = this.languages.length
        ? this.languages[1].id
        : 'nb-NO';
      this.selectedLanguage = localStorage.getItem('language')
        ? localStorage.getItem('language')
        : defaultLanguage;
    });
  }

  changeLanguage(lang: MatSelectChange) {
    localStorage.setItem('language', lang.value);
    this.translateService.use(lang.value);
  }

  logout() {
    const loggedOut = this.authService.logout();
    if (loggedOut) {
      this.router.navigate(['/login']);
    }
  }
  navigateToProfile() {
    this.router.navigate(['/profile']);
  }
  openHome() {
    this.router.navigate(['']);
  }
  deleteMe() {

    this.translateService.get('delete_your_data').subscribe(res => {
      this.deleteYouData = res;
  });

  this.translateService.get('confirm_delete_data').subscribe(res => {
    this.confirmDeleteData = res;
});

    this.dialogService.confirm(this.deleteYouData, this.confirmDeleteData).subscribe(res => {
       if (res) {
       this.userService.UserDeleteMyData().subscribe(()=>{
         this.authService.logout();
         this.router.navigate(['/login']);

       });
       }
    });

  }
}
