/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { InsightStatistics } from '../models/insight-statistics';
@Injectable({
  providedIn: 'root',
})
class ApplicationInsightsService extends __BaseService {
  static readonly ApplicationInsightsGetStatisticsPath = '/api/ApplicationInsights/statistics';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ApplicationInsightsService.ApplicationInsightsGetStatisticsParams` containing the following parameters:
   *
   * - `time`:
   *
   * - `period`:
   */
  ApplicationInsightsGetStatisticsResponse(params: ApplicationInsightsService.ApplicationInsightsGetStatisticsParams): __Observable<__StrictHttpResponse<Array<InsightStatistics>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.time != null) __params = __params.set('time', params.time.toString());
    if (params.period != null) __params = __params.set('period', params.period.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ApplicationInsights/statistics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<InsightStatistics>>;
      })
    );
  }
  /**
   * @param params The `ApplicationInsightsService.ApplicationInsightsGetStatisticsParams` containing the following parameters:
   *
   * - `time`:
   *
   * - `period`:
   */
  ApplicationInsightsGetStatistics(params: ApplicationInsightsService.ApplicationInsightsGetStatisticsParams): __Observable<Array<InsightStatistics>> {
    return this.ApplicationInsightsGetStatisticsResponse(params).pipe(
      __map(_r => _r.body as Array<InsightStatistics>)
    );
  }
}

module ApplicationInsightsService {

  /**
   * Parameters for ApplicationInsightsGetStatistics
   */
  export interface ApplicationInsightsGetStatisticsParams {
    time?: number;
    period?: null | string;
  }
}

export { ApplicationInsightsService }
