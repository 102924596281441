import { OnInit, Directive } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { BaseComponent } from './base.component';
import { AlertService } from 'services/alert.service';
import { DatatablePageData } from './datatable-commonfooter.component';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class BasePagedComponent extends BaseComponent
  implements OnInit {
  currentPageSize = 20;
  currentPage = 1;
  pageSizes: number[] = [10, 20, 50, 100];
  searchTerm = '';
  orderBy: any = { column: '', order: 'desc' };
  emitSearch: (event: any) => void;
  emitFilter: (event: any, column: string) => void;
  filter: any;

  constructor(alertService: AlertService, router: Router, public translateService: TranslateService) {
    super(alertService, router, translateService);
  }

  abstract loadData();

  ngOnInit(loadData = true) {
    if (loadData) {
      this.loadData();
    }

    this.searchTermChanges().subscribe(value => {
      this.searchTerm = value.trim();
      this.currentPage = 1;
      this.loadData();
    });
    this.filterChanges().subscribe(value => {
      this.loadData();
    });
  }
  ApplyLocationFilter(childComponent: any, locationId?: number) {
    if (locationId == null) {
      const tempLocation = localStorage.getItem('locationsFilter');
      if (tempLocation) {
        childComponent.filterLocation = Number(tempLocation);
        locationId = Number(childComponent.filterLocation);
      }
    }

    if (locationId != null) {
      localStorage.setItem('locationsFilter', locationId + '');
    }
    return locationId;
  }

  searchTermChanges(): Observable<any> {
    return new Observable((observer: any) => {
      this.emitSearch = (event: any) => {
        observer.next((<HTMLInputElement>event.target).value);
      };
    }).pipe(
      distinctUntilChanged(),
      debounceTime(300)
    );
  }

  filterChanges(): Observable<any> {
    return new Observable((observer: any) => {
      this.emitFilter = (event: any, column: string) => {
        const value = (<HTMLInputElement>event.target).value.trim();
        if (!this.filter) {
          this.filter = {};
        }
        if (!value && this.filter[column]) {
          delete this.filter[column];
        } else {
          this.filter[column] = value;
        }

        if (this.filter && !Object.keys(this.filter).length) {
          this.filter = undefined;
        }

        observer.next({ value: this.filter });
      };
    }).pipe(
      distinctUntilChanged(),
      debounceTime(300)
    );
  }

  pagingChanged(pageChangeData: DatatablePageData) {
    this.currentPage = pageChangeData.page;
    this.currentPageSize = pageChangeData.pageSize;
    this.loadData();
  }

  onSort(event: any) {
    this.orderBy.column = event.column.prop;
    this.orderBy.order = event.newValue;
    this.loadData();
  }
}
