
import { MatDialogRef } from '@angular/material/dialog';

import { BaseComponent } from 'components/shared/base.component';
import { AlertService } from 'services/alert.service';

import { TokenData } from 'models/login.model';
import { AuthService } from 'services/auth.service';
import * as moment from 'moment';
import { OrderService, UserService, BookingService } from 'api/services';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import * as ICal from 'ical.js';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-book-item',
  templateUrl: './book-item.component.html',
  styleUrls: ['./book-item.component.css']
})
export class BookItemComponent extends BaseComponent implements OnInit {
  userTokenData: TokenData;
  data: any;
  user: any;
  showLoadingIcon = false;
  constructor(
    alertService: AlertService,
    router: Router,
    public translateService: TranslateService,
    private dialogRef: MatDialogRef<BookItemComponent>,
    private authService: AuthService,
    private orderService: OrderService,
    private userService: UserService,
    private bookingService: BookingService,
    private httpClient: HttpClient

  ) {
    super(alertService, router, translateService);
    this.userTokenData = this.authService.getTokenData();
    this.userService.UserGet(Number(this.userTokenData.sub)).subscribe(res => {

      this.user = res;

    }, err => {
      this.defaultErrorHandler(err);
    });
  }

  ngOnInit() {
  }
  buy() {

    const calendar = new ICal.Component(['vcalendar', [], []]);
    calendar.updatePropertyWithValue('userId', Number(this.userTokenData.sub));
    calendar.updatePropertyWithValue('itemId', this.data.id);

      const vevent = new ICal.Component('vevent');
      const _event = new ICal.Event(vevent);

      _event.userId = Number(this.userTokenData.sub);
      _event.startDate = new ICal.Time.fromDateTimeString(
        this.convertDate(this.data.start)
      );
      _event.endDate = new ICal.Time.fromDateTimeString(
        this.convertDate(this.data.end)
      );

      calendar.addSubcomponent(vevent);
      const bookingLines = [];
      bookingLines.push({
      itemId: this.data.id,
      quantity: 1,
      item: { id: this.data.id}
      });
      const data = {
        UserId: Number(this.userTokenData.sub),
        User: {
          Id: Number(this.userTokenData.sub)
        },
        BookingCalendar: calendar.toString(),
        BookingLines: bookingLines
      };
      this.showLoadingIcon = true;
      this.httpClient.post('/api/Booking', data).subscribe(res => {
      this.showLoadingIcon = false;
      this.dialogRef.close();

      this.translateService.get('success_booked').subscribe(messsage => {
           this.alertService.success(messsage, 3000);
      });
      }, err => {
        this.defaultErrorHandler(err);
      });

  }
  close() {
    this.dialogRef.close();
  }

  convertDate(date, zone?) {
    if (zone === 'local') {
      date = moment(date).utc(true).format('YYYY-MM-DDTHH:mm:ss[Z]');
      date = moment(date).local();
      return new Date(date);
    } else {
       date = moment(date).local(false).format();

       return moment(date).add(-2, 'hours').format('YYYY-MM-DDTHH:mm:ss') + 'Z';

     // return moment(date).utc(true).add(-2, 'hours').format('YYYY-MM-DDTHH:mm:ss');

    }
  }
}
