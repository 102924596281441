<mat-card class="my-tickets-mat-card">
    <mat-card-header>
      <mat-card-title>{{'my_tickets' | translate}}</mat-card-title>
    </mat-card-header>

        <mat-card-content>


          <ngx-datatable class="material fullscreen flex-responsive only-scroll-horz no-box-shadow order-table my-tickets-table" [columnMode]="'force'" [headerHeight]="'auto'" [footerHeight]="50" [rowHeight]="'auto'" [rows]="items || []"
          [scrollbarH]="true"  [messages]="{emptyMessage: 'no_data_to_display' | translate, totalMessage: 'total' | translate}">
          <ngx-datatable-column name="{{'type' | translate}}" prop="itemCategory"  [width]="50">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
               <span *ngIf="row.itemType===1" >{{'single_tickets' | translate}}</span>
               <span *ngIf="row.itemType===4" > {{'monthly_subscription' | translate}} </span>
               <span *ngIf="row.itemType===2" > {{'yearly_subscription' | translate}} </span>
               <span *ngIf="row.itemType===5" >{{'journey_tickets' | translate}} </span>
               <span *ngIf="row.itemType===0" > {{'gift_tickets' | translate}} </span>
            </ng-template>
        </ngx-datatable-column>
          <ngx-datatable-column name="{{'ticket' | translate}}">

            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ getName(row) }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column>

          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <button style="background-color:#c23277;" mat-raised-button color="primary" (click)="generateQrCode(row)">{{'qr_code' | translate}}</button>
            <button *ngIf="row.itemId==288" class="ml-3"  mat-raised-button color="primary" (click)="downloadGiftCard(row)" >{{'download_gift_card' | translate}}</button>
            <button *ngIf="row.itemId==204" class="ml-3"  mat-raised-button color="primary" (click)="downloadGiftCard(row)" >{{'download_gift_card' | translate}}</button>


            <button *ngIf="row.itemType==4 && !row.cancelRecurring"  class="ml-3"  mat-raised-button color="primary" (click)="stopRecurring(row)" >Stop</button>
            <button disabled *ngIf="row.itemType==4 && row.cancelRecurring"  class="ml-3"  mat-raised-button color="primary" >{{'stopped' | translate}}</button>

          </ng-template>
      </ngx-datatable-column>







        </ngx-datatable>


        </mat-card-content>
</mat-card>
