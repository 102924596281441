<mat-card class="payment-mat-card">
    <mat-card-header>
        <mat-card-title >{{'payments_history' | translate}}</mat-card-title>
    </mat-card-header>

        <mat-card-content>
            <ngx-datatable class="material fullscreen flex-responsive only-scroll-horz no-box-shadow payment-table" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [count]="tableData.totalItems" [rows]="items || []" [offset]="tableData.currentPage"
                [limit]="currentPageSize" [externalPaging]="true" [externalSorting]="true" (sort)="onSort($event)" [scrollbarH]="true" (activate)="toggleExpandRow($event)" [messages]="{emptyMessage: 'no_data_to_display' | translate, totalMessage: 'total' | translate}">
                <ngx-datatable-row-detail [rowHeight]="'auto'" #detailRow>
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                        <ngx-datatable class="material fullscreen no-box-shadow flex-responsive only-scroll-horz" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="'auto'" [rowHeight]="'auto'" [rows]="row.order.orderLines || []" [scrollbarH]="true" [messages]="{emptyMessage: 'no_data_to_display' | translate, totalMessage: 'total' | translate}">
                            <ngx-datatable-column name="{{'name' | translate}}" prop="itemName">
                                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    {{ getName(row) }}
                                </ng-template>
                            </ngx-datatable-column>


                            <ngx-datatable-column name="{{'used_from_to' | translate}}" prop="used" [width]="200">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    <span> {{ ((value.from) ? (value.from | appDate) : '--')  + ' - ' + ((value.to) ? (value.to | appDate) : '--')  }} </span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'price_c' | translate}}"  prop="itemPrice.value">
                                <ng-template let-price="value" let-row="row" ngx-datatable-cell-template>
                                    <span>{{ price | appCurrency }}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column [headerClass]="'headerNumberOfTickets'" [cellClass]="'numberOfTickets'" name="{{'quantity' | translate}}"  prop="itemCount">

                                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    <span>{{ value }}</span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'total | translate'}}"  prop="itemPrice.value">
                                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    <span>{{ (value * row.itemCount) | appCurrency }}</span>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </ng-template>
                </ngx-datatable-row-detail>

                <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                        <a href="javascript:void(0)" class="expand-arrow" [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="{{'expand_collapse' | translate}}" ></a>
                    </ng-template>
                    <ng-template ngx-datatable-header-template>
                        <a href="javascript:void(0)" class="expand-arrow" [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="{{'expand_collapse' | translate}}"  (click)="toggleExpandAllRows()"></a>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{'price_c' | translate}}"   prop="order.totalPrice">
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        <b>{{ value | appCurrency }}</b>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'paid_with' | translate}}"  prop="paymentOption.name">
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{ value }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'created' | translate}}"  prop="createdOn">
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{value | appDate}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'status' | translate}}"  prop="status">
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{ getPaymentStatus(value) | translate }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [width]="80" [canAutoResize]="false">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <button mat-icon-button (click)="downloadReceipt(row.order)" title="Download receipt" *ngIf="row.status===1"><mat-icon>receipt</mat-icon></button>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-footer [template]="commonfooter.tmplDir.template"></ngx-datatable-footer>
                <datatable-commonfooter #commonfooter [page]="currentPage" [pageSize]="currentPageSize" [totalPages]="tableData.totalPages" [rowCount]="tableData.totalItems" (change)="pagingChanged($event)"></datatable-commonfooter>
            </ngx-datatable>
        </mat-card-content>
</mat-card>
