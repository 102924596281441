import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    const keys = [];
    Object.keys(value).forEach((key, index) => {
      keys.push({key: key, value: value[key]});
    });
    return keys;
  }
}
