
export class LoginModel {
  username: string;
  password: string;
}

export class TokenData {
  token: string;
  sub: string;
  email: string;
  unique_name: string;
  exp: Number = 0;

  companyName: string;
  companyId: number;
  role: string;
}
