/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReportRevenueResponse } from '../models/report-revenue-response';
import { Booking } from '../models/booking';
import { ReportLocationUsageResponse } from '../models/report-location-usage-response';
import { GenericDictionaryOfInteger } from '../models/generic-dictionary-of-integer';
import { Stream } from '../models/stream';
import { VisitorStatisticsResponseModel } from '../models/visitor-statistics-response-model';
import { RegisteredStatisticsResponse } from '../models/registered-statistics-response';
import { GrossVolumeItem } from '../models/gross-volume-item';
import { PagedResultOfAccessReportUser } from '../models/paged-result-of-access-report-user';
import { StoragesReport } from '../models/storages-report';
@Injectable({
  providedIn: 'root',
})
class ReportService extends __BaseService {
  static readonly ReportRevenueReportPath = '/api/Report/revenue';
  static readonly ReportBookingReportPath = '/api/Report/bookingreport';
  static readonly ReportLocationUsagePath = '/api/Report/locationusage';
  static readonly ReportAverageItemUsagePath = '/api/Report/averageitemusage';
  static readonly ReportPeakhoursPath = '/api/Report/peakhours';
  static readonly ReportItemUsagePercentagePath = '/api/Report/itemusagepercentage';
  static readonly ReportCurrentVisitorsPath = '/api/Report/currentvisitors';
  static readonly ReportTotalVisitorsForDatePath = '/api/Report/totalvisitors';
  static readonly ReportGenerateRevenuePdfPath = '/api/Report/revenue/generatepdf';
  static readonly ReportVisitorStatisticsPath = '/api/Report/visitorstatistics';
  static readonly ReportRegisteredStatisticsPath = '/api/Report/registeredstatistics';
  static readonly ReportGrossVolumePath = '/api/Report/grossvolume';
  static readonly ReportAccessReportPath = '/api/Report/accessreport';
  static readonly ReportGetStorageUsagePath = '/api/Report/StorageUsage';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ReportService.ReportRevenueReportParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportRevenueReportResponse(params: ReportService.ReportRevenueReportParams): __Observable<__StrictHttpResponse<ReportRevenueResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Start != null) __params = __params.set('Start', params.Start.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.End != null) __params = __params.set('End', params.End.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/revenue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportRevenueResponse>;
      })
    );
  }
  /**
   * @param params The `ReportService.ReportRevenueReportParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportRevenueReport(params: ReportService.ReportRevenueReportParams): __Observable<ReportRevenueResponse> {
    return this.ReportRevenueReportResponse(params).pipe(
      __map(_r => _r.body as ReportRevenueResponse)
    );
  }

  /**
   * @param params The `ReportService.ReportBookingReportParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportBookingReportResponse(params: ReportService.ReportBookingReportParams): __Observable<__StrictHttpResponse<Array<Booking>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Start != null) __params = __params.set('Start', params.Start.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.End != null) __params = __params.set('End', params.End.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/bookingreport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Booking>>;
      })
    );
  }
  /**
   * @param params The `ReportService.ReportBookingReportParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportBookingReport(params: ReportService.ReportBookingReportParams): __Observable<Array<Booking>> {
    return this.ReportBookingReportResponse(params).pipe(
      __map(_r => _r.body as Array<Booking>)
    );
  }

  /**
   * @param params The `ReportService.ReportLocationUsageParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportLocationUsageResponse(params: ReportService.ReportLocationUsageParams): __Observable<__StrictHttpResponse<Array<ReportLocationUsageResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Start != null) __params = __params.set('Start', params.Start.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.End != null) __params = __params.set('End', params.End.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/locationusage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReportLocationUsageResponse>>;
      })
    );
  }
  /**
   * @param params The `ReportService.ReportLocationUsageParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportLocationUsage(params: ReportService.ReportLocationUsageParams): __Observable<Array<ReportLocationUsageResponse>> {
    return this.ReportLocationUsageResponse(params).pipe(
      __map(_r => _r.body as Array<ReportLocationUsageResponse>)
    );
  }

  /**
   * @param params The `ReportService.ReportAverageItemUsageParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportAverageItemUsageResponse(params: ReportService.ReportAverageItemUsageParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Start != null) __params = __params.set('Start', params.Start.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.End != null) __params = __params.set('End', params.End.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/averageitemusage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ReportService.ReportAverageItemUsageParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportAverageItemUsage(params: ReportService.ReportAverageItemUsageParams): __Observable<string> {
    return this.ReportAverageItemUsageResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ReportService.ReportPeakhoursParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportPeakhoursResponse(params: ReportService.ReportPeakhoursParams): __Observable<__StrictHttpResponse<GenericDictionaryOfInteger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Start != null) __params = __params.set('Start', params.Start.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.End != null) __params = __params.set('End', params.End.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/peakhours`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GenericDictionaryOfInteger>;
      })
    );
  }
  /**
   * @param params The `ReportService.ReportPeakhoursParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportPeakhours(params: ReportService.ReportPeakhoursParams): __Observable<GenericDictionaryOfInteger> {
    return this.ReportPeakhoursResponse(params).pipe(
      __map(_r => _r.body as GenericDictionaryOfInteger)
    );
  }

  /**
   * @param params The `ReportService.ReportItemUsagePercentageParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportItemUsagePercentageResponse(params: ReportService.ReportItemUsagePercentageParams): __Observable<__StrictHttpResponse<GenericDictionaryOfInteger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Start != null) __params = __params.set('Start', params.Start.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.End != null) __params = __params.set('End', params.End.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/itemusagepercentage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GenericDictionaryOfInteger>;
      })
    );
  }
  /**
   * @param params The `ReportService.ReportItemUsagePercentageParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportItemUsagePercentage(params: ReportService.ReportItemUsagePercentageParams): __Observable<GenericDictionaryOfInteger> {
    return this.ReportItemUsagePercentageResponse(params).pipe(
      __map(_r => _r.body as GenericDictionaryOfInteger)
    );
  }
  ReportCurrentVisitorsResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/currentvisitors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }  ReportCurrentVisitors(): __Observable<number> {
    return this.ReportCurrentVisitorsResponse().pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `ReportService.ReportTotalVisitorsForDateParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportTotalVisitorsForDateResponse(params: ReportService.ReportTotalVisitorsForDateParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Start != null) __params = __params.set('Start', params.Start.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.End != null) __params = __params.set('End', params.End.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/totalvisitors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `ReportService.ReportTotalVisitorsForDateParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportTotalVisitorsForDate(params: ReportService.ReportTotalVisitorsForDateParams): __Observable<number> {
    return this.ReportTotalVisitorsForDateResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param report undefined
   */
  ReportGenerateRevenuePdfResponse(report: ReportRevenueResponse): __Observable<__StrictHttpResponse<Stream>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = report;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Report/revenue/generatepdf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Stream>;
      })
    );
  }
  /**
   * @param report undefined
   */
  ReportGenerateRevenuePdf(report: ReportRevenueResponse): __Observable<Stream> {
    return this.ReportGenerateRevenuePdfResponse(report).pipe(
      __map(_r => _r.body as Stream)
    );
  }

  /**
   * @param params The `ReportService.ReportVisitorStatisticsParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportVisitorStatisticsResponse(params: ReportService.ReportVisitorStatisticsParams): __Observable<__StrictHttpResponse<VisitorStatisticsResponseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Start != null) __params = __params.set('Start', params.Start.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.End != null) __params = __params.set('End', params.End.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/visitorstatistics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitorStatisticsResponseModel>;
      })
    );
  }
  /**
   * @param params The `ReportService.ReportVisitorStatisticsParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportVisitorStatistics(params: ReportService.ReportVisitorStatisticsParams): __Observable<VisitorStatisticsResponseModel> {
    return this.ReportVisitorStatisticsResponse(params).pipe(
      __map(_r => _r.body as VisitorStatisticsResponseModel)
    );
  }

  /**
   * @param params The `ReportService.ReportRegisteredStatisticsParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportRegisteredStatisticsResponse(params: ReportService.ReportRegisteredStatisticsParams): __Observable<__StrictHttpResponse<RegisteredStatisticsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Start != null) __params = __params.set('Start', params.Start.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.End != null) __params = __params.set('End', params.End.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/registeredstatistics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RegisteredStatisticsResponse>;
      })
    );
  }
  /**
   * @param params The `ReportService.ReportRegisteredStatisticsParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportRegisteredStatistics(params: ReportService.ReportRegisteredStatisticsParams): __Observable<RegisteredStatisticsResponse> {
    return this.ReportRegisteredStatisticsResponse(params).pipe(
      __map(_r => _r.body as RegisteredStatisticsResponse)
    );
  }

  /**
   * @param params The `ReportService.ReportGrossVolumeParams` containing the following parameters:
   *
   * - `timeZone`:
   *
   * - `startDate`:
   *
   * - `endDate`:
   */
  ReportGrossVolumeResponse(params: ReportService.ReportGrossVolumeParams): __Observable<__StrictHttpResponse<Array<GrossVolumeItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.timeZone != null) __params = __params.set('timeZone', params.timeZone.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/grossvolume`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GrossVolumeItem>>;
      })
    );
  }
  /**
   * @param params The `ReportService.ReportGrossVolumeParams` containing the following parameters:
   *
   * - `timeZone`:
   *
   * - `startDate`:
   *
   * - `endDate`:
   */
  ReportGrossVolume(params: ReportService.ReportGrossVolumeParams): __Observable<Array<GrossVolumeItem>> {
    return this.ReportGrossVolumeResponse(params).pipe(
      __map(_r => _r.body as Array<GrossVolumeItem>)
    );
  }

  /**
   * @param params The `ReportService.ReportAccessReportParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `locationId`:
   *
   * - `end`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ReportAccessReportResponse(params: ReportService.ReportAccessReportParams): __Observable<__StrictHttpResponse<PagedResultOfAccessReportUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.start != null) __params = __params.set('start', params.start.toString());
    if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
    if (params.end != null) __params = __params.set('end', params.end.toString());
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/accessreport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfAccessReportUser>;
      })
    );
  }
  /**
   * @param params The `ReportService.ReportAccessReportParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `locationId`:
   *
   * - `end`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  ReportAccessReport(params: ReportService.ReportAccessReportParams): __Observable<PagedResultOfAccessReportUser> {
    return this.ReportAccessReportResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfAccessReportUser)
    );
  }

  /**
   * @param params The `ReportService.ReportGetStorageUsageParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportGetStorageUsageResponse(params: ReportService.ReportGetStorageUsageParams): __Observable<__StrictHttpResponse<StoragesReport>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Start != null) __params = __params.set('Start', params.Start.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.End != null) __params = __params.set('End', params.End.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/StorageUsage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StoragesReport>;
      })
    );
  }
  /**
   * @param params The `ReportService.ReportGetStorageUsageParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Start`:
   *
   * - `LocationId`:
   *
   * - `End`:
   */
  ReportGetStorageUsage(params: ReportService.ReportGetStorageUsageParams): __Observable<StoragesReport> {
    return this.ReportGetStorageUsageResponse(params).pipe(
      __map(_r => _r.body as StoragesReport)
    );
  }
}

module ReportService {

  /**
   * Parameters for ReportRevenueReport
   */
  export interface ReportRevenueReportParams {
    Type?: null | string;
    Start?: string;
    LocationId?: null | number;
    End?: string;
  }

  /**
   * Parameters for ReportBookingReport
   */
  export interface ReportBookingReportParams {
    Type?: null | string;
    Start?: string;
    LocationId?: null | number;
    End?: string;
  }

  /**
   * Parameters for ReportLocationUsage
   */
  export interface ReportLocationUsageParams {
    Type?: null | string;
    Start?: string;
    LocationId?: null | number;
    End?: string;
  }

  /**
   * Parameters for ReportAverageItemUsage
   */
  export interface ReportAverageItemUsageParams {
    Type?: null | string;
    Start?: string;
    LocationId?: null | number;
    End?: string;
  }

  /**
   * Parameters for ReportPeakhours
   */
  export interface ReportPeakhoursParams {
    Type?: null | string;
    Start?: string;
    LocationId?: null | number;
    End?: string;
  }

  /**
   * Parameters for ReportItemUsagePercentage
   */
  export interface ReportItemUsagePercentageParams {
    Type?: null | string;
    Start?: string;
    LocationId?: null | number;
    End?: string;
  }

  /**
   * Parameters for ReportTotalVisitorsForDate
   */
  export interface ReportTotalVisitorsForDateParams {
    Type?: null | string;
    Start?: string;
    LocationId?: null | number;
    End?: string;
  }

  /**
   * Parameters for ReportVisitorStatistics
   */
  export interface ReportVisitorStatisticsParams {
    Type?: null | string;
    Start?: string;
    LocationId?: null | number;
    End?: string;
  }

  /**
   * Parameters for ReportRegisteredStatistics
   */
  export interface ReportRegisteredStatisticsParams {
    Type?: null | string;
    Start?: string;
    LocationId?: null | number;
    End?: string;
  }

  /**
   * Parameters for ReportGrossVolume
   */
  export interface ReportGrossVolumeParams {
    timeZone?: number;
    startDate?: null | string;
    endDate?: null | string;
  }

  /**
   * Parameters for ReportAccessReport
   */
  export interface ReportAccessReportParams {
    start?: string;
    locationId?: null | number;
    end?: string;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for ReportGetStorageUsage
   */
  export interface ReportGetStorageUsageParams {
    Type?: null | string;
    Start?: string;
    LocationId?: null | number;
    End?: string;
  }
}

export { ReportService }
