<mat-card class="login-card">
    <mat-card-header>
        <mat-card-title>{{'sign_in' | translate}}</mat-card-title>
        <mat-card-subtitle style="margin-top: 16px;" >{{'enter_username_password' | translate}}</mat-card-subtitle>
    </mat-card-header>
        <!-- <mat-card-subtitle>Demo user: demo@source.no/test1234</mat-card-subtitle> -->

        <mat-card-content>
            <form [formGroup]="loginForm" (ngSubmit)="submit()">
                <mat-form-field appearance="outline">
                    <mat-label> {{ 'phone_or_email' | translate }}</mat-label>
                    <input matInput formControlName="username">

                    <mat-error *ngIf="loginForm.controls.username.hasError('required') || loginForm.controls.username.hasError('minlength')" >{{'phone_or_email_required' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label >{{'password' | translate}}</mat-label>
                    <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password">
                    <mat-icon matSuffix (click)="hidePassword = !hidePassword" class="toggle-password-visibility">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>

                    <mat-error *ngIf="loginForm.controls.password.hasError('required') || loginForm.controls.password.hasError('minlength')" >{{'password_required' | translate}}</mat-error>
                </mat-form-field>

                <div layout="row" layout-align="start center">
                    <div class="action-buttons" flex>
                        <button class="loginButton" mat-raised-button color="primary" >{{'sign_in' | translate}}</button>
                        <button mat-raised-button color="secondary"  (click)="register()">{{'register' | translate}}</button>
                    </div>
                    <a routerLink="/recoverpassword" class="forgot-password"><small >{{ 'lost_your_password' | translate }}</small></a>
                </div>
            </form>
        </mat-card-content>
</mat-card>
