/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedResultOfOrder } from '../models/paged-result-of-order';
import { Order } from '../models/order';
import { RecurringOrder } from '../models/recurring-order';
import { Stream } from '../models/stream';
import { OrderLine } from '../models/order-line';
import { OrderLineSimple } from '../models/order-line-simple';
import { PagedResultOfOrderLineSimple } from '../models/paged-result-of-order-line-simple';
@Injectable({
  providedIn: 'root',
})
class OrderService extends __BaseService {
  static readonly OrderAddNfcUidToOrderLinePath = '/api/Order/AddNfcUidToOrderLine';
  static readonly OrderGetPath = '/api/Order';
  static readonly OrderCreatePath = '/api/Order';
  static readonly OrderUpdatePath = '/api/Order';
  static readonly OrderScanOutCustomersPath = '/api/Order/scanoutcustomers/{locationId}';
  static readonly OrderGetByLocationAndItemsPath = '/api/Order/booking/{locationId}';
  static readonly OrderGetByUserIdAndStatusPath = '/api/Order/{userId}/{status}';
  static readonly OrderGet2Path = '/api/Order/{id}';
  static readonly OrderRedeemCodePath = '/api/Order/{userId}/redeemcode';
  static readonly OrderCreateRecurringOrderPath = '/api/Order/recurring';
  static readonly OrderCompletePath = '/api/Order/{orderId}/complete';
  static readonly OrderDeletePath = '/api/Order/{id}/{deletedReason}';
  static readonly OrderDeleteOrderLinePath = '/api/Order/orderline/{id}';
  static readonly OrderResendOrderReceiptPath = '/api/Order/{orderId}/resendReceipt';
  static readonly OrderGenerateOrderPdfPath = '/api/Order/{orderId}/generatepdf';
  static readonly OrderCalculateOrderPricePath = '/api/Order/calculateprice';
  static readonly OrderGetConnectedOrdersPath = '/api/Order/connected/{userId}';
  static readonly OrderGenerateQRCodePath = '/api/Order/qr/{userId}/{orderLineId}';
  static readonly OrderVerifyQRCodePath = '/api/Order/qr/verify/{data}';
  static readonly OrderGetActiveOrderLinesPath = '/api/Order/activeorderlines/{onlyFromDate}';
  static readonly OrderGetOrderLinesForCurrentUserPath = '/api/Order/user/orderlines';
  static readonly OrderGetSubscriptionsForCurrentUserPath = '/api/Order/user/activesubscriptions';
  static readonly OrderGetFirstOrderDatePath = '/api/Order/firstorder/createddate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Register NfcUid to OrderLine
   * @param params The `OrderService.OrderAddNfcUidToOrderLineParams` containing the following parameters:
   *
   * - `orderLineId`:
   *
   * - `nfcUid`:
   */
  OrderAddNfcUidToOrderLineResponse(params: OrderService.OrderAddNfcUidToOrderLineParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orderLineId != null) __params = __params.set('orderLineId', params.orderLineId.toString());
    if (params.nfcUid != null) __params = __params.set('nfcUid', params.nfcUid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Order/AddNfcUidToOrderLine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Register NfcUid to OrderLine
   * @param params The `OrderService.OrderAddNfcUidToOrderLineParams` containing the following parameters:
   *
   * - `orderLineId`:
   *
   * - `nfcUid`:
   */
  OrderAddNfcUidToOrderLine(params: OrderService.OrderAddNfcUidToOrderLineParams): __Observable<null> {
    return this.OrderAddNfcUidToOrderLineResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get orders
   * @param params The `OrderService.OrderGetParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `start`:
   *
   * - `simpleResults`:
   *
   * - `showDeletedOrders`:
   *
   * - `paymentStatus`:
   *
   * - `orderStatus`:
   *
   * - `locationId`:
   *
   * - `itemId`:
   *
   * - `excludeUsed`:
   *
   * - `end`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  OrderGetResponse(params: OrderService.OrderGetParams): __Observable<__StrictHttpResponse<PagedResultOfOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.start != null) __params = __params.set('start', params.start.toString());
    if (params.simpleResults != null) __params = __params.set('simpleResults', params.simpleResults.toString());
    if (params.showDeletedOrders != null) __params = __params.set('showDeletedOrders', params.showDeletedOrders.toString());
    if (params.paymentStatus != null) __params = __params.set('paymentStatus', params.paymentStatus.toString());
    if (params.orderStatus != null) __params = __params.set('orderStatus', params.orderStatus.toString());
    if (params.locationId != null) __params = __params.set('locationId', params.locationId.toString());
    if (params.itemId != null) __params = __params.set('itemId', params.itemId.toString());
    if (params.excludeUsed != null) __params = __params.set('excludeUsed', params.excludeUsed.toString());
    if (params.end != null) __params = __params.set('end', params.end.toString());
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfOrder>;
      })
    );
  }
  /**
   * Get orders
   * @param params The `OrderService.OrderGetParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `start`:
   *
   * - `simpleResults`:
   *
   * - `showDeletedOrders`:
   *
   * - `paymentStatus`:
   *
   * - `orderStatus`:
   *
   * - `locationId`:
   *
   * - `itemId`:
   *
   * - `excludeUsed`:
   *
   * - `end`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  OrderGet(params: OrderService.OrderGetParams): __Observable<PagedResultOfOrder> {
    return this.OrderGetResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfOrder)
    );
  }

  /**
   * Create order
   * @param params The `OrderService.OrderCreateParams` containing the following parameters:
   *
   * - `order`:
   *
   * - `completeAndCharge`: Completes the order and tries to charge
   */
  OrderCreateResponse(params: OrderService.OrderCreateParams): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.order;
    if (params.completeAndCharge != null) __params = __params.set('completeAndCharge', params.completeAndCharge.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * Create order
   * @param params The `OrderService.OrderCreateParams` containing the following parameters:
   *
   * - `order`:
   *
   * - `completeAndCharge`: Completes the order and tries to charge
   */
  OrderCreate(params: OrderService.OrderCreateParams): __Observable<Order> {
    return this.OrderCreateResponse(params).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * Update order
   * @param order undefined
   */
  OrderUpdateResponse(order: Order): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = order;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update order
   * @param order undefined
   */
  OrderUpdate(order: Order): __Observable<null> {
    return this.OrderUpdateResponse(order).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param locationId undefined
   */
  OrderScanOutCustomersResponse(locationId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Order/scanoutcustomers/${encodeURIComponent(String(locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param locationId undefined
   */
  OrderScanOutCustomers(locationId: number): __Observable<null> {
    return this.OrderScanOutCustomersResponse(locationId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `OrderService.OrderGetByLocationAndItemsParams` containing the following parameters:
   *
   * - `locationId`:
   *
   * - `itemIds`:
   *
   * - `To`:
   *
   * - `From`:
   */
  OrderGetByLocationAndItemsResponse(params: OrderService.OrderGetByLocationAndItemsParams): __Observable<__StrictHttpResponse<Array<Order>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.itemIds;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Order/booking/${encodeURIComponent(String(params.locationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Order>>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderGetByLocationAndItemsParams` containing the following parameters:
   *
   * - `locationId`:
   *
   * - `itemIds`:
   *
   * - `To`:
   *
   * - `From`:
   */
  OrderGetByLocationAndItems(params: OrderService.OrderGetByLocationAndItemsParams): __Observable<Array<Order>> {
    return this.OrderGetByLocationAndItemsResponse(params).pipe(
      __map(_r => _r.body as Array<Order>)
    );
  }

  /**
   * Get orders by userId and status
   * @param params The `OrderService.OrderGetByUserIdAndStatusParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `status`:
   *
   * - `paymentStatus`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  OrderGetByUserIdAndStatusResponse(params: OrderService.OrderGetByUserIdAndStatusParams): __Observable<__StrictHttpResponse<PagedResultOfOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.paymentStatus != null) __params = __params.set('paymentStatus', params.paymentStatus.toString());
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Order/${encodeURIComponent(String(params.userId))}/${encodeURIComponent(String(params.status))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfOrder>;
      })
    );
  }
  /**
   * Get orders by userId and status
   * @param params The `OrderService.OrderGetByUserIdAndStatusParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `status`:
   *
   * - `paymentStatus`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  OrderGetByUserIdAndStatus(params: OrderService.OrderGetByUserIdAndStatusParams): __Observable<PagedResultOfOrder> {
    return this.OrderGetByUserIdAndStatusResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfOrder)
    );
  }

  /**
   * Get order by given orderId
   * @param id undefined
   */
  OrderGet2Response(id: number): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Order/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * Get order by given orderId
   * @param id undefined
   */
  OrderGet2(id: number): __Observable<Order> {
    return this.OrderGet2Response(id).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * @param params The `OrderService.OrderRedeemCodeParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `code`:
   */
  OrderRedeemCodeResponse(params: OrderService.OrderRedeemCodeParams): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.code != null) __params = __params.set('code', params.code.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Order/${encodeURIComponent(String(params.userId))}/redeemcode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderRedeemCodeParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `code`:
   */
  OrderRedeemCode(params: OrderService.OrderRedeemCodeParams): __Observable<Order> {
    return this.OrderRedeemCodeResponse(params).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * Create recurring order
   * @param recurringOrder Object containing base order with repeat dates
   * @return List of recurring orders with indicators for failed/success
   */
  OrderCreateRecurringOrderResponse(recurringOrder: RecurringOrder): __Observable<__StrictHttpResponse<Array<RecurringOrder>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = recurringOrder;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Order/recurring`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RecurringOrder>>;
      })
    );
  }
  /**
   * Create recurring order
   * @param recurringOrder Object containing base order with repeat dates
   * @return List of recurring orders with indicators for failed/success
   */
  OrderCreateRecurringOrder(recurringOrder: RecurringOrder): __Observable<Array<RecurringOrder>> {
    return this.OrderCreateRecurringOrderResponse(recurringOrder).pipe(
      __map(_r => _r.body as Array<RecurringOrder>)
    );
  }

  /**
   * Complete order
   * @param orderId undefined
   */
  OrderCompleteResponse(orderId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Order/${encodeURIComponent(String(orderId))}/complete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Complete order
   * @param orderId undefined
   */
  OrderComplete(orderId: number): __Observable<null> {
    return this.OrderCompleteResponse(orderId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete order by given orderId
   * @param params The `OrderService.OrderDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `deletedReason`:
   *
   * - `refundAll`:
   *
   * - `refund`:
   */
  OrderDeleteResponse(params: OrderService.OrderDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.refundAll != null) __params = __params.set('refundAll', params.refundAll.toString());
    if (params.refund != null) __params = __params.set('refund', params.refund.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Order/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.deletedReason))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete order by given orderId
   * @param params The `OrderService.OrderDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `deletedReason`:
   *
   * - `refundAll`:
   *
   * - `refund`:
   */
  OrderDelete(params: OrderService.OrderDeleteParams): __Observable<null> {
    return this.OrderDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete order by given orderId
   * @param id undefined
   */
  OrderDeleteOrderLineResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Order/orderline/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete order by given orderId
   * @param id undefined
   */
  OrderDeleteOrderLine(id: number): __Observable<null> {
    return this.OrderDeleteOrderLineResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Resend order receipt
   * @param orderId undefined
   */
  OrderResendOrderReceiptResponse(orderId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Order/${encodeURIComponent(String(orderId))}/resendReceipt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Resend order receipt
   * @param orderId undefined
   */
  OrderResendOrderReceipt(orderId: number): __Observable<null> {
    return this.OrderResendOrderReceiptResponse(orderId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Generate order receipt pdf
   * @param orderId undefined
   */
  OrderGenerateOrderPdfResponse(orderId: number): __Observable<__StrictHttpResponse<Stream>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Order/${encodeURIComponent(String(orderId))}/generatepdf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Stream>;
      })
    );
  }
  /**
   * Generate order receipt pdf
   * @param orderId undefined
   */
  OrderGenerateOrderPdf(orderId: number): __Observable<Stream> {
    return this.OrderGenerateOrderPdfResponse(orderId).pipe(
      __map(_r => _r.body as Stream)
    );
  }

  /**
   * @param orderLines undefined
   */
  OrderCalculateOrderPriceResponse(orderLines: {[key: string]: OrderLine}): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = orderLines;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Order/calculateprice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * @param orderLines undefined
   */
  OrderCalculateOrderPrice(orderLines: {[key: string]: OrderLine}): __Observable<Order> {
    return this.OrderCalculateOrderPriceResponse(orderLines).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * Get orders which user has access to through user groups.
   * @param params The `OrderService.OrderGetConnectedOrdersParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  OrderGetConnectedOrdersResponse(params: OrderService.OrderGetConnectedOrdersParams): __Observable<__StrictHttpResponse<PagedResultOfOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Order/connected/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfOrder>;
      })
    );
  }
  /**
   * Get orders which user has access to through user groups.
   * @param params The `OrderService.OrderGetConnectedOrdersParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  OrderGetConnectedOrders(params: OrderService.OrderGetConnectedOrdersParams): __Observable<PagedResultOfOrder> {
    return this.OrderGetConnectedOrdersResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfOrder)
    );
  }

  /**
   * Generate QR Code
   * @param params The `OrderService.OrderGenerateQRCodeParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `orderLineId`:
   */
  OrderGenerateQRCodeResponse(params: OrderService.OrderGenerateQRCodeParams): __Observable<__StrictHttpResponse<Stream>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Order/qr/${encodeURIComponent(String(params.userId))}/${encodeURIComponent(String(params.orderLineId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Stream>;
      })
    );
  }
  /**
   * Generate QR Code
   * @param params The `OrderService.OrderGenerateQRCodeParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `orderLineId`:
   */
  OrderGenerateQRCode(params: OrderService.OrderGenerateQRCodeParams): __Observable<Stream> {
    return this.OrderGenerateQRCodeResponse(params).pipe(
      __map(_r => _r.body as Stream)
    );
  }

  /**
   * Reads qrdata.
   * @param data undefined
   */
  OrderVerifyQRCodeResponse(data: null | string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Order/qr/verify/${encodeURIComponent(String(data))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Reads qrdata.
   * @param data undefined
   */
  OrderVerifyQRCode(data: null | string): __Observable<Blob> {
    return this.OrderVerifyQRCodeResponse(data).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param onlyFromDate undefined
   */
  OrderGetActiveOrderLinesResponse(onlyFromDate: null | string): __Observable<__StrictHttpResponse<Array<OrderLineSimple>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Order/activeorderlines/${encodeURIComponent(String(onlyFromDate))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrderLineSimple>>;
      })
    );
  }
  /**
   * @param onlyFromDate undefined
   */
  OrderGetActiveOrderLines(onlyFromDate: null | string): __Observable<Array<OrderLineSimple>> {
    return this.OrderGetActiveOrderLinesResponse(onlyFromDate).pipe(
      __map(_r => _r.body as Array<OrderLineSimple>)
    );
  }

  /**
   * Get paged order lines for authenticated user
   * @param params The `OrderService.OrderGetOrderLinesForCurrentUserParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   *
   * @return List of user's order lines
   */
  OrderGetOrderLinesForCurrentUserResponse(params: OrderService.OrderGetOrderLinesForCurrentUserParams): __Observable<__StrictHttpResponse<PagedResultOfOrderLineSimple>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Order/user/orderlines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfOrderLineSimple>;
      })
    );
  }
  /**
   * Get paged order lines for authenticated user
   * @param params The `OrderService.OrderGetOrderLinesForCurrentUserParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   *
   * @return List of user's order lines
   */
  OrderGetOrderLinesForCurrentUser(params: OrderService.OrderGetOrderLinesForCurrentUserParams): __Observable<PagedResultOfOrderLineSimple> {
    return this.OrderGetOrderLinesForCurrentUserResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfOrderLineSimple)
    );
  }

  /**
   * Get active subscriptions of authenticated user
   * @return List of user's recurring orders
   */
  OrderGetSubscriptionsForCurrentUserResponse(): __Observable<__StrictHttpResponse<Array<Order>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Order/user/activesubscriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Order>>;
      })
    );
  }
  /**
   * Get active subscriptions of authenticated user
   * @return List of user's recurring orders
   */
  OrderGetSubscriptionsForCurrentUser(): __Observable<Array<Order>> {
    return this.OrderGetSubscriptionsForCurrentUserResponse().pipe(
      __map(_r => _r.body as Array<Order>)
    );
  }

  /**
   * Get first order date
   */
  OrderGetFirstOrderDateResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Order/firstorder/createddate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Get first order date
   */
  OrderGetFirstOrderDate(): __Observable<string> {
    return this.OrderGetFirstOrderDateResponse().pipe(
      __map(_r => _r.body as string)
    );
  }
}

module OrderService {

  /**
   * Parameters for OrderAddNfcUidToOrderLine
   */
  export interface OrderAddNfcUidToOrderLineParams {
    orderLineId?: number;
    nfcUid?: null | string;
  }

  /**
   * Parameters for OrderGet
   */
  export interface OrderGetParams {
    userId?: number;
    start?: null | string;
    simpleResults?: boolean;
    showDeletedOrders?: boolean;
    paymentStatus?: any;
    orderStatus?: any;
    locationId?: null | number;
    itemId?: null | number;
    excludeUsed?: boolean;
    end?: null | string;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for OrderCreate
   */
  export interface OrderCreateParams {
    order: Order;

    /**
     * Completes the order and tries to charge
     */
    completeAndCharge?: boolean;
  }

  /**
   * Parameters for OrderGetByLocationAndItems
   */
  export interface OrderGetByLocationAndItemsParams {
    locationId: number;
    itemIds: {[key: string]: number};
    To?: null | string;
    From?: string;
  }

  /**
   * Parameters for OrderGetByUserIdAndStatus
   */
  export interface OrderGetByUserIdAndStatusParams {
    userId: number;
    status: null | number;
    paymentStatus?: null | 0 | 1 | 2 | 3 | 4 | 5;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for OrderRedeemCode
   */
  export interface OrderRedeemCodeParams {
    userId: number;
    code?: null | string;
  }

  /**
   * Parameters for OrderDelete
   */
  export interface OrderDeleteParams {
    id: number;
    deletedReason: null | string;
    refundAll?: boolean;
    refund?: boolean;
  }

  /**
   * Parameters for OrderGetConnectedOrders
   */
  export interface OrderGetConnectedOrdersParams {
    userId: number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for OrderGenerateQRCode
   */
  export interface OrderGenerateQRCodeParams {
    userId: number;
    orderLineId: number;
  }

  /**
   * Parameters for OrderGetOrderLinesForCurrentUser
   */
  export interface OrderGetOrderLinesForCurrentUserParams {
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }
}

export { OrderService }
