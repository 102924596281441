import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'services/auth.service';
import { AlertService } from 'services/alert.service';
import { UserService } from 'api/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './activateaccount.component.html',
  styleUrls: ['./activateaccount.component.css']
})
export class ActivateAccountComponent implements OnInit {
  hidePassword = true;
  showLoadingIcon = false;
  activationForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    public translateService: TranslateService,
    private userService: UserService
  ) {
    this.createForm();
  }

  ngOnInit() {
    const userToken = this.authService.getToken();

    if (userToken) {
      this.router.navigate(['/']);
    }
  }

  createForm() {
    this.activationForm = this.fb.group({
      emailOrMobile: ['', [Validators.required, Validators.minLength(4)]],
      activationCode: ['', [Validators.required, Validators.minLength(4)]],
      companyId: 10
    });
  }

  submit() {
    if (!this.activationForm.valid) {
      return;
    }

    this.showLoadingIcon = true;

    this.userService
      .UserActivateAccount({
        emailOrMobile: this.activationForm.value.emailOrMobile,
        activationCode: this.activationForm.value.activationCode.trim()
      })
      .subscribe(
        result => {
          this.alertService.clear();
          this.showLoadingIcon = false;

          this.translateService.get('success_account_activated').subscribe(mess => {
             this.alertService.success(mess, 3000);
          });

          this.login();
        },
        error => {
          const errorAlertMessage = this.authService.getErrorTranslationObject(
            error.error.type
          );
          if (errorAlertMessage) {
            this.translateService.get(error.error.type).subscribe(message=>{
              this.alertService.error(message);
            });

          }

          this.showLoadingIcon = false;
        }
      );
  }

  login() {
    this.router.navigate(['/login']);
  }
}
