import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GlobalConfigService {
  private _apiBaseUrl: string = null;
  private _googleApiKey: string = null;
  private _webVersion: string = null;
  private _stripePublicKey: string = null;
  private _items: string = null;
  private _christmasGiftCardId = 0;
  private _childrenBirthday = null;
  private _christmasGiftCardSingle = 0;
  public loadedOk = false;

  public get apiBaseUrl() {
    if (this._apiBaseUrl == null) {
      throw new Error('Api base not set, cannot continue.');
    }

    return this._apiBaseUrl;
  }
  public get items() {
    if (this._items == null) {
      throw new Error('Items not set, cannot continue.');
    }
    return this._items;
  }

  public get christmasGiftCardId() {
    if (this._christmasGiftCardId == null) {
      throw new Error('christmasGiftCardId not set, cannot continue.');
    }
    return this._christmasGiftCardId;
  }
  public get christmasGiftCardSingle() {
    if (this._christmasGiftCardSingle == null) {
      throw new Error('christmasGiftCardId not set, cannot continue.');
    }
    return this._christmasGiftCardSingle;
  }

  public get childrenBirtday() {
    if (this._childrenBirthday == null) {
      throw new Error('Children birthday not set, cannot continue.');
    }
    return this._childrenBirthday;
  }

  public get googleApiKey() {
    if (this._googleApiKey == null) {
      throw new Error('google keynot set, cannot continue.');
    }

    return this._googleApiKey;
  }

  public get webVersion() {
    if (this._webVersion == null) {
      throw new Error('webVersion not set, cannot continue.');
    }

    return this._webVersion;
  }

  public get stripePublicKey() {
    if (this._stripePublicKey == null) {
      throw new Error('stripePublicKey not set, cannot continue.');
    }
    return this._stripePublicKey;
  }

  constructor(private http: HttpClient) {}

  load(): Promise<GlobalConfigService> {
    return this.http
      .get('webClient.config.json')
      .pipe(
        map((v: any) => {
          this._apiBaseUrl = v.apiBaseUrl;
          this._googleApiKey = v.googleApiKey;
          this._webVersion = v.webVersion;
          this._stripePublicKey = v.stripePublicKey;
          this._christmasGiftCardId = v.christmasGiftCardId;
          this.loadedOk = true;
          this._items = v.items;
          this._childrenBirthday = v.childrenBirthday;
          this._christmasGiftCardSingle = v.christmasGiftCardSingle;
          return this;
        })
      )
      .toPromise();
  }
}
