import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'appPlainText' })
export class PlainTextPipe implements PipeTransform {
  constructor() { }

  transform(value: any): string {
       return value.toString().replace(/<\/?[^>]+(>|$)/g, '');
  }
}
