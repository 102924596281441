import { PipeTransform, Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({ name: 'appDate' })
export class AppDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) { }

  transform(value: any, type: string): string | null {
    if (value) {
      value = moment.utc(value).local().format();
      if (type && type === 'short') {
        return this.datePipe.transform(value, 'dd.MM.yyyy', null, 'no');
      }
      else if (type && type == 'time') {
        return this.datePipe.transform(value, 'HH:mm:ss', null, 'no');
      } 
      else if (type && type == 'monthFirst') {
        return this.datePipe.transform(value, 'MMM dd,yyyy', null, 'en');
      } else {
        return this.datePipe.transform(value, 'dd.MM.yyyy HH:mm:ss', null, 'no');
      }
    }
  }
}
