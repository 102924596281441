import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { AuthService } from './services/auth.service';
import { BaseComponent } from './components/shared/base.component';
import { DialogService } from './shared/dialog.service';


@Injectable()
export class SecureComponent  {
  loginUrl = '/login';

  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const tokenData = this.authService.getTokenData();
    if (tokenData && !this.authService.isTokenExpired()) {
      return true;
    }
    this.router.navigate([this.loginUrl],{queryParams: {returnUrl: state.url}});
    return false;
  }
}

@Injectable()
export class DeactivateGuard  {
  constructor(private dialogService: DialogService) {}

  canDeactivate(component: BaseComponent) {
    const canDeactivate = component.canDeactivate();
    if (!canDeactivate) {
      return new Observable((ob: Observer<boolean>) => {
        this.dialogService
          .confirm(
            'Active changes',
            'You got some unsaved changes, are you sure that you want to leave this page ?'
          )
          .subscribe(
            result => {
              ob.next(result);
              ob.complete();
            },
            err => {
              ob.next(false);
              ob.complete();
            }
          );
      });
    } else {
      return true;
    }
  }
}
