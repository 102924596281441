/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Source } from '../models/source';
import { PaymentStatusResponse } from '../models/payment-status-response';
import { PaymentResponse } from '../models/payment-response';
import { OnetimeCheckout } from '../models/onetime-checkout';
import { PaymentIntent } from '../models/payment-intent';
import { StripeAuthentication } from '../models/stripe-authentication';
import { CreditCard } from '../models/credit-card';
@Injectable({
  providedIn: 'root',
})
class PaymentStripeService extends __BaseService {
  static readonly PaymentStripeGetSourcesPath = '/api/PaymentStripe/{userId}/sources';
  static readonly PaymentStripeAddSourcePath = '/api/PaymentStripe/{userId}/sources';
  static readonly PaymentStripeDefaultSourcePath = '/api/PaymentStripe/{userId}/sources/{sourceId}/default';
  static readonly PaymentStripeRemoveSourcePath = '/api/PaymentStripe/{userId}/sources/{sourceId}';
  static readonly PaymentStripeCancelSubscriptionPath = '/api/PaymentStripe/{userId}/subscriptions/cancel';
  static readonly PaymentStripeStripeCallbackPath = '/api/PaymentStripe/{providerId}/stripecallback';
  static readonly PaymentStripeGetPaymentStatusPath = '/api/PaymentStripe/paymentStatus';
  static readonly PaymentStripeCreatePaymentRequestPath = '/api/PaymentStripe/createPaymentRequest';
  static readonly PaymentStripeAddPaymentIntentPath = '/api/PaymentStripe/{userId}/paymentintent/{amount}';
  static readonly PaymentStripeAddSetupIntentPath = '/api/PaymentStripe/{userId}/setupintent';
  static readonly PaymentStripeGetCreditCardsPath = '/api/PaymentStripe/{userId}/creditcards';
  static readonly PaymentStripeDeleteCreditCardPath = '/api/PaymentStripe/{userId}/creditcards/{cardId}';
  static readonly PaymentStripeSetDefaultCreditCardPath = '/api/PaymentStripe/{userId}/creditcards/{sourceId}/default';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get payment sources for given user
   * @param userId UserId
   */
  PaymentStripeGetSourcesResponse(userId: number): __Observable<__StrictHttpResponse<Array<Source>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PaymentStripe/${encodeURIComponent(String(userId))}/sources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Source>>;
      })
    );
  }
  /**
   * Get payment sources for given user
   * @param userId UserId
   */
  PaymentStripeGetSources(userId: number): __Observable<Array<Source>> {
    return this.PaymentStripeGetSourcesResponse(userId).pipe(
      __map(_r => _r.body as Array<Source>)
    );
  }

  /**
   * Add payment source to given user
   * @param params The `PaymentStripeService.PaymentStripeAddSourceParams` containing the following parameters:
   *
   * - `userId`: UserId
   *
   * - `sourceOrtoken`: Client generated stripe source or token
   */
  PaymentStripeAddSourceResponse(params: PaymentStripeService.PaymentStripeAddSourceParams): __Observable<__StrictHttpResponse<Array<Source>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.sourceOrtoken;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PaymentStripe/${encodeURIComponent(String(params.userId))}/sources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Source>>;
      })
    );
  }
  /**
   * Add payment source to given user
   * @param params The `PaymentStripeService.PaymentStripeAddSourceParams` containing the following parameters:
   *
   * - `userId`: UserId
   *
   * - `sourceOrtoken`: Client generated stripe source or token
   */
  PaymentStripeAddSource(params: PaymentStripeService.PaymentStripeAddSourceParams): __Observable<Array<Source>> {
    return this.PaymentStripeAddSourceResponse(params).pipe(
      __map(_r => _r.body as Array<Source>)
    );
  }

  /**
   * Set default source to given user
   * @param params The `PaymentStripeService.PaymentStripeDefaultSourceParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `sourceId`:
   */
  PaymentStripeDefaultSourceResponse(params: PaymentStripeService.PaymentStripeDefaultSourceParams): __Observable<__StrictHttpResponse<Array<Source>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PaymentStripe/${encodeURIComponent(String(params.userId))}/sources/${encodeURIComponent(String(params.sourceId))}/default`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Source>>;
      })
    );
  }
  /**
   * Set default source to given user
   * @param params The `PaymentStripeService.PaymentStripeDefaultSourceParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `sourceId`:
   */
  PaymentStripeDefaultSource(params: PaymentStripeService.PaymentStripeDefaultSourceParams): __Observable<Array<Source>> {
    return this.PaymentStripeDefaultSourceResponse(params).pipe(
      __map(_r => _r.body as Array<Source>)
    );
  }

  /**
   * Remove payment source
   * @param params The `PaymentStripeService.PaymentStripeRemoveSourceParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `sourceId`:
   */
  PaymentStripeRemoveSourceResponse(params: PaymentStripeService.PaymentStripeRemoveSourceParams): __Observable<__StrictHttpResponse<Array<Source>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/PaymentStripe/${encodeURIComponent(String(params.userId))}/sources/${encodeURIComponent(String(params.sourceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Source>>;
      })
    );
  }
  /**
   * Remove payment source
   * @param params The `PaymentStripeService.PaymentStripeRemoveSourceParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `sourceId`:
   */
  PaymentStripeRemoveSource(params: PaymentStripeService.PaymentStripeRemoveSourceParams): __Observable<Array<Source>> {
    return this.PaymentStripeRemoveSourceResponse(params).pipe(
      __map(_r => _r.body as Array<Source>)
    );
  }

  /**
   * @param params The `PaymentStripeService.PaymentStripeCancelSubscriptionParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `orderLineId`:
   */
  PaymentStripeCancelSubscriptionResponse(params: PaymentStripeService.PaymentStripeCancelSubscriptionParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.orderLineId != null) __params = __params.set('orderLineId', params.orderLineId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PaymentStripe/${encodeURIComponent(String(params.userId))}/subscriptions/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `PaymentStripeService.PaymentStripeCancelSubscriptionParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `orderLineId`:
   */
  PaymentStripeCancelSubscription(params: PaymentStripeService.PaymentStripeCancelSubscriptionParams): __Observable<boolean> {
    return this.PaymentStripeCancelSubscriptionResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Stripe webhook (from stripe only)
   * @param providerId undefined
   * @return Ok if success
   */
  PaymentStripeStripeCallbackResponse(providerId: number): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PaymentStripe/${encodeURIComponent(String(providerId))}/stripecallback`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Stripe webhook (from stripe only)
   * @param providerId undefined
   * @return Ok if success
   */
  PaymentStripeStripeCallback(providerId: number): __Observable<Blob> {
    return this.PaymentStripeStripeCallbackResponse(providerId).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Get payment status from reference
   * @param paymentReference payment reference
   * @return status and order
   */
  PaymentStripeGetPaymentStatusResponse(paymentReference?: null | string): __Observable<__StrictHttpResponse<PaymentStatusResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (paymentReference != null) __params = __params.set('paymentReference', paymentReference.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PaymentStripe/paymentStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentStatusResponse>;
      })
    );
  }
  /**
   * Get payment status from reference
   * @param paymentReference payment reference
   * @return status and order
   */
  PaymentStripeGetPaymentStatus(paymentReference?: null | string): __Observable<PaymentStatusResponse> {
    return this.PaymentStripeGetPaymentStatusResponse(paymentReference).pipe(
      __map(_r => _r.body as PaymentStatusResponse)
    );
  }

  /**
   * Create payment request for onetime payments
   * @param onetimeCheckout Items and quantity to pay for
   * @return Stripe payment request
   */
  PaymentStripeCreatePaymentRequestResponse(onetimeCheckout: OnetimeCheckout): __Observable<__StrictHttpResponse<PaymentResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = onetimeCheckout;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PaymentStripe/createPaymentRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentResponse>;
      })
    );
  }
  /**
   * Create payment request for onetime payments
   * @param onetimeCheckout Items and quantity to pay for
   * @return Stripe payment request
   */
  PaymentStripeCreatePaymentRequest(onetimeCheckout: OnetimeCheckout): __Observable<PaymentResponse> {
    return this.PaymentStripeCreatePaymentRequestResponse(onetimeCheckout).pipe(
      __map(_r => _r.body as PaymentResponse)
    );
  }

  /**
   * @param params The `PaymentStripeService.PaymentStripeAddPaymentIntentParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `sourceOrtoken`:
   *
   * - `amount`:
   */
  PaymentStripeAddPaymentIntentResponse(params: PaymentStripeService.PaymentStripeAddPaymentIntentParams): __Observable<__StrictHttpResponse<PaymentIntent>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.sourceOrtoken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PaymentStripe/${encodeURIComponent(String(params.userId))}/paymentintent/${encodeURIComponent(String(params.amount))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentIntent>;
      })
    );
  }
  /**
   * @param params The `PaymentStripeService.PaymentStripeAddPaymentIntentParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `sourceOrtoken`:
   *
   * - `amount`:
   */
  PaymentStripeAddPaymentIntent(params: PaymentStripeService.PaymentStripeAddPaymentIntentParams): __Observable<PaymentIntent> {
    return this.PaymentStripeAddPaymentIntentResponse(params).pipe(
      __map(_r => _r.body as PaymentIntent)
    );
  }

  /**
   * @param params The `PaymentStripeService.PaymentStripeAddSetupIntentParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `sourceOrtoken`:
   */
  PaymentStripeAddSetupIntentResponse(params: PaymentStripeService.PaymentStripeAddSetupIntentParams): __Observable<__StrictHttpResponse<StripeAuthentication>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.sourceOrtoken;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PaymentStripe/${encodeURIComponent(String(params.userId))}/setupintent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StripeAuthentication>;
      })
    );
  }
  /**
   * @param params The `PaymentStripeService.PaymentStripeAddSetupIntentParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `sourceOrtoken`:
   */
  PaymentStripeAddSetupIntent(params: PaymentStripeService.PaymentStripeAddSetupIntentParams): __Observable<StripeAuthentication> {
    return this.PaymentStripeAddSetupIntentResponse(params).pipe(
      __map(_r => _r.body as StripeAuthentication)
    );
  }

  /**
   * Get payment sources for given user
   * @param userId UserId
   */
  PaymentStripeGetCreditCardsResponse(userId: number): __Observable<__StrictHttpResponse<Array<CreditCard>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PaymentStripe/${encodeURIComponent(String(userId))}/creditcards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CreditCard>>;
      })
    );
  }
  /**
   * Get payment sources for given user
   * @param userId UserId
   */
  PaymentStripeGetCreditCards(userId: number): __Observable<Array<CreditCard>> {
    return this.PaymentStripeGetCreditCardsResponse(userId).pipe(
      __map(_r => _r.body as Array<CreditCard>)
    );
  }

  /**
   * Remove payment source
   * @param params The `PaymentStripeService.PaymentStripeDeleteCreditCardParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `cardId`:
   */
  PaymentStripeDeleteCreditCardResponse(params: PaymentStripeService.PaymentStripeDeleteCreditCardParams): __Observable<__StrictHttpResponse<Array<CreditCard>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/PaymentStripe/${encodeURIComponent(String(params.userId))}/creditcards/${encodeURIComponent(String(params.cardId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CreditCard>>;
      })
    );
  }
  /**
   * Remove payment source
   * @param params The `PaymentStripeService.PaymentStripeDeleteCreditCardParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `cardId`:
   */
  PaymentStripeDeleteCreditCard(params: PaymentStripeService.PaymentStripeDeleteCreditCardParams): __Observable<Array<CreditCard>> {
    return this.PaymentStripeDeleteCreditCardResponse(params).pipe(
      __map(_r => _r.body as Array<CreditCard>)
    );
  }

  /**
   * Set default source to given user
   * @param params The `PaymentStripeService.PaymentStripeSetDefaultCreditCardParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `sourceId`:
   */
  PaymentStripeSetDefaultCreditCardResponse(params: PaymentStripeService.PaymentStripeSetDefaultCreditCardParams): __Observable<__StrictHttpResponse<Array<CreditCard>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PaymentStripe/${encodeURIComponent(String(params.userId))}/creditcards/${encodeURIComponent(String(params.sourceId))}/default`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CreditCard>>;
      })
    );
  }
  /**
   * Set default source to given user
   * @param params The `PaymentStripeService.PaymentStripeSetDefaultCreditCardParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `sourceId`:
   */
  PaymentStripeSetDefaultCreditCard(params: PaymentStripeService.PaymentStripeSetDefaultCreditCardParams): __Observable<Array<CreditCard>> {
    return this.PaymentStripeSetDefaultCreditCardResponse(params).pipe(
      __map(_r => _r.body as Array<CreditCard>)
    );
  }
}

module PaymentStripeService {

  /**
   * Parameters for PaymentStripeAddSource
   */
  export interface PaymentStripeAddSourceParams {

    /**
     * UserId
     */
    userId: number;

    /**
     * Client generated stripe source or token
     */
    sourceOrtoken: string;
  }

  /**
   * Parameters for PaymentStripeDefaultSource
   */
  export interface PaymentStripeDefaultSourceParams {
    userId: number;
    sourceId: null | string;
  }

  /**
   * Parameters for PaymentStripeRemoveSource
   */
  export interface PaymentStripeRemoveSourceParams {
    userId: number;
    sourceId: null | string;
  }

  /**
   * Parameters for PaymentStripeCancelSubscription
   */
  export interface PaymentStripeCancelSubscriptionParams {
    userId: number;
    orderLineId?: number;
  }

  /**
   * Parameters for PaymentStripeAddPaymentIntent
   */
  export interface PaymentStripeAddPaymentIntentParams {
    userId: number;
    sourceOrtoken: string;
    amount: number;
  }

  /**
   * Parameters for PaymentStripeAddSetupIntent
   */
  export interface PaymentStripeAddSetupIntentParams {
    userId: number;
    sourceOrtoken: string;
  }

  /**
   * Parameters for PaymentStripeDeleteCreditCard
   */
  export interface PaymentStripeDeleteCreditCardParams {
    userId: number;
    cardId: null | string;
  }

  /**
   * Parameters for PaymentStripeSetDefaultCreditCard
   */
  export interface PaymentStripeSetDefaultCreditCardParams {
    userId: number;
    sourceId: null | string;
  }
}

export { PaymentStripeService }
