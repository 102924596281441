import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'services/auth.service';
import { AlertService } from 'services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hidePassword = true;
  showLoadingIcon = false;
  loginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit() {
    const userToken = this.authService.getToken();

    if (userToken) {
      this.router.navigate(['/']);
    }
  }

  createForm() {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(4)]],
      password: ['', [Validators.required, Validators.minLength(4)]],
      companyId: 10
    });
  }

  submit() {
    if (!this.loginForm.valid) {
      return;
    }

    this.authService.logout(); // Prevent bearer token from being sent.

    this.showLoadingIcon = true;

    this.authService.login(this.loginForm.value, true).subscribe(
      result => {
        this.alertService.clear();
        this.showLoadingIcon = false;

        if (result.hasAccess) {
          const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          if (returnUrl.includes('gavekort')) {
            this.router.navigate(['/gavekort']);
          } else {
            this.router.navigate(['/']);
          }

        }
      },
      error => {
        const errorAlertMessage = this.authService.getErrorTranslationObject(
          error.error.type
        );
        if (errorAlertMessage) {
          this.translateService.get(error.error.type).subscribe(message => {
            this.alertService.error(
                message
              );
          });

        }

        this.authService.logout();
        this.showLoadingIcon = false;
      }
    );
  }

  register() {
    this.router.navigate(['/register']);
  }
}
