import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { DataTableFooterTemplateDirective } from '@swimlane/ngx-datatable';

export class DatatablePageData {
    pageSize: number;
    page: number;
}

@Component({
    selector: 'datatable-commonfooter',
    templateUrl: 'datatable-commonfooter.component.html'
})
export class DatatableCommonFooterComponent {
    @ViewChild(DataTableFooterTemplateDirective, { static: true }) tmplDir: DataTableFooterTemplateDirective;

    @Input() pageSize: number;
    @Input() page: number;
    @Input() totalPages: number;
    @Input() rowCount: number;
    selectedCount = 0;

    get showPager(): boolean {
        return this.rowCount > this.pageSize;
    }

    @Output() change = new EventEmitter<DatatablePageData>();

    @Input() pageSizes: number[] = [10, 20, 50, 100];

    constructor() { }

    onPageSizeChange(size: number) {
        this.pageSize = size;
        this.page = 1;
        this.emitChange();
    }

    onPageChange(event: any) {
        this.page = event.page;
        this.emitChange();
    }

    private emitChange() {
        this.change.emit({ page: this.page, pageSize: this.pageSize });
    }
}
