import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccessLevel } from './auth.service';

@Injectable()
export class MenuService {



  public clientMenu: Menu[] = [
    {
      title: 'products',
      icon: 'add_shopping_cart',
      route: ''
    },
    {
      title: 'booking',
      icon: 'event',
      route: '/booking'
    },
    {
      title: 'childrens_birthday',
      icon: 'event',
      route: '/children-birthday'
    },
    {
      title: 'my_bookings',
      icon: 'event',
      route: '/mybookings'
    },
    {
      title: 'my_tickets',
      icon: 'view_module',
      route: '/mytickets'
    },
    {
      title: 'payments_history',
      icon: 'payment',
      route: '/payments'
    },
    {
      title: 'my_profile',
      icon: 'account_circle',
      route: '/profile'
    },
  ];

  constructor() {}
  public getAvailableClientMenus(): Array<Menu> {
    return this.clientMenu;
  }
}

export class Menu {
  icon: string;
  route: string;
  title: string;
  description?: string;
  subMenus?: Menu[];
  requiresAccessLevel?: AccessLevel;
  isCompanyEntry?: boolean;
  comingSoon?: boolean;
}
