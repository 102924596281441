<mat-dialog-content>
  <div class="add-wrapper" >
        <div class="cart-wrap">

            <div class="item-wrap">
                <div class="name-cart">
                    <p> {{'birthday_heading' | translate}}</p>




                </div>
                <p style="    display: flex;
                flex-direction: row;" class="mt-3"> {{ data? (data.start | appDate) : ''}} </p>
 <p style="    display: flex;
 flex-direction: row;" class="mt-3"> <span style="width: 50px !important;
display: flex;
flex-direction: row;" > {{'price' | translate}} </span> {{ calculatePrice() }} kr </p>
            </div>

            <div [formGroup]="saveForm" class="description-cart">

           <div style="    display: flex;
           align-items: center;">
            <mat-form-field style="width: 60% !important;"  appearance="outline">
              <mat-label>{{'number_of_children' | translate}}</mat-label>
              <input min="8" type="number" matInput formControlName="numberOfChildren">
              <span class="required-input" matSuffix>*</span>
              <mat-error *ngIf="saveForm.controls.numberOfChildren.hasError('required')">{{'number_of_children_required' | translate}}</mat-error>
              <mat-error *ngIf="saveForm.controls.numberOfChildren.hasError('min')">{{'number_of_quests' | translate}}</mat-error>

          </mat-form-field>
          <span style="margin-bottom: 30px;" >{{'3_15_years' | translate}}</span>
           </div>
            <mat-label class="mt-2">{{'menu_selection' | translate}}</mat-label>
              <div class="food-type mt-2">
                <span><input type="radio" formControlName="typeOfFood"  class="mt-2"  value="Pizza"  ><span style="    margin-top: 5px;font-weight: bold;">Pizza</span></span>

               <div *ngIf="saveForm.value.typeOfFood==='Pizza'">
                <span class="ml-2"><input type="radio" formControlName="additionalFoodInfo"  class="mt-2"  value="Skinke"  ><span style="    margin-top: 5px;">Skinke</span></span>
                <span class="ml-2"><input type="radio" formControlName="additionalFoodInfo"  class="mt-2"  value="Pepperoni"  ><span style="    margin-top: 5px;">Pepperoni</span></span>
                <span class="ml-2"><input type="radio" formControlName="additionalFoodInfo"  class="mt-2"  value="Biff"  ><span style="    margin-top: 5px;">Biff</span></span>
                <span class="ml-2"><input type="radio" formControlName="additionalFoodInfo"  class="mt-2"  value="Margaritha"  ><span style="    margin-top: 5px;">Margaritha</span></span>

               </div>
               <div class="mt-2" *ngIf="saveForm.value.additionalFoodInfo==='Skinke' || saveForm.value.additionalFoodInfo==='Pepperoni' || saveForm.value.additionalFoodInfo==='Biff' || saveForm.value.additionalFoodInfo==='Margaritha' ">
                <span class="ml-2"><input type="radio" formControlName="dressing"  class="mt-2"  value="Oppgradering: fra 2 til 4 pizzabiter pr person kr 30."  ><span style="margin-top: 5px;">Oppgradering: fra 2 til 4 pizzabiter pr person kr 30.</span></span>
               </div>
                <span><input type="radio" formControlName="typeOfFood" class="mt-2"  value="Hamburger"  ><span style="    margin-top: 5px;font-weight: bold;">Hamburger</span></span>

                <div *ngIf="saveForm.value.typeOfFood==='Hamburger'">
                  <span class="ml-2"><input type="radio" formControlName="foodInfo"  class="mt-2"  value="Ost kr 10."  ><span style="    margin-top: 5px;">Ost kr 10.</span></span>
                </div>
                <div *ngIf="saveForm.value.typeOfFood==='Hamburger'">
                  <span class="ml-2"><input type="radio" formControlName="additionalFoodInfo"  class="mt-2"  value="Oppgradering fra 70g - 100g kr 30"  ><span style="    margin-top: 5px;">Oppgradering fra 70g - 100g kr 30</span></span>
                  <div class="ml-2" *ngIf="saveForm.value.additionalFoodInfo==='Oppgradering fra 70g - 100g kr 30'">
                    <span class="ml-2"><input type="radio" formControlName="dressing"  class="mt-2"  value="Plain (kun hamburger og brød)"  ><span style="    margin-top: 5px;">Plain (kun hamburger og brød)</span></span>
                    <span class="ml-2"><input type="radio" formControlName="dressing"  class="mt-2"  value="Med dressing og alle grønnsaker"  ><span style="    margin-top: 5px;">Med dressing og alle grønnsaker</span></span>
                    <span class="ml-2"><input type="radio" formControlName="dressing"  class="mt-2"  value="Med dressing og salat"  ><span style="    margin-top: 5px;">Med dressing og salat</span></span>


                   </div>
                  <span class="ml-2"><input type="radio" formControlName="additionalFoodInfo"  class="mt-2"  value="Oppgradering fra 70g - 160g kr 50"  ><span style="    margin-top: 5px;">Oppgradering fra 70g - 160g kr 50</span></span>
                  <div class="ml-2" *ngIf="saveForm.value.additionalFoodInfo==='Oppgradering fra 70g - 160g kr 50'">
                    <span class="ml-2"><input type="radio" formControlName="dressing"  class="mt-2"  value="Plain (kun hamburger og brød)"  ><span style="    margin-top: 5px;">Plain (kun hamburger og brød)</span></span>
                    <span class="ml-2"><input type="radio" formControlName="dressing"  class="mt-2"  value="Med dressing og alle grønnsaker"  ><span style="    margin-top: 5px;">Med dressing og alle grønnsaker</span></span>
                    <span class="ml-2"><input type="radio" formControlName="dressing"  class="mt-2"  value="Med dressing og salat"  ><span style="    margin-top: 5px;">Med dressing og salat</span></span>


                   </div>

                 </div>

                <span><input type="radio" formControlName="typeOfFood" class="mt-2"  value="Pølse med pommes strips"  ><span style="    margin-top: 5px;font-weight: bold;">Pølse med pommes strips</span></span>
                <div *ngIf="saveForm.value.typeOfFood==='Pølse med pommes strips'">
                  <span class="ml-2"><input type="radio" formControlName="additionalFoodInfo"  class="mt-2"  value="Ekstra pølse kr 22"  ><span style="    margin-top: 5px;">Ekstra pølse kr 22</span></span>
                 </div>
                <span><input type="radio"  formControlName="typeOfFood" class="mt-2"  value="Pølse med potetmod"  ><span style="    margin-top: 5px;font-weight: bold;">Pølse med potetmod</span></span>
                <div *ngIf="saveForm.value.typeOfFood==='Pølse med potetmod'">
                  <span class="ml-2"><input type="radio" formControlName="additionalFoodInfo"  class="mt-2"  value="Ekstra pølse kr 22"  ><span style="    margin-top: 5px;">Ekstra pølse kr 22</span></span>
                 </div>

                 <div class="mt-2">

                  <span style="font-weight: bold;" class="mt-2" >{{'included_drinks' | translate}}</span>
                 <span style="font-weight: bold;" class="mt-2" >{{'drink_upgrade' | translate}} </span>
                  <span class="mt-2"><input type="radio" formControlName="drink"  class="mt-2"  value="0,4l brus/slush kr 5"  ><span style="    margin-top: 5px; font-weight: bold;">0,4l brus/slush kr 5</span></span>
                  <span ><input type="radio" formControlName="drink"  class="mt-2"  value="0,5l brus eller stor juice/litago kr 10"  ><span style="    margin-top: 5px; font-weight: bold;">0,5l brus eller stor juice/litago kr 10</span></span>

                 </div>
                 <div class="mt-4">
                   <span ><input type="radio" formControlName="dessert"  class="mt-2"  value="Bursdagssoftis kr 40"  ><span style="    margin-top: 5px; font-weight: bold;">Bursdagssoftis kr 40</span></span>

                 </div>
              </div>




            <mat-form-field class="mt-3"  appearance="outline">
              <mat-label >{{'allergies' | translate}}</mat-label>
              <textarea  matInput formControlName="allergies"></textarea>

          </mat-form-field>



            </div>



        </div>


        <div class="action mt-4">

          <button mat-raised-button color="secondary" (click)="close()">{{'close' | translate}}</button>
          <button style="background-color: #7b2cb5;" class="ml-3" mat-raised-button color="primary" (click)="buy()" >{{'book' | translate}}</button>


      </div>
  </div>
</mat-dialog-content>