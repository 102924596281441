<mat-card class="login-card">
    <mat-card-header>
        <mat-card-title>{{'activate_account' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'enter_activation_below' | translate}}</mat-card-subtitle>
    </mat-card-header>

        <mat-card-content>
            <form [formGroup]="activationForm" (ngSubmit)="submit()">
                <mat-form-field appearance="outline">
                    <mat-label>{{'phone_or_email' | translate}}</mat-label>
                    <input matInput formControlName="emailOrMobile">

                    <mat-error *ngIf="activationForm.controls.emailOrMobile.hasError('required') || activationForm.controls.emailOrMobile.hasError('minlength')" >{{'phone_or_email_required' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                        <mat-label>{{'activation_code' | translate}}</mat-label>
                        <input matInput formControlName="activationCode">

                        <mat-error *ngIf="activationForm.controls.activationCode.hasError('required') || activationForm.controls.activationCode.hasError('minlength')" >{{'activation_code_required' | translate}}</mat-error>
                    </mat-form-field>

                <div class="row" layout-align="start center">
                    <div class="action-buttons" flex>
                        <button mat-raised-button color="primary">{{'activate' | translate}}</button>
                        <button mat-raised-button color="secondary" (click)="login()">{{'sign_in' | translate}}</button>
                    </div>
                </div>
            </form>
        </mat-card-content>
</mat-card>
