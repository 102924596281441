<h1 mat-dialog-title>
  {{ title }}
</h1>

<mat-dialog-content>
  {{ message }}

  <div *ngIf="htmlMessage" class="push-top" [innerHTML]="htmlMessage"></div>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="button" class="dialog-action-button-no" mat-raised-button color="white" (click)="onCloseDialog(false)">{{'cancel' | translate}}</button>
  <button style="background-color:#c23277;" type="button" class="dialog-action-button-yes" mat-raised-button color="primary" (click)="onCloseDialog(true)">{{'yes' | translate}}</button>
</mat-dialog-actions>
