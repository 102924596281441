import { Component, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BasePagedComponent } from '../../shared/base-paged.component';

import { AlertService } from 'services/alert.service';
import * as moment from 'moment';
import { OrderService, PaymentService } from 'api/services';
import { Order } from 'api/models';

import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
@Component({
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent extends BasePagedComponent {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  tableData: any = {};
  items: any[] = [];
  showLoadingIcon: boolean;
  expanded = true;
  paymentStatus = ['NotPaid', 'Paid', 'Refunded'];

  constructor(
    private paymentService: PaymentService,
    private orderService: OrderService,
    alertService: AlertService,
    router: Router,
    public translateService: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {
    super(alertService, router,translateService );
  }

  ngAfterViewChecked() {
    if (this.table && this.table.rowDetail) {

      this.cdRef.detectChanges();
      this.table.recalculate();
    }
  }

  loadData() {
    const queryParams = {
      PageSize: this.currentPageSize,
      Page: this.currentPage,
      IsDescending: this.orderBy.order === 'desc',
      OrderByKey: this.orderBy.column
    };

    this.showLoadingIcon = true;

    this.paymentService.PaymentGet(queryParams).subscribe(
      data => {
      data.results = data.results.filter(x => x.status !== 0);
        this.items = (data.results && data.results.length > 0) ? data.results.filter(i => i.status !== 0) : [];
        this.tableData['currentPage'] = data.page - 1;
        this.tableData['totalItems'] = data.totalResults;
        this.tableData['totalPages'] = data.totalPages;

        this.showLoadingIcon = false;
      },
      err => {
        this.defaultErrorHandler(err);
        this.showLoadingIcon = false;
      }
    );
  }

  toggleExpandRow(row) {
    this.table.recalculate();
    if (
      row.type !== 'click' ||
      row.event.target.classList.contains('mat-icon-button') ||
      row.event.target.classList.contains('mat-icon')
    ) {
      this.table.recalculate();
      return;
    }
    this.table.rowDetail.toggleExpandRow(row.row);
    this.table.recalculate();
  }

  toggleExpandAllRows() {
    if (!this.expanded) {
      this.table.rowDetail.expandAllRows();
    } else {
      this.table.rowDetail.collapseAllRows();
    }
    this.expanded = !this.expanded;
  }

  downloadReceipt(order: Order): void {
    this.showLoadingIcon = true;

    this.orderService.OrderGenerateOrderPdf( order.id ).subscribe(
      result => {
        const orderPlaced = moment(order.placedAt);

        const fileName = orderPlaced.format('LL') + '-' + order.id + '.pdf';

        FileSaver.saveAs(result, fileName);
        // if (window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(result, fileName);
        // } else {
        //   const link = document.createElement('a');
        //   link.setAttribute('type', 'hidden');
        //   link.download = fileName;
        //   link.href = window.URL.createObjectURL(result);
        //   document.body.appendChild(link);
        //   link.click();
        // }
        this.showLoadingIcon = false;
      },
      err => {
        this.defaultErrorHandler(err);
        this.showLoadingIcon = false;
      }
    );
  }

  getName(item) {
if (item) {
  const currentLanguge = localStorage.getItem('language');
  if (currentLanguge) {
    switch (currentLanguge) {
      case 'en-GB': return item.itemName;
      case 'nb-NO': return item.properties['nb-NO_Name'] ? item.properties['nb-NO_Name'] : item.itemName ;
      default : return item.name;
    }

  } else {
     return item.properties['nb-NO_Name'] ? item.properties['nb-NO_Name'] : item.itemName;
  }
}


  }
  getPaymentStatus(status) {
    switch (status) {
      case 0:
       return "not_paid" ;
      case 1:
       return "paid"
      case 2:
        return "refunded"
    }

  }
}
