import { Component, OnInit } from '@angular/core';
import { BasePagedComponent } from '../../shared/base-paged.component';
import { AlertService } from 'services/alert.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DialogService } from 'shared/dialog.service';
import { ItemService } from 'api/services';
import { GlobalConfigService } from 'services/global-config.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent extends BasePagedComponent implements OnInit {
  showLoadingIcon: boolean;
  selectedTab = 0;

 value = 0;
 cart = [];
 cartSum = 0;
 fullCartSum = 0;

 items = [];
 giftTicket = null;
 discount = 10;
 gavekortUrl = false;

  constructor(
    private config: GlobalConfigService,
    alertService: AlertService,
    router: Router,
    public translateService: TranslateService,
    private itemService: ItemService,
    private dialogService: DialogService
  ) {
    super(alertService, router, translateService);
    if (this.router.url.includes('gavekort')) {
      this.selectedTab = 4;
      this.getItem();
      this.gavekortUrl = true;
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  loadData(type= null) {
    if (!this.gavekortUrl) {
      if (!type) {
        type = 1;
      }
      this.showLoadingIcon = true;
      const queryParams = {
        PageSize: 999,
        itemType: type,
        timeFrom: moment.utc().toISOString(),
        timeTo: moment
          .utc()
          .add(1, 'minute')
          .toISOString()
      };
      this.itemService.ItemGet(queryParams).subscribe(
        response => {

          if (this.config.christmasGiftCardId) {
            this.items = response.results.filter(x => x.id != this.config.christmasGiftCardId);
          } else {
            this.items = response.results;
          }
          
          this.showLoadingIcon = false;
        },
        error => {
           this.defaultErrorHandler(error);
          this.showLoadingIcon = false;
        }
      );
    }
  }

  getItemsByType(type) {
    if (this.items && this.items.length > 0) {
      return this.items.filter(x => x.type === type);
    }
  }
  removeFromCart(item) {
    const itemCart = this.cart.find(x => x.item.id === item.id);
    if (itemCart && itemCart.number > 0) {
      itemCart.number = itemCart.number - 1;
      if (itemCart.number === 0) {
        this.cart = this.cart.filter(x => x.item.id !== item.id);
      }
      this.cartSum = this.cartSum - (item.prices[0] ? item.prices[0].value : 0);
      this.fullCartSum = this.fullCartSum - (item.prices[0] ? item.prices[0].value : 0);
      this.checkForDiscount();
    }
  }

  addToCart(item) {
    const itemCart = this.cart.find(x => x.item.id === item.id);
    if (itemCart) {
      itemCart.number = itemCart.number + 1;
      this.cartSum = this.cartSum + (item.prices[0] ? item.prices[0].value : 0);
      this.fullCartSum = this.fullCartSum + (item.prices[0] ? item.prices[0].value : 0);
      this.checkForDiscount();
    } else {
      this.cart.push({
        item: item,
        number: 1
      });
      this.cartSum = this.cartSum + (item.prices[0] ? item.prices[0].value : 0);
      this.fullCartSum = this.fullCartSum + (item.prices[0] ? item.prices[0].value : 0);
      this.checkForDiscount();
    }
  }

  getNumberOfItems(item) {
    if (item && this.cart && this.cart.length > 0) {
      const itemCart = this.cart.find(x => x.item.id === item.id);
      if (itemCart) {
        return itemCart.number;
      } else {
        return 0;
      }
    }
    return 0;
  }

  buy() {
    const odrderLines = [];
    let monthly = false;
    this.cart.forEach(itemOrder => {
    if (itemOrder.item.type === 4) {
      monthly = true;
    }
    for (let i = 1; i <= itemOrder.number;  i++) {
      odrderLines.push({
        itemId: itemOrder.item.id,
        discount: itemOrder.discount,
        itemCount: itemOrder.item.id == this.config.christmasGiftCardId ? itemOrder.number : 1
      });
    }
    });
   if (monthly) {
     this.dialogService.confirmMonthlyBooking().subscribe(() => {
      this.dialogService.buy(this.cart, this.cartSum, odrderLines, false, this.giftTicket, true).subscribe(res => {

      });
     });
   } else {
    this.dialogService.buy(this.cart, this.cartSum, odrderLines, false, this.giftTicket, false).subscribe(res => {

    });
   }
  }

  onSelectTabChange(event) {
    this.emptyBasket();

    if (this.gavekortUrl) {
      this.gavekortUrl = false;
    }

    switch (event.index) {
      case 0: {
        this.loadData(1);
        break;
      }
      case 1: {
        this.loadData(4);
        break;
      }
      case 2: {
        this.loadData(2);
        break;
      }
      case 3: {
        this.loadData(5);
        break;
      }
      case 4: {
        this.items = [];
        this.getItem();
        break;
      }
    }
  }

  getName(item) {
    const currentLanguge = localStorage.getItem('language');
    if (currentLanguge) {
      switch (currentLanguge) {
        case 'en-GB': return item.name;
        case 'nb-NO': return (item.properties && item.properties['nb-NO_Name'] ) ? item.properties['nb-NO_Name'] : item.name ;
        default : return item.name;
      }

    } else {
       return item.properties['nb-NO_Name'] ? item.properties['nb-NO_Name'] : item.name;
    }
  }

  getItem() {

    // this.itemService.ItemGet2(this.config.christmasGiftCardId).subscribe(res => {
    //   this.items = [];
    //   this.giftTicket = res;
    //   const wetPackages = {
    //     id: res.id,
    //     name: res.name,
    //     prices: [ { value: res.prices[0] ? res.prices[0].value * 5 : 0 }]
    //   };
    //   this.items.push(wetPackages);
    //   this.getSingleGift();
    // }, err => {
    //    this.defaultErrorHandler(err);
    // });
    this.getSingleGift();
  }
  
  getSingleGift(){
    this.itemService.ItemGet2(this.config.christmasGiftCardSingle).subscribe(res => {
      const wetPackages = {
        id: res.id,
        name: res.name,
        prices: [ { value: res.prices[0] ? res.prices[0].value  : 0 }]
      };
       this.items.push(wetPackages);
    }, err => {
       this.defaultErrorHandler(err);
    });
  }

  checkForDiscount() {
    this.cartSum = 0;
   if (this.cart.some(x => x.item.type === 1 && x.item.productNumber === '101') &&
    ( this.cart.some(x => x.item.type === 1 && this.cart.some(x => x.item.productNumber === '102') )
     || this.cart.some(x => x.item.type === 1 && this.cart.some(x => x.item.productNumber === '103') )
    || this.cart.some(x => x.item.type === 1 && this.cart.some(x => x.item.productNumber === '105') )  )) {
      this.cart.forEach(itemCart => {
         if (itemCart.item.productNumber === '101' ||
         itemCart.item.productNumber === '102' ||
          itemCart.item.productNumber === '103' || itemCart.item.productNumber === '105' ) {
            itemCart.discount = true;
            this.cartSum = this.cartSum + ((itemCart.item.prices[0] ? itemCart.item.prices[0].value - 15 : 0) * itemCart.number );
         } else {
          itemCart.discount = false;
          this.cartSum = this.cartSum + ((itemCart.item.prices[0] ? itemCart.item.prices[0].value : 0) * itemCart.number );
         }
      });
   } else {
     this.cartSum = 0;
     this.cart.forEach(itemCart => {
      itemCart.discount = false;
      this.cartSum = this.cartSum + ((itemCart.item.prices[0] ? itemCart.item.prices[0].value : 0) * itemCart.number);
     });
   }
  }

  checkDiscount(item) {
     if (this.cart && this.cart.length > 0) {
       const itemCart = this.cart.find(x => x.item.id === item.id);
       if (itemCart && itemCart.discount) {
         return true;
       }
     }
     return false;
  }

  checkSumDiscount() {
    const item = this.cart.find(x => x.discount === true);
    if (item) {
      return true;
    }
    return false;
  }

  getPriceDiscount(item) {
  return item.prices[0] ? item.prices[0].value - 15 : 0;
  }

  emptyBasket() {
   this.cart = [];
   this.cartSum = 0;
   this.fullCartSum = 0;
  }
}
