import { Component, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePagedComponent } from 'components/shared/base-paged.component';


import { AuthService } from 'services/auth.service';
import { AlertService } from 'services/alert.service';
import { StripeSource } from 'stripe-angular';
import { CustomPaymentStripeService } from 'services/payment-stripe.service';

import { DialogService } from 'shared/dialog.service';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { Language, Order, PaymentOption, PhysicalAddress, User, UserRole } from 'api/models';
import { CountryService, OrderService, PaymentService, ThirdPartyProviderService, UserService } from 'api/services';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent extends BasePagedComponent implements OnInit {
  userId: Number;
  saveForm: FormGroup;
  userGenders = ['Unknown', 'Male', 'Female'];
  showLoadingIcon: boolean;
  maxDateOfBirth = new Date();
  paymentOptions: PaymentOption[] = [];
  selectedTab = 0;
  @ViewChild('stripeCard') stripeCard;
  userRoles: UserRole[] = [];
  physicalAddresses: PhysicalAddress[] = [];
  languages: Language[] = [];

  items: Order[] = [];
  recurringCancelled = {};
  tableData: any = {};
  orderStatus = [
    'Pending',
    'Ordered',
    'Completed',
    'Cancelled'
  ];
  paymentStatus = ['NotPaid', 'Paid', 'Refunded'];
  includes = 'OrderLines';
  deletePayment: string;
  youWillDeletePayment: string;
  youWillDelete: string;
  public selectedDate = null;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  expanded = false;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private authService: AuthService,
    alertService: AlertService,
    router: Router,
    public translateService: TranslateService,
    private customPaymentStripeService: CustomPaymentStripeService,
    private paymentService: PaymentService,
    private dialogService: DialogService,
    private countryService: CountryService,
    private orderService: OrderService,
    private route: ActivatedRoute
  ) {
    super(alertService, router, translateService);
    this.createForm();
    const token = this.authService.getTokenData();
    this.userId = Number(token.sub);

    this.loadData();
    this.translateService.get('delete_payment_option').subscribe(x =>
      this.deletePayment = x);
    this.translateService.get('you_will_delete_card').subscribe(x =>
      this.youWillDeletePayment = x);
      this.translateService.get('you_will_delete').subscribe(x =>
        this.youWillDelete = x);
  }

  ngOnInit() {
    const tab = this.route.snapshot.queryParamMap.get('tab');
    this.selectedTab = tab === 'payment-options' ? 1 : 0;
  }

  createForm() {
    this.saveForm = this.fb.group({
      id: this.userId,
      username: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ])
      ),
      cellPhone: '',
      firstName: new FormControl('', [
        Validators.required
      ]),
      middleName: '',
      lastName: new FormControl('', [
        Validators.required
      ]),
      dateOfBirth: '',
      gender: ['', Validators.required],
      languageId: ['', Validators.required],
      newPassword: '',
      repeatedNewPassword: '',
      companyId: this.authService.getTokenData().companyId,
      landPhone: ''
    });
  }

  loadData() {
    this.showLoadingIcon = true;

    this.userService.UserGet(Number(this.userId)).subscribe(
      data => {
        data.gender = data.gender;
        this.saveForm.patchValue(data);
        this.showLoadingIcon = false;
        this.userRoles = data.userRoles; // naknadno dodano
        this.physicalAddresses = data.physicalAddresses;
      },
      err => {
        this.defaultErrorHandler(err);
        this.showLoadingIcon = false;
      }
    );
    this.loadPaymentOptions();
    this.getLanguages();
    this.loadMyReceipts();
  }

  loadMyReceipts() {
    const queryParams = {
      PageSize: this.currentPageSize,
      Includes: this.includes,
      Page: this.currentPage,
      IsDescending: this.orderBy.order === 'desc',
      OrderByKey: this.orderBy.column,
      Query: this.searchTerm,
      start: this.selectedDate
        ? this.selectedDate.start.toISOString()
        : moment()
          .startOf('month')
          .toISOString(),
      end: this.selectedDate
        ? this.selectedDate.end.toISOString()
        : moment()
          .endOf('month')
          .toISOString(),
      simpleResults: true,
      showDeletedOrders: false
    };

    this.orderService.OrderGet(queryParams).subscribe(
      data => {
        this.items = data.results;
        this.calculateCancelledRecurring();
        this.tableData['currentPage'] = data.page - 1;
        this.tableData['totalItems'] = data.totalResults;
        this.tableData['totalPages'] = data.totalPages;
        this.showLoadingIcon = false;

      },
      err => {
        this.defaultErrorHandler(err);
        this.showLoadingIcon = false;
      }
    );
  }

  calculateCancelledRecurring() {
    this.items.map(o => {
      let recurringCount = 0;
      o.orderLines.map(ol => {
        if (ol.cancelRecurring) {
          recurringCount++;
        }
      });
      this.recurringCancelled[o.id] = recurringCount;
    });
  }

  downloadReceipt(order: Order): void {
    this.showLoadingIcon = true;

    this.orderService.OrderGenerateOrderPdf(order.id ).subscribe(
      result => {
        const orderPlaced = moment(order.placedAt);

        const fileName = orderPlaced.format('LL') + '-' + order.id + '.pdf';
        FileSaver.saveAs(result, fileName);
        // if (window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(result, fileName);
        // } else {
        //   const link = document.createElement('a');
        //   link.setAttribute('type', 'hidden');
        //   link.download = fileName;
        //   link.href = window.URL.createObjectURL(result);
        //   document.body.appendChild(link);
        //   link.click();
        // }
        this.showLoadingIcon = false;
      },
      err => {
        this.defaultErrorHandler(err);
        this.showLoadingIcon = false;
      }
    );
  }

  toggleExpandRow(row) {
    if (row.type !== 'click') {
      return;
    }
    this.table.rowDetail.toggleExpandRow(row.row);
  }

  toggleExpandAllRows() {
    if (!this.expanded) {
      this.table.rowDetail.expandAllRows();
    } else {
      this.table.rowDetail.collapseAllRows();
    }
    this.expanded = !this.expanded;
  }



  loadPaymentOptions() {
    this.showLoadingIcon = true;

    this.paymentService
      .PaymentGetPaymentOptions({ userId: Number(this.userId), PageSize: 100 })
      .subscribe(
        response => {
          this.paymentOptions = response.results;
          this.showLoadingIcon = false;
        },
        error => {
          this.showLoadingIcon = false;
          this.defaultErrorHandler(error);
        }
      );
  }

  getLanguages() {
    const queryParams = {
      PageSize: this.currentPageSize,
      Page: this.currentPage,
      IsDescending: false,
      OrderByKey: this.orderBy.column
    };

    this.countryService.CountryGetLanguages(queryParams).subscribe(
      data => {
        this.languages = data.results;
      },
      err => this.defaultErrorHandler(err)
    );

  }

  onSubmit() {
    if (!this.saveForm.valid) {
      this.showErrorMessages(this.saveForm);
      return;
    }

    const newPassword = this.saveForm.get('newPassword');
    const repeatedNewPassword = this.saveForm.get('repeatedNewPassword');

    if (newPassword.value) {
      if (newPassword.value.length < 4) {
        this.translateService.get('new_password_4_chars').subscribe(mess => {
          this.alertService.error(mess, 3000);
        });
        newPassword.setErrors([]);
        return;
      }

      if (newPassword.value !== repeatedNewPassword.value) {

        this.translateService.get('repeated_password_doesnt_match').subscribe(mess => {
          this.alertService.error(mess, 3000);
        });
        repeatedNewPassword.setErrors([]);
        return;
      }
    }

    this.showLoadingIcon = true;

    const user = this.saveForm.value as User;

    // make sure the new password isn't a null value
     if (!user.newPassword) {
      user.newPassword = '';
    }

    user.userRoles = this.userRoles;

    user.physicalAddresses = this.physicalAddresses;

    this.userService.UserUpdate(user).subscribe(response => {
      this.translateService.get('sucess_user').subscribe(mess => {
        this.alertService.success(mess, 3000);
      });

      this.saveForm.patchValue({
        newPassword: '',
        repeatedNewPassword: ''
      });

      this.showLoadingIcon = false;
    });
  }

  addCard() {
    this.stripeCard.createToken({});
  }

  onStripeInvalid(error: Error) {
    this.showLoadingIcon = false;
  }

  setStripeToken(token) {
    this.showLoadingIcon = true;

    this.customPaymentStripeService
      .byUserIdSourcesPost({
        userId: Number(this.userId),
        sourceOrtoken: token.id
      })
      .subscribe(
        () => {
          this.showLoadingIcon = false;
          this.loadPaymentOptions();

          // @ts-ignore
          this.stripeCard.elements.clear();
        },
        error => {
          this.defaultErrorHandler(error);
          this.showLoadingIcon = false;
        }
      );
  }

  setStripeSource(source: StripeSource) {
    this.showLoadingIcon = false;
  }

  onStripeError(error: Error) {
    this.showLoadingIcon = false;
  }

  deletePaymentOption(paymentOption: PaymentOption) {
    const message = this.youWillDelete + '<b>' + paymentOption.name + '.</b> <br/>' + this.youWillDeletePayment;
    this.dialogService
      .confirm(this.deletePayment, null, message)
      .subscribe(confirmed => {
        if (confirmed) {
          this.showLoadingIcon = true;

          this.paymentService
            .PaymentDelete({
              userId: Number(this.userId),
              id: paymentOption.id
            })
            .subscribe(
              () => {
                this.showLoadingIcon = false;
                this.paymentOptions = this.paymentOptions.filter(
                  x => x.id !== paymentOption.id
                );
                this.translateService.get('success_removed_payment_option').subscribe(mess => {
                  this.alertService.success(mess, 3000);
                });
              },
              error => {
                this.defaultErrorHandler(error);
                this.showLoadingIcon = false;
              }
            );
        }
      });
  }

  setDefaultPaymentOption(paymentOption: PaymentOption) {
    this.paymentService
      .PaymentMarkAsDefault({ userId: Number(this.userId), id: paymentOption.id })
      .subscribe(
        response => {
          this.loadPaymentOptions();
        },
        error => {
          this.defaultErrorHandler(error);
        }
      );
  }
  onSelectTabChange(event) {
    this.selectedTab = event.index;
  }

  getName(item) {
    if (item) {
      const currentLanguge = localStorage.getItem('language');
      if (currentLanguge) {
        switch (currentLanguge) {
          case 'en-GB': return item.itemName;
          case 'nb-NO': return item.properties['nb-NO_Name'] ? item.properties['nb-NO_Name'] : item.itemName ;
          default : return item.name;
        }

      } else {
         return item.properties['nb-NO_Name'] ? item.properties['nb-NO_Name'] : item.itemName;
      }
    }


      }

      getPaymentStatus(status) {
        switch (status) {
          case 0:
          return 'not_paid';
          case 1:
          return 'paid';
          case 2:
            return 'refunded';
        }

      }

      getOrderStatus(status) {
        switch (status) {
          case 0:
            return 'pending';
          case 1:
            return 'ordered';
          case 2:
             return 'completed';
          case 3:
            return 'canceled';
          default: return '';
        }

      }
}
