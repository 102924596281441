/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedResultOfChangeSet } from '../models/paged-result-of-change-set';
@Injectable({
  providedIn: 'root',
})
class LogReaderService extends __BaseService {
  static readonly LogReaderGetLogOfThingsUserHasDonePath = '/api/LogReader/GetLogOfThingsUserHasDone';
  static readonly LogReaderGetChangeLogForPath = '/api/LogReader/GetChangeLogFor';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get changelog for given userId (pagedQuery.query)
   * @param params The `LogReaderService.LogReaderGetLogOfThingsUserHasDoneParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  LogReaderGetLogOfThingsUserHasDoneResponse(params: LogReaderService.LogReaderGetLogOfThingsUserHasDoneParams): __Observable<__StrictHttpResponse<PagedResultOfChangeSet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LogReader/GetLogOfThingsUserHasDone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfChangeSet>;
      })
    );
  }
  /**
   * Get changelog for given userId (pagedQuery.query)
   * @param params The `LogReaderService.LogReaderGetLogOfThingsUserHasDoneParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  LogReaderGetLogOfThingsUserHasDone(params: LogReaderService.LogReaderGetLogOfThingsUserHasDoneParams): __Observable<PagedResultOfChangeSet> {
    return this.LogReaderGetLogOfThingsUserHasDoneResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfChangeSet)
    );
  }

  /**
   * Get change log for primaryElementKey (pagedQuery.Query)
   * @param params The `LogReaderService.LogReaderGetChangeLogForParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  LogReaderGetChangeLogForResponse(params: LogReaderService.LogReaderGetChangeLogForParams): __Observable<__StrictHttpResponse<PagedResultOfChangeSet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LogReader/GetChangeLogFor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfChangeSet>;
      })
    );
  }
  /**
   * Get change log for primaryElementKey (pagedQuery.Query)
   * @param params The `LogReaderService.LogReaderGetChangeLogForParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  LogReaderGetChangeLogFor(params: LogReaderService.LogReaderGetChangeLogForParams): __Observable<PagedResultOfChangeSet> {
    return this.LogReaderGetChangeLogForResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfChangeSet)
    );
  }
}

module LogReaderService {

  /**
   * Parameters for LogReaderGetLogOfThingsUserHasDone
   */
  export interface LogReaderGetLogOfThingsUserHasDoneParams {
    UpdatedAfter?: null | string;
    Query?: number;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for LogReaderGetChangeLogFor
   */
  export interface LogReaderGetChangeLogForParams {
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }
}

export { LogReaderService }
