import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.getToken();

    if (token) {
      const language = localStorage.getItem('language') || 'nb-NO';

      request = request.clone({
        headers: request.headers
          .set('Authorization', 'Bearer ' + token.token)
          .set('companyId', token.companyId + '')
          .set('Accept-Language', language)
      });
    } else {
      const language = localStorage.getItem('language') || 'nb-NO';

      request = request.clone({
        headers: request.headers
          .set('companyId', 10 + '')
          .set('Accept-Language', language)
      });
    }

    return next.handle(request);
  }

  getToken(): any {
    const tokenData = localStorage.getItem('tokenData');
    if (tokenData) {
      return JSON.parse(tokenData);
    } else {
      return null;
    }
  }
}
