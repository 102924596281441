import { Injectable } from '@angular/core';
@Injectable()
export class ColorService {
  public colors: any[] = [
    {
      name: 'Blue',
      primary: '#1e90ff',
      secondary: '#1e90ff'
    },
    {
      name: 'Cyanid',
      primary: '#003f5c',
      secondary: '#003f5c'
    },
    {
      name: 'Purple',
      primary: '#66568a',
      secondary: '#66568a'
    },
    {
      name: 'Pink',
      primary: '#c5678d',
      secondary: '#c5678d'
    },
    {
      name: 'Havelock Blue',
      primary: '#5173bf',
      secondary: '#5173bf'
    },
    {
      name: 'Red',
      primary: '#a1001f',
      secondary: '#a1001f'
    },
    {
      name: 'Purple Blue',
      primary: '#313057',
      secondary: '#313057'
    },
    {
      name: 'Horizon Blue',
      primary: '#63848e',
      secondary: '#63848e'
    },
    {
      name: 'Gray24',
      primary: '#3d3d3d',
      secondary: '#3d3d3d'
    },
    {
      name: 'Deep Fir Green',
      primary: '#064014',
      secondary: '#064014'
    },
    {
      name: 'Orange',
      primary: '#bf6e0b',
      secondary: '#bf6e0b'
    },
    {
      name: 'Cyan Teal',
      primary: '#008080',
      secondary: '#008080'
    },
    {
      name: 'Salmon orange',
      primary: '#FA8072',
      secondary: '#FA8072'
    },
    {
      name: 'Black',
      primary: '#101010',
      secondary: '#101010'
    },
    {
      name: 'Pastel green',
      primary: '#61ED7A',
      secondary: '#61ED7A'
    },
    {
      name: 'Turquoise Blue',
      primary: '#61C9ED',
      secondary: '#61C9ED'
    },
    {
      name: 'Lavender',
      primary: '#C161ED',
      secondary: '#C161ED'
    },
    {
      name: 'Olive Green',
      primary: '#708238',
      secondary: '#708238'
    },
    {
      name: 'Indigo Blue',
      primary: '#545CCF',
      secondary: '#545CCF'
    },
    {
      name: 'Vivid Violet',
      primary: '#6F3A92',
      secondary: '#6F3A92'
    },
    {
      name: 'Luxor Gold',
      primary: '#AC7F2F',
      secondary: '#AC7F2F'
    },
    {
      name: 'Brown',
      primary: '#6d4c41',
      secondary: '#6d4c41'
    },
    {
      name: 'Ruby Pink',
      primary: '#E0115F',
      secondary: '#E0115F'
    },
    {
      name: 'Blue Gray',
      primary: '#546e7a',
      secondary: '#546e7a'
    }
  ];

  public getColors() {
    return this.colors.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
}
