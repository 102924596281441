import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'services/auth.service';
import { AlertService } from 'services/alert.service';
import { UserService } from 'api/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  hidePassword = true;
  showLoadingIcon = false;
  registerForm: FormGroup;
  activationChoice: 'Email' | 'SMS' = 'Email';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    this.createForm();
  }

  ngOnInit() {
    const userToken = this.authService.getToken();

    if (userToken) {
      this.router.navigate(['/']);
    }
  }

  createForm() {
    this.registerForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.minLength(4)]],
      newPassword: ['', [Validators.required, Validators.minLength(4)]],
      cellPhone: ['', [Validators.required, Validators.minLength(4)]],
      userRoles: [[{ roleName: 'User' }]],
      companyId: 10,
      languageId: ['nb-NO']
    });
  }

  submit() {
    if (!this.registerForm.valid) {
      return;
    }

    this.showLoadingIcon = true;
    this.userService
      .UserCreate({
        user: this.registerForm.value,
        activationType: this.activationChoice
      }
      )
      .subscribe(
        () => {
          this.translateService.get('soon_receive_activation_code').subscribe(message => {
            this.alertService.info(message);
          });

          this.router.navigate(['/login']);
        },
        error => this.handleErrors(error)
      );
  }

  login() {
    this.router.navigate(['/login']);
  }

  handleErrors(error) {
    const errorAlertMessage = this.authService.getErrorTranslationObject(
      error.error.type
    );
    if (errorAlertMessage) {
      this.translateService.get(error.error.type).subscribe(message => {
       this.alertService.error(message);
      });

    }

    this.showLoadingIcon = false;
  }
}
