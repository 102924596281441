/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedResultOfUserGroup } from '../models/paged-result-of-user-group';
import { UserGroup } from '../models/user-group';
@Injectable({
  providedIn: 'root',
})
class UserGroupService extends __BaseService {
  static readonly UserGroupGetPath = '/api/UserGroup';
  static readonly UserGroupCreatePath = '/api/UserGroup';
  static readonly UserGroupUpdatePath = '/api/UserGroup';
  static readonly UserGroupGet2Path = '/api/UserGroup/{userGroupId}';
  static readonly UserGroupDeletePath = '/api/UserGroup/{userGroupId}';
  static readonly UserGroupGetUserGroupsByUserPath = '/api/UserGroup/foruser/{userId}';
  static readonly UserGroupAddUserToGroupPath = '/api/UserGroup/{userGroupId}/member/{userId}';
  static readonly UserGroupRemoveUserFromGroupPath = '/api/UserGroup/{userGroupId}/member/{userId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get user groups
   * @param params The `UserGroupService.UserGroupGetParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  UserGroupGetResponse(params: UserGroupService.UserGroupGetParams): __Observable<__StrictHttpResponse<PagedResultOfUserGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfUserGroup>;
      })
    );
  }
  /**
   * Get user groups
   * @param params The `UserGroupService.UserGroupGetParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  UserGroupGet(params: UserGroupService.UserGroupGetParams): __Observable<PagedResultOfUserGroup> {
    return this.UserGroupGetResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfUserGroup)
    );
  }

  /**
   * Create user group
   * @param userGroup undefined
   */
  UserGroupCreateResponse(userGroup: UserGroup): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = userGroup;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UserGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create user group
   * @param userGroup undefined
   */
  UserGroupCreate(userGroup: UserGroup): __Observable<null> {
    return this.UserGroupCreateResponse(userGroup).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update user group
   * @param userGroup undefined
   */
  UserGroupUpdateResponse(userGroup: UserGroup): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = userGroup;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/UserGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update user group
   * @param userGroup undefined
   */
  UserGroupUpdate(userGroup: UserGroup): __Observable<null> {
    return this.UserGroupUpdateResponse(userGroup).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get user group by id
   * @param userGroupId undefined
   */
  UserGroupGet2Response(userGroupId: number): __Observable<__StrictHttpResponse<UserGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserGroup/${encodeURIComponent(String(userGroupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserGroup>;
      })
    );
  }
  /**
   * Get user group by id
   * @param userGroupId undefined
   */
  UserGroupGet2(userGroupId: number): __Observable<UserGroup> {
    return this.UserGroupGet2Response(userGroupId).pipe(
      __map(_r => _r.body as UserGroup)
    );
  }

  /**
   * Delete user group
   * @param userGroupId undefined
   */
  UserGroupDeleteResponse(userGroupId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/UserGroup/${encodeURIComponent(String(userGroupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete user group
   * @param userGroupId undefined
   */
  UserGroupDelete(userGroupId: number): __Observable<null> {
    return this.UserGroupDeleteResponse(userGroupId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get user groups by userId
   * @param userId undefined
   */
  UserGroupGetUserGroupsByUserResponse(userId: number): __Observable<__StrictHttpResponse<Array<UserGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserGroup/foruser/${encodeURIComponent(String(userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserGroup>>;
      })
    );
  }
  /**
   * Get user groups by userId
   * @param userId undefined
   */
  UserGroupGetUserGroupsByUser(userId: number): __Observable<Array<UserGroup>> {
    return this.UserGroupGetUserGroupsByUserResponse(userId).pipe(
      __map(_r => _r.body as Array<UserGroup>)
    );
  }

  /**
   * Add user to given user group
   * @param params The `UserGroupService.UserGroupAddUserToGroupParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `userGroupId`:
   */
  UserGroupAddUserToGroupResponse(params: UserGroupService.UserGroupAddUserToGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UserGroup/${encodeURIComponent(String(params.userGroupId))}/member/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Add user to given user group
   * @param params The `UserGroupService.UserGroupAddUserToGroupParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `userGroupId`:
   */
  UserGroupAddUserToGroup(params: UserGroupService.UserGroupAddUserToGroupParams): __Observable<null> {
    return this.UserGroupAddUserToGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Remove user from given user group
   * @param params The `UserGroupService.UserGroupRemoveUserFromGroupParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `userGroupId`:
   */
  UserGroupRemoveUserFromGroupResponse(params: UserGroupService.UserGroupRemoveUserFromGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/UserGroup/${encodeURIComponent(String(params.userGroupId))}/member/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Remove user from given user group
   * @param params The `UserGroupService.UserGroupRemoveUserFromGroupParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `userGroupId`:
   */
  UserGroupRemoveUserFromGroup(params: UserGroupService.UserGroupRemoveUserFromGroupParams): __Observable<null> {
    return this.UserGroupRemoveUserFromGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserGroupService {

  /**
   * Parameters for UserGroupGet
   */
  export interface UserGroupGetParams {
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for UserGroupAddUserToGroup
   */
  export interface UserGroupAddUserToGroupParams {
    userId: number;
    userGroupId: number;
  }

  /**
   * Parameters for UserGroupRemoveUserFromGroup
   */
  export interface UserGroupRemoveUserFromGroupParams {
    userId: number;
    userGroupId: number;
  }
}

export { UserGroupService }
