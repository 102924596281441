<mat-dialog-content>
<div class="add-wrapper" >
        <div class="cart-wrap">

            <div class="item-wrap">
                <div class="name-cart">
                    <p> {{ data? data.title : '' }} </p>




                </div>
                <p style="    display: flex;
                flex-direction: row;" class="mt-3"> <span style="width: 50px !important;
    display: flex;
    flex-direction: row;" > {{'from' | translate}} </span> {{ data? (data.start | appDate) : ''}} </p>
                <p style="    display: flex;
                flex-direction: row;"><span style="width: 50px !important;
    display: flex;
    flex-direction: row;" >{{'to' | translate}}</span> {{ data? ( data.end | appDate) : ''}} </p>
            </div>

            <div class="description-cart">

                <span> {{'description' | translate}} </span>

                <div class="description-wrap mt-2">
                    {{ data? data.description : ''}}

                </div>



            </div>

        </div>

        <div class="action mt-4">

            <button mat-raised-button color="secondary" (click)="close()" >{{'close' | translate}}</button>
            <button style="background-color: #7b2cb5;" class="ml-3" mat-raised-button color="primary" (click)="buy()" >{{'book' | translate }}</button>


        </div>
    </div>
</mat-dialog-content>