/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedResultOfDevice } from '../models/paged-result-of-device';
import { Device } from '../models/device';
import { PagedResultOfDeviceActionLog } from '../models/paged-result-of-device-action-log';
@Injectable({
  providedIn: 'root',
})
class DeviceService extends __BaseService {
  static readonly DeviceGt7DevicePath = '/api/Device/gt7';
  static readonly DeviceGetPath = '/api/Device';
  static readonly DeviceCreatePath = '/api/Device';
  static readonly DeviceUpdatePath = '/api/Device';
  static readonly DeviceGet2Path = '/api/Device/hub/{hubId}';
  static readonly DeviceGetByOrderIdPath = '/api/Device/order/{orderId}';
  static readonly DeviceGetByItemIdPath = '/api/Device/item/{itemId}';
  static readonly DeviceGet3Path = '/api/Device/{id}';
  static readonly DeviceDeletePath = '/api/Device/{id}';
  static readonly DeviceSendDeviceActionPath = '/api/Device/SendDeviceAction/{deviceId}/{deviceAction}';
  static readonly DeviceSendPublicDeviceActionPath = '/api/Device/SendPublicDeviceAction/{deviceId}/{deviceAction}';
  static readonly DeviceSendDeviceAction2Path = '/api/Device/SendDeviceAction/{orderLineId}/{deviceAction}/{deviceType}';
  static readonly DeviceGetDeviceActionLogsPath = '/api/Device/log/{deviceId}';
  static readonly DeviceOpenPublicTestPath = '/api/Device/publicopen/{gantnerDeviceReference}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param deviceId undefined
   */
  DeviceGt7DeviceResponse(deviceId?: null | string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (deviceId != null) __params = __params.set('deviceId', deviceId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Device/gt7`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param deviceId undefined
   */
  DeviceGt7Device(deviceId?: null | string): __Observable<Blob> {
    return this.DeviceGt7DeviceResponse(deviceId).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Get devices
   * @param params The `DeviceService.DeviceGetParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  DeviceGetResponse(params: DeviceService.DeviceGetParams): __Observable<__StrictHttpResponse<PagedResultOfDevice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Device`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfDevice>;
      })
    );
  }
  /**
   * Get devices
   * @param params The `DeviceService.DeviceGetParams` containing the following parameters:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  DeviceGet(params: DeviceService.DeviceGetParams): __Observable<PagedResultOfDevice> {
    return this.DeviceGetResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfDevice)
    );
  }

  /**
   * Create device
   * @param patch undefined
   */
  DeviceCreateResponse(patch: Device): __Observable<__StrictHttpResponse<Device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patch;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Device`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Device>;
      })
    );
  }
  /**
   * Create device
   * @param patch undefined
   */
  DeviceCreate(patch: Device): __Observable<Device> {
    return this.DeviceCreateResponse(patch).pipe(
      __map(_r => _r.body as Device)
    );
  }

  /**
   * Update device
   * @param patch undefined
   */
  DeviceUpdateResponse(patch: Device): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patch;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Device`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update device
   * @param patch undefined
   */
  DeviceUpdate(patch: Device): __Observable<null> {
    return this.DeviceUpdateResponse(patch).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get devices for given hubId
   * @param params The `DeviceService.DeviceGet2Params` containing the following parameters:
   *
   * - `hubId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  DeviceGet2Response(params: DeviceService.DeviceGet2Params): __Observable<__StrictHttpResponse<PagedResultOfDevice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Device/hub/${encodeURIComponent(String(params.hubId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfDevice>;
      })
    );
  }
  /**
   * Get devices for given hubId
   * @param params The `DeviceService.DeviceGet2Params` containing the following parameters:
   *
   * - `hubId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  DeviceGet2(params: DeviceService.DeviceGet2Params): __Observable<PagedResultOfDevice> {
    return this.DeviceGet2Response(params).pipe(
      __map(_r => _r.body as PagedResultOfDevice)
    );
  }

  /**
   * Get devices for given orderId
   * @param params The `DeviceService.DeviceGetByOrderIdParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  DeviceGetByOrderIdResponse(params: DeviceService.DeviceGetByOrderIdParams): __Observable<__StrictHttpResponse<PagedResultOfDevice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Device/order/${encodeURIComponent(String(params.orderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfDevice>;
      })
    );
  }
  /**
   * Get devices for given orderId
   * @param params The `DeviceService.DeviceGetByOrderIdParams` containing the following parameters:
   *
   * - `orderId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  DeviceGetByOrderId(params: DeviceService.DeviceGetByOrderIdParams): __Observable<PagedResultOfDevice> {
    return this.DeviceGetByOrderIdResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfDevice)
    );
  }

  /**
   * Get devices for given itemId
   * @param params The `DeviceService.DeviceGetByItemIdParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  DeviceGetByItemIdResponse(params: DeviceService.DeviceGetByItemIdParams): __Observable<__StrictHttpResponse<PagedResultOfDevice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Device/item/${encodeURIComponent(String(params.itemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfDevice>;
      })
    );
  }
  /**
   * Get devices for given itemId
   * @param params The `DeviceService.DeviceGetByItemIdParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  DeviceGetByItemId(params: DeviceService.DeviceGetByItemIdParams): __Observable<PagedResultOfDevice> {
    return this.DeviceGetByItemIdResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfDevice)
    );
  }

  /**
   * Get device for given deviceId
   * @param id undefined
   */
  DeviceGet3Response(id: number): __Observable<__StrictHttpResponse<Device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Device/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Device>;
      })
    );
  }
  /**
   * Get device for given deviceId
   * @param id undefined
   */
  DeviceGet3(id: number): __Observable<Device> {
    return this.DeviceGet3Response(id).pipe(
      __map(_r => _r.body as Device)
    );
  }

  /**
   * Delete device by given deviceId
   * @param id undefined
   */
  DeviceDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Device/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete device by given deviceId
   * @param id undefined
   */
  DeviceDelete(id: number): __Observable<null> {
    return this.DeviceDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Open lock for admin, directly on device
   * @param params The `DeviceService.DeviceSendDeviceActionParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `deviceAction`:
   */
  DeviceSendDeviceActionResponse(params: DeviceService.DeviceSendDeviceActionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Device/SendDeviceAction/${encodeURIComponent(String(params.deviceId))}/${encodeURIComponent(String(params.deviceAction))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Open lock for admin, directly on device
   * @param params The `DeviceService.DeviceSendDeviceActionParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `deviceAction`:
   */
  DeviceSendDeviceAction(params: DeviceService.DeviceSendDeviceActionParams): __Observable<null> {
    return this.DeviceSendDeviceActionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Open locks. Authorized user has access to open public access devices.
   * @param params The `DeviceService.DeviceSendPublicDeviceActionParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `deviceAction`:
   */
  DeviceSendPublicDeviceActionResponse(params: DeviceService.DeviceSendPublicDeviceActionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Device/SendPublicDeviceAction/${encodeURIComponent(String(params.deviceId))}/${encodeURIComponent(String(params.deviceAction))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Open locks. Authorized user has access to open public access devices.
   * @param params The `DeviceService.DeviceSendPublicDeviceActionParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `deviceAction`:
   */
  DeviceSendPublicDeviceAction(params: DeviceService.DeviceSendPublicDeviceActionParams): __Observable<null> {
    return this.DeviceSendPublicDeviceActionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Open lock for end user based on orderLine and type of device (Door / FrontDoor)
   * @param params The `DeviceService.DeviceSendDeviceAction2Params` containing the following parameters:
   *
   * - `orderLineId`:
   *
   * - `deviceType`:
   *
   * - `deviceAction`:
   */
  DeviceSendDeviceAction2Response(params: DeviceService.DeviceSendDeviceAction2Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Device/SendDeviceAction/${encodeURIComponent(String(params.orderLineId))}/${encodeURIComponent(String(params.deviceAction))}/${encodeURIComponent(String(params.deviceType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Open lock for end user based on orderLine and type of device (Door / FrontDoor)
   * @param params The `DeviceService.DeviceSendDeviceAction2Params` containing the following parameters:
   *
   * - `orderLineId`:
   *
   * - `deviceType`:
   *
   * - `deviceAction`:
   */
  DeviceSendDeviceAction2(params: DeviceService.DeviceSendDeviceAction2Params): __Observable<null> {
    return this.DeviceSendDeviceAction2Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get logs for given deviceId
   * @param params The `DeviceService.DeviceGetDeviceActionLogsParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  DeviceGetDeviceActionLogsResponse(params: DeviceService.DeviceGetDeviceActionLogsParams): __Observable<__StrictHttpResponse<PagedResultOfDeviceActionLog>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.UpdatedAfter != null) __params = __params.set('UpdatedAfter', params.UpdatedAfter.toString());
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrderByKey != null) __params = __params.set('OrderByKey', params.OrderByKey.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.IsDescending != null) __params = __params.set('IsDescending', params.IsDescending.toString());
    if (params.Includes != null) __params = __params.set('Includes', params.Includes.toString());
    if (params.IncludeDeleted != null) __params = __params.set('IncludeDeleted', params.IncludeDeleted.toString());
    if (params.FromId != null) __params = __params.set('FromId', params.FromId.toString());
    if (params.Filter != null) __params = __params.set('Filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Device/log/${encodeURIComponent(String(params.deviceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfDeviceActionLog>;
      })
    );
  }
  /**
   * Get logs for given deviceId
   * @param params The `DeviceService.DeviceGetDeviceActionLogsParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `UpdatedAfter`:
   *
   * - `Query`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `OrderByKey`:
   *
   * - `ItemsToSkip`:
   *
   * - `IsDescending`:
   *
   * - `Includes`:
   *
   * - `IncludeDeleted`:
   *
   * - `FromId`:
   *
   * - `Filter`:
   */
  DeviceGetDeviceActionLogs(params: DeviceService.DeviceGetDeviceActionLogsParams): __Observable<PagedResultOfDeviceActionLog> {
    return this.DeviceGetDeviceActionLogsResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfDeviceActionLog)
    );
  }

  /**
   * @param gantnerDeviceReference undefined
   */
  DeviceOpenPublicTestResponse(gantnerDeviceReference: null | string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Device/publicopen/${encodeURIComponent(String(gantnerDeviceReference))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param gantnerDeviceReference undefined
   */
  DeviceOpenPublicTest(gantnerDeviceReference: null | string): __Observable<boolean> {
    return this.DeviceOpenPublicTestResponse(gantnerDeviceReference).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module DeviceService {

  /**
   * Parameters for DeviceGet
   */
  export interface DeviceGetParams {
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for DeviceGet2
   */
  export interface DeviceGet2Params {
    hubId: number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for DeviceGetByOrderId
   */
  export interface DeviceGetByOrderIdParams {
    orderId: number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for DeviceGetByItemId
   */
  export interface DeviceGetByItemIdParams {
    itemId: number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }

  /**
   * Parameters for DeviceSendDeviceAction
   */
  export interface DeviceSendDeviceActionParams {
    deviceId: number;
    deviceAction: 0;
  }

  /**
   * Parameters for DeviceSendPublicDeviceAction
   */
  export interface DeviceSendPublicDeviceActionParams {
    deviceId: number;
    deviceAction: 0;
  }

  /**
   * Parameters for DeviceSendDeviceAction2
   */
  export interface DeviceSendDeviceAction2Params {
    orderLineId: number;
    deviceType: 0 | 1 | 2 | 3;
    deviceAction: 0;
  }

  /**
   * Parameters for DeviceGetDeviceActionLogs
   */
  export interface DeviceGetDeviceActionLogsParams {
    deviceId: number;
    UpdatedAfter?: null | string;
    Query?: null | string;
    PageSize?: number;
    Page?: number;
    OrderByKey?: null | string;
    ItemsToSkip?: number;
    IsDescending?: boolean;
    Includes?: null | string;
    IncludeDeleted?: boolean;
    FromId?: null | number;
    Filter?: null | string;
  }
}

export { DeviceService }
