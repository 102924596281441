/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Booking } from '../models/booking';
import { BookablePeriod } from '../models/bookable-period';
import { Item } from '../models/item';
import { OrderLine } from '../models/order-line';
@Injectable({
  providedIn: 'root',
})
class BookingService extends __BaseService {
  static readonly BookingGetPath = '/api/Booking';
  static readonly BookingCreatePath = '/api/Booking';
  static readonly BookingUpdatePath = '/api/Booking';
  static readonly BookingDeletePath = '/api/Booking/{orderId}';
  static readonly BookingGetBookablePeriodsPath = '/api/Booking/{itemId}/{start}/{end}';
  static readonly BookingGetItemsForBookingPath = '/api/Booking/itemids';
  static readonly BookingGetMyActivceBookingsPath = '/api/Booking/{userId}';
  static readonly BookingCancelMyBookingPath = '/api/Booking/{orderLineId}/cancel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BookingService.BookingGetParams` containing the following parameters:
   *
   * - `itemIds`:
   *
   * - `To`:
   *
   * - `From`:
   */
  BookingGetResponse(params: BookingService.BookingGetParams): __Observable<__StrictHttpResponse<Array<Booking>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.itemIds != null) __params = __params.set('itemIds', params.itemIds.toString());
    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Booking`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Booking>>;
      })
    );
  }
  /**
   * @param params The `BookingService.BookingGetParams` containing the following parameters:
   *
   * - `itemIds`:
   *
   * - `To`:
   *
   * - `From`:
   */
  BookingGet(params: BookingService.BookingGetParams): __Observable<Array<Booking>> {
    return this.BookingGetResponse(params).pipe(
      __map(_r => _r.body as Array<Booking>)
    );
  }

  /**
   * Create booking-order. One orderLine for each occurence.
   * @param booking undefined
   */
  BookingCreateResponse(booking: Booking): __Observable<__StrictHttpResponse<Booking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = booking;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Booking`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Booking>;
      })
    );
  }
  /**
   * Create booking-order. One orderLine for each occurence.
   * @param booking undefined
   */
  BookingCreate(booking: Booking): __Observable<Booking> {
    return this.BookingCreateResponse(booking).pipe(
      __map(_r => _r.body as Booking)
    );
  }

  /**
   * @param booking undefined
   */
  BookingUpdateResponse(booking: Booking): __Observable<__StrictHttpResponse<Booking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = booking;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Booking`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Booking>;
      })
    );
  }
  /**
   * @param booking undefined
   */
  BookingUpdate(booking: Booking): __Observable<Booking> {
    return this.BookingUpdateResponse(booking).pipe(
      __map(_r => _r.body as Booking)
    );
  }

  /**
   * @param orderId undefined
   */
  BookingDeleteResponse(orderId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Booking/${encodeURIComponent(String(orderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param orderId undefined
   */
  BookingDelete(orderId: number): __Observable<null> {
    return this.BookingDeleteResponse(orderId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `BookingService.BookingGetBookablePeriodsParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `itemId`:
   *
   * - `end`:
   */
  BookingGetBookablePeriodsResponse(params: BookingService.BookingGetBookablePeriodsParams): __Observable<__StrictHttpResponse<Array<BookablePeriod>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Booking/${encodeURIComponent(String(params.itemId))}/${encodeURIComponent(String(params.start))}/${encodeURIComponent(String(params.end))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BookablePeriod>>;
      })
    );
  }
  /**
   * @param params The `BookingService.BookingGetBookablePeriodsParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `itemId`:
   *
   * - `end`:
   */
  BookingGetBookablePeriods(params: BookingService.BookingGetBookablePeriodsParams): __Observable<Array<BookablePeriod>> {
    return this.BookingGetBookablePeriodsResponse(params).pipe(
      __map(_r => _r.body as Array<BookablePeriod>)
    );
  }
  BookingGetItemsForBookingResponse(): __Observable<__StrictHttpResponse<Array<Item>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Booking/itemids`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Item>>;
      })
    );
  }  BookingGetItemsForBooking(): __Observable<Array<Item>> {
    return this.BookingGetItemsForBookingResponse().pipe(
      __map(_r => _r.body as Array<Item>)
    );
  }

  /**
   * @param userId undefined
   */
  BookingGetMyActivceBookingsResponse(userId: number): __Observable<__StrictHttpResponse<Array<OrderLine>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Booking/${encodeURIComponent(String(userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrderLine>>;
      })
    );
  }
  /**
   * @param userId undefined
   */
  BookingGetMyActivceBookings(userId: number): __Observable<Array<OrderLine>> {
    return this.BookingGetMyActivceBookingsResponse(userId).pipe(
      __map(_r => _r.body as Array<OrderLine>)
    );
  }

  /**
   * @param orderLineId undefined
   */
  BookingCancelMyBookingResponse(orderLineId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Booking/${encodeURIComponent(String(orderLineId))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param orderLineId undefined
   */
  BookingCancelMyBooking(orderLineId: number): __Observable<null> {
    return this.BookingCancelMyBookingResponse(orderLineId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BookingService {

  /**
   * Parameters for BookingGet
   */
  export interface BookingGetParams {
    itemIds?: null | string;
    To?: null | string;
    From?: string;
  }

  /**
   * Parameters for BookingGetBookablePeriods
   */
  export interface BookingGetBookablePeriodsParams {
    start: string;
    itemId: number;
    end: string;
  }
}

export { BookingService }
