<mat-card class="changepassword-card">
    <mat-card-header>
        <mat-card-title >{{'change_password' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'enter_new_password' | translate}}</mat-card-subtitle>
    </mat-card-header>

        <mat-card-content>
            <form [formGroup]="changeForm" (ngSubmit)="submit()">
                <mat-form-field appearance="outline">
                    <mat-label>{{'activation_code' | translate}}</mat-label>
                    <input matInput type="number" formControlName="resetKey">
                    <mat-error *ngIf="changeForm.controls.resetKey.hasError('required')">{{'activation_code_required' | translate}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'password' | translate}}</mat-label>
                    <input matInput type="password" formControlName="newPassword">
                    <mat-error *ngIf="changeForm.controls.newPassword.hasError('minlength') || changeForm.controls.newPassword.hasError('required')">{{'password_required' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label >{{'repeat_password' | translate}}</mat-label>
                    <input matInput type="password" placeholder="{{'repeat_password' | translate}}"  formControlName="newPasswordRepeat">
                    <mat-error *ngIf="changeForm.controls.newPasswordRepeat.hasError('minlength') || changeForm.controls.newPasswordRepeat.hasError('required')">{{'password_required' | translate }}</mat-error>
                </mat-form-field>

                <div layout="row" layout-align="start center">
                    <div flex>
                        <button mat-raised-button color="primary" >{{'change_password' | translate}}</button>
                    </div>
                    <a routerLink="/login" class="forgot-password"><small>{{'go_back_login' | translate}}</small></a>
                </div>
            </form>
        </mat-card-content>
</mat-card>
