import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-monthly-dialog',
  templateUrl: './confirm-monthly-dialog.component.html',
  styleUrls: ['./confirm-monthly-dialog.component.css']
})
export class ConfirmMonthlyDialogComponent {

  constructor(private dialogRef: MatDialogRef<ConfirmMonthlyDialogComponent>) {}

  onCloseDialog(confirm: boolean) {
    this.dialogRef.close(confirm);
  }
}
