<mat-card class="vipps-mat-card">
  <mat-card-header>
    <mat-card-title>Vipps</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div *ngIf="!showLoadingIcon">

      <div *ngIf="!showError">
        <h1>Kjøpet er gjennomført</h1>
        <p>Ditt kjøp er nå gjennomført. Billettene finner du ved å trykke på "mine billetter" i menyen.</p>
      </div>
  
      <div *ngIf="showError">
        <h1>Kjøpet er avbrutt</h1>
        <p>Ditt kjøp ble ikke gjennomført. Vennligst prøv igjen.</p>
      </div>

    </div>

  </mat-card-content>
</mat-card>