<mat-card class="recoverpassword-card">
    <mat-card-header>
        <mat-card-title >{{'lost_password' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'enter_username_reset_password' | translate}}</mat-card-subtitle>
    </mat-card-header>

        <mat-card-content>
            <form [formGroup]="recoverForm" (ngSubmit)="submit()">
                <mat-form-field appearance="outline">
                    <mat-label>{{'phone_or_email' | translate}}</mat-label>
                    <input matInput formControlName="username">

                    <mat-error *ngIf="recoverForm.controls.username.hasError('required') || recoverForm.controls.username.hasError('minlength')" >{{'phone_or_email_required' | translate}}</mat-error>
                </mat-form-field>

                <div layout="row" layout-align="start center">
                    <div flex>
                        <button mat-raised-button color="primary" >{{'recover_password' | translate}}</button>
                    </div>
                    <a routerLink="/login" class="forgot-password"><small >{{'go_back_login' | translate}}</small></a>
                </div>
            </form>
        </mat-card-content>
</mat-card>
