<mat-card>
    <mat-card-title>{{'manage_subscriptions' | translate}}</mat-card-title>

        <mat-card-content>
                <ngx-datatable class="material fullscreen flex-responsive only-scroll-horz no-box-shadow order-table"
                   [columnMode]="'force'"
                   [headerHeight]="'auto'"
                   [footerHeight]="50"
                   [rowHeight]="'auto'"
                   [rows]="items || []"
                   (sort)="onSort($event)"
                   [scrollbarH]="true"
                   (activate)="toggleExpandRow($event)"
                   [messages]="{emptyMessage: 'no_data_to_display' | translate, totalMessage: 'total' | translate}">
                   <!-- Row Detail Template -->
                   <ngx-datatable-row-detail [rowHeight]="'auto'" #detailRow>
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                            <ngx-datatable class="material fullscreen flex-responsive only-scroll-horz no-box-shadow"
                            [columnMode]="'force'"
                            [headerHeight]="50"
                            [footerHeight]="'auto'"
                            [rowHeight]="'auto'"
                            [rows]="row.orderLines || []"
                            [scrollbarH]="true"
                            [messages]="{emptyMessage: 'no_data_to_display' | translate, totalMessage: 'total' | translate}">
                                <ngx-datatable-column name="{{'product_id' | translate}}" prop="itemId" [width]="100" [canAutoResize]="false"></ngx-datatable-column>
                                <ngx-datatable-column name="{{'name' | translate}}" prop="itemName">
                                        <ng-template let-value="value" ngx-datatable-cell-template>
                                            {{ value ? value : row.item.name}}
                                        </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="{{'quantity' | translate}}" prop="itemCount">
                                </ngx-datatable-column>
                                <ngx-datatable-column name="{{'used_from_to' | translate}}" prop="used" [width]="200">
                                    <ng-template let-value="value"  ngx-datatable-cell-template>
                                      <span> {{ ((value.from) ? (value.from | appDate) : '--')  + ' - ' + ((value.to) ? (value.to | appDate) : '--')  }} </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="{{'price_c' | translate}}" prop="itemPrice.value">
                                    <ng-template let-price="value" let-row="row" ngx-datatable-cell-template>
                                        <span>{{ price | appCurrency }}</span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="{{'vat' | translate}}" prop="itemPrice.vat">
                                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                        <span>{{ value | appCurrency }}</span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="{{'total' | translate}}" prop="itemPrice.value">
                                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                        <span>{{ (value * row.itemCount) | appCurrency }}</span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="{{'number_of_uses' | translate}}" prop="numberOfUses"></ngx-datatable-column>
                                <ngx-datatable-column name="" cellClass="action-column" headerClass="action-column-header" [width]="300" >
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div class="orders-grid-actions">
                                                <button mat-raised-button [disabled]="completed" (click)="cancelRecurring(row)" *ngIf="!row.cancelRecurring && itemTypes[row.item.type] === 'Month'" >{{'cancel_recurring' | translate}}</button>
                                                <span *ngIf="row.cancelRecurring">{{'cancelled' | translate}}</span>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                              </ngx-datatable>
                        </ng-template>
                   </ngx-datatable-row-detail>

                   <!-- Column Templates arrow for expanding and header template for arrow collapse/expand all-->
                   <ngx-datatable-column
                             [width]="50"
                             [resizeable]="false"
                             [sortable]="false"
                             [draggable]="false"
                             [canAutoResize]="false">
                   </ngx-datatable-column>

      <ngx-datatable-column name="ID" prop="id" minWidth="100" [canAutoResize]="false"></ngx-datatable-column>
      <ngx-datatable-column name="{{'order_date' | translate}}" prop="placedAt">
          <ng-template let-value="value" ngx-datatable-cell-template><span>{{ value | appDate }}</span></ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'status'}}" prop="status">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{ orderStatus[value] }}
              <div *ngIf="recurringCancelled[row.id]">
                  <mat-icon color="warn" title="Number of cancelled recurring">cancel</mat-icon> {{ recurringCancelled[row.id] + ' cancelled' }}
              </div>
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'payment_status' | translate}}" prop="paymentStatus">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{ paymentStatus[value] }}
          </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
        </mat-card-content>
</mat-card>
