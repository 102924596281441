import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'components/shared/base.component';
import { AlertService } from 'services/alert.service';
import { Router } from '@angular/router';
import { BookingService, ItemService } from 'api/services';
import * as moment from 'moment';

import { DialogService } from 'shared/dialog.service';
import { Observable } from 'rxjs';
import { Item } from 'api/models';
import { FormControl } from '@angular/forms';
import { GlobalConfigService } from 'services/global-config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-children-birthday',
  templateUrl: './children-birthday.component.html',
  styleUrls: ['./children-birthday.component.css']
})
export class ChildrenBirthdayComponent extends BaseComponent implements OnInit {


  constructor(alertService: AlertService,
    router: Router,
    private itemService: ItemService,
    private bookingService: BookingService,
    private dialogService: DialogService,
    private globalConfig: GlobalConfigService,
    public translateService: TranslateService

    ) {
    super(alertService, router, translateService);
   if (window.innerWidth > 767 ) {
    this.endDate = this.endDate.add(6, 'day');
   }


    this.childrenBirthday = this.globalConfig.childrenBirtday ? Number(this.globalConfig.childrenBirtday) : null;


  }
  options: any;
  calendarVisible = true;
  items = [];
  filteredItems: Observable<Item[]>;
  selectedItem = new FormControl();
 events = [];
 eventsBacked = [];
  selectedItems = [];
  showLoadingIcon = false;
  calendarWeekends = true;
  themeSystem: 'bootstrap4';
  calendarEvents = [];
  calendarOrders = [];
  currentEvent: any;
  uniqueEvents: any[] = [];
  selectionStart: any;
  selectionEnd: any;
  selectionId: any;
  cart = [];
  cartSum = 0;

  startDate = moment().add(4, 'days');
  endDate = moment().add(4, 'days');
  dates = [];
  childrenBirthday = null;
  itemsList = [];
check;

  ngOnInit() {

    this.generateDates();


  }

  eventClick(data: any) {

    if (data.available <= 0) {
      return;
    }

    this.dialogService.bookChildrenBirthday(data).subscribe(res => {
         this.loadBookableEvents();
    });
  }

  loadData() {
  this.showLoadingIcon = true;
    const queryParams = {
      PageSize: 999,
      itemType: 0,
      timeFrom: moment(this.startDate).toString(),
      timeTo: moment(this.endDate).toString()

    };
    this.itemService.ItemGet(queryParams).subscribe(result => {
     this.items = result.results;
      this.showLoadingIcon = false;


    }, err => {
      this.defaultErrorHandler(err);
    });
  }

  convertDate(date, zone?) {
    if (zone === 'local') {
      return new Date(moment(date).utc(false).add(2, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS'));

    } else {

      date = moment(date)
        .local(true)
        .format();
      date = moment(date)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      return date;
    }
  }
  onSelect(arg) {
   // this.onAddOrder(true, arg);
  }

  buy() {

    const items = [];
    const orderLines = [];

    this.cart.forEach(itemOrder => {

      items.push({
        item: {
          name: itemOrder.item.title,
          prices: [
            {
              value: itemOrder.item.extendedProps.price
            }
          ]
        },
        number: itemOrder.number
      });

      orderLines.push({
        itemId: Number(itemOrder.item.id),
        itemCount: itemOrder.number
      });

    });

    this.dialogService.buy(items, this.cartSum, orderLines, true, null, false).subscribe(res => {

    });


  }
  onItemChange(event) {

  }

  getName(item) {

    const currentLanguge = localStorage.getItem('language');
    if (currentLanguge) {
      switch (currentLanguge) {
        case 'en-GB': return item.name;
        case 'nb-NO': return item.properties['nb-NO_Name'] ? item.properties['nb-NO_Name'] : item.name ;
        default : return item.name;
      }

    } else {
      return item.properties['nb-NO_Name'] ? item.properties['nb-NO_Name'] : item.name;
    }

  }
  generateDates() {
    this.dates = [];
    const startValue = moment(this.startDate);
    for (const day = startValue; day <= this.endDate; day.add(1, 'day')) {
      const date = moment(day);
      this.dates.push(date);


    }

    this.loadBookableEvents();

  }

  loadBookableEvents() {

    this.events = [];
    this.eventsBacked = [];

    this.showLoadingIcon = true;
    this.bookingService.BookingGetBookablePeriods({
      itemId: Number(this.childrenBirthday),
      start: this.startDate.format('YYYY-MM-DDT').toString() + '04:00:00',
      end: this.endDate.format('YYYY-MM-DDT').toString() + '23:00:00'
    }).subscribe(res => {

     for (const resEvent of res) {

       const startDate = moment(resEvent.start.slice(0, -1)).add(2, 'hours').toLocaleString();
       const endDate = moment(resEvent.end.slice(0, -1)).add(2, 'hours').toLocaleString();

       const newEvent = {
         id: resEvent.itemId,
         title: resEvent.itemName,
         start: new Date(startDate),
         end: new Date(endDate),
         color: resEvent.itemColor,
         available: resEvent.available,
         total: resEvent.total
       };

       this.events.push(newEvent);
       this.eventsBacked.push(newEvent);
     }

       this.showLoadingIcon = false;
    }, err => {
      this.defaultErrorHandler(err);
      this.showLoadingIcon = false;
    });



  }

  getDayName(dateStr) {

  let locale = 'nb-NO';


  const currentLanguge = localStorage.getItem('language');
  if (currentLanguge) {
    switch (currentLanguge) {
      case 'en-GB': {
        locale = 'en-GB';
        const date = moment(dateStr).locale(locale).format('dddd');
       return date;
      }
      case 'nb-NO': {
        locale = 'nb-NO';
        const date = moment(dateStr).locale(locale).format('dddd');
        return date;

      }
      default : {
        locale = 'nb-NO';
        const date = moment(dateStr).locale(locale).format('dddd');
        return date;
      }
    }

}
}
getDate(day) {

  let locale = 'no';
  const currentLanguge = localStorage.getItem('language');
  if (currentLanguge) {
    switch (currentLanguge) {
      case 'en-GB': {
        locale = 'en-GB';
        const value = moment(day).locale(locale).format('DD.MMM.YYYY');
        return value;
      }
      case 'nb-NO': {
        locale = 'no';
        const value = moment(day).locale(locale).format('DD.MMM.YYYY');
        return value;

      }
      default : {
        locale = 'no';
        const value = moment(day).locale(locale).format('DD.MMM.YYYY');
        return value;
      }
    }

} else {
  const value = moment(day).locale(locale).format('DD.MMM.YYYY');
  return value;
}
}
getEventTime(item) {
  let locale = 'nb-NO';
  const currentLanguge = localStorage.getItem('language');
  if (currentLanguge) {
    switch (currentLanguge) {
      case 'en-GB': {
        locale = 'en-GB';
        const startTime = new Date(item.start).toLocaleTimeString(locale, {hour: '2-digit', minute: '2-digit'});
        return startTime;
      }
      case 'nb-NO': {
        locale = 'nb-NO';
        const startTime = new Date(item.start).toLocaleTimeString(locale, {hour: '2-digit', minute: '2-digit'});
        return startTime;

      }
      default : {
        locale = 'nb-NO';
        const startTime = new Date(item.start).toLocaleTimeString(locale, {hour: '2-digit', minute: '2-digit'});
        return startTime;
      }
    }

} else {
  const startTime = new Date(item.start).toLocaleTimeString(locale, {hour: '2-digit', minute: '2-digit'});
  const endTime = new Date(item.end).toLocaleTimeString(locale, {hour: '2-digit', minute: '2-digit'});
  return startTime + ' - ' + endTime;
}

}
navigateNext() {

 this.startDate = moment(this.endDate).add(1, 'day');
 if (window.innerWidth > 767 ) {
  this.endDate = moment(this.startDate).add(6, 'day');
 } else {
   this.endDate = moment(this.startDate);
 }


 this.generateDates();

 let filtered = [];
 for (const itemFor of this.itemsList) {
   if (itemFor.selected) {
     filtered = filtered.concat(this.eventsBacked.filter(x => x.id === itemFor.item.id));
   }
 }
 this.events = filtered;
}
navigateBack() {
if (this.startDate > moment().add(4, 'days')) {

  if (window.innerWidth > 767 ) {
    this.startDate = moment(this.startDate).add(-7, 'day');
    this.endDate = moment(this.endDate).add(-7, 'day');
   } else {
    this.startDate = moment(this.startDate).add(-1, 'day');
    this.endDate = moment(this.startDate);
   }

  this.generateDates();

  let filtered = [];
  for (const itemFor of this.itemsList) {
    if (itemFor.selected) {
      filtered = filtered.concat(this.eventsBacked.filter(x => x.id === itemFor.item.id));
    }
  }
  this.events = filtered;
}
}
getDayEvents(day) {
  const date = new Date(day);
  return this.events.filter(x => x.start.toDateString() == date.toDateString()).sort((a, b) => (a.start > b.start) ? 1 : -1);
}

itemSelectedChange(item) {
  const itemList = this.itemsList.find(x => x.item.id === item.item.id);
  itemList.selected = !item.selected;

  let filtered = [];
  for (const itemFor of this.itemsList) {
    if (itemFor.selected) {
      filtered = filtered.concat(this.eventsBacked.filter(x => x.id === itemFor.item.id));
    }
  }
  this.events = filtered;

}
}
